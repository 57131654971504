// components/ModalDonationThanks.js

import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'antd'
import { MainContext } from '../contexts/MainContext'
import { H1C } from '../common/Builders'
import { breakpoints } from '../common/constants'
// import { modalDonationThnxMaxWidthInPx } from '../common/constants'

// NBS-2024-02141-p1.png
const htvImgPath = `${process.env.PUBLIC_URL}/images/NBS-2024-02141-p1.png`

export const ModalHTV = props => {

    const { isOpen } = props

    const {
        width,
        showModalHTV,
        setShowModalHTV,
    } = useContext(MainContext)

    useEffect(() => {
        setShowModalHTV(true)
    }, [isOpen])

    // const [ modalOpen, setModalOpen ] = useState(!!isOpen)

    if (width < breakpoints.md) return null

    return (
        <Modal
            // width={Math.min(modalDonationThnxMaxWidthInPx, Math.round(width * .9))}
            width={`${width}px`}
            // className="modal-howtovote"
            // open={modalOpen}
            open={showModalHTV}
            onOk={() => setShowModalHTV(!showModalHTV)}
            // onCancel={() => setModalOpen(false)}
            // onCancel={() => setModalOpen(false)}
            onCancel={() => setShowModalHTV(!showModalHTV)}
            cancelText={null}
            cancelButtonProps={{ style: { display: 'none' } }}
            style={{ display: 'flex', justifyContent: 'center', }}
        >
            <div>
                <img src={htvImgPath} alt="How to Vote Labor, Narrabeen Ward, Northern Beaches Council" />
            </div>
        </Modal>
    )
}
