// screens/Issues.js

import React, { useCallback, useContext, useRef } from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import {
    breakpoints,
    DEBUG,
    issues,
    laborRed,
    subpages,
    whiteish,
} from '../common/constants'
import { H1C } from '../common/Builders'
import { MainContext } from '../contexts/MainContext'
import { Divider } from 'antd'
import { useHref, useLinkClickHandler } from 'react-router-dom'
import { SeawallsPledge } from '../components/SeawallsPledge'

const candidatesForum = 'candidates-forum'

export const Issues = () => {

    const {
        // currentBreakpoint,
        width,
        debugMode,
        // active,
    } = useContext(MainContext)

    // const navigate = useNavigate()

    const { teaserMdArr } = subpages.find(e => e?.key === candidatesForum)

    return (
        <OuterCont className={DEBUG ? 'OuterCont' : ''} debug={debugMode ? 1 : null}>
            <HeaderCont className={DEBUG ? 'HeaderCont' : ''}>
                <H1C>{issues?.hdr || 'Issues'}</H1C>
                {/* { DEBUG ? <>width: {width}</> : null } */}
            </HeaderCont>

            <ContentCont className={DEBUG ? 'ContentCont' : ''}>
            {
                issues && issues?.textMd && Array.isArray(issues?.textMd)
                    // ? <ReactMarkdown>{issues?.textMd.join('\n\n')}</ReactMarkdown>
                    ? (
                        <ReactMarkdown
                            components={{
                                a(props) {
                                    // const { children, className, node, ...rest } = props
                                    // console.log(25, { children, className, node, ...rest })
                                    return SurveyBtn(props)
                                },
                                // p: 'div',
                                p(props) {
                                    return <StyledSurveyDiv>{props?.children}</StyledSurveyDiv>
                                },
                                ul(props) {
                                    return <StyledUl>{props?.children}</StyledUl>
                                }
                            }}
                        >
                            {issues?.textMd.join('\n\n')}
                        </ReactMarkdown>
                    )
                    : null
            }
                
                {/* new content */}
                {/* <Button
                    onClick={e => {
                        e.preventDefault()
                        // alert(`active = ${active}`)
                        navigate('/sub/candidates-forum')
                    }}
                >NEW CONTENT</Button>
                <br/> */}
                <Divider />
                <div>
                    <ReactMarkdown>{teaserMdArr && Array.isArray(teaserMdArr) && teaserMdArr.join('\n')}</ReactMarkdown>
                    <Link
                        to="/sub/candidates-forum"
                    >&gt;&gt;&gt; Candidates Forum</Link>
                </div>
                <p>&nbsp;</p>
                <SeawallsPledge />
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </ContentCont>

            {/* padding at bottom for mobile devices */}
            {
                width < breakpoints.md
                    ? <><br/><br/><br/><br/></>
                    : null
            }

        </OuterCont>
    )
}

/* cf 
- https://stackoverflow.com/a/75887115/23517902
- https://plainenglish.io/blog/react-open-link-in-new-tab
*/
const SurveyBtn = props => {

    // console.log(41, props)
    const {
        href = false,
        children = null,
    } = props

    // const [ href, setHref ] = useState()
    const openedNewTabRef = useRef(false)

    // const handleOnClick = () => {
    //     if (!openedNewTabRef?.current && href) {
    //         window.open(href, '_blank')?.focus()
    //         openedNewTabRef.current = true
    //     }
    // }
    const handleOnClickCb = useCallback(() => {
        if (!openedNewTabRef?.current && href) {
            window.open(href, '_blank')?.focus()
            openedNewTabRef.current = true
        }
    }, [href])

    if (!href || !children) return null

    // return <button onClick={handleOnClick}>{props?.children}</button>
    return (
        <StyledSurveyBtnCont className={DEBUG ? 'StyledSurveyBtnCont' : ''}>
            <StyledSurveyBtn onClick={handleOnClickCb}>{props?.children}</StyledSurveyBtn>
        </StyledSurveyBtnCont>
    )
}

// const StyledLink = styled("a", { color: "fuchsia" })
const StyledLink = styled.a`
    display: block;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
`

const Link = React.forwardRef(
    (
        {
            onClick,
            replace = false,
            state,
            target,
            to,
            ...rest
        },
        ref
    ) => {
        let href = useHref(to)
        let handleClick = useLinkClickHandler(to, {
            replace,
            state,
            target,
        })

        return (
            <StyledLink
                {...rest}
                href={href}
                onClick={(event) => {
                    onClick?.(event)
                    if (!event.defaultPrevented) {
                        handleClick(event)
                    }
                }}
                ref={ref}
                target={target}
            />
        )
    }
)

const StyledSurveyBtnCont = styled.div`
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    // margin-bottom: 4rem; /* allows enough space for slogan */
`

const StyledSurveyBtn = styled.button`
    // flex: 1;
    padding: 1rem 2rem;
    margin: 1rem;
    // border: 1px solid ${whiteish};
    border: 1px solid ${laborRed};
    font-size: 1.1rem;
    font-family: Raleway;
    font-weight: 600;
    cursor: pointer;
`

const StyledSurveyDiv = styled.div`
    padding-top: .5rem;
    // padding-bottom: .5rem;
`

const StyledUl = styled.ul`
    list-style: square;
    margin-block-start: .3em;
    margin-block-end: .3em;
    padding-inline-start: 1.5rem;
`

const HeaderCont = styled.div`
    // padding-top: 2rem;
    padding-top: 1.5rem;
    // padding-bottom: 2rem;
    padding-bottom: .25rem;
    flex: 0 1 auto; /* sized to content */
`

const ContentCont = styled.div`
    flex: 1 1 auto; /* fills remaining space */
    // display: flex;
`

const OuterCont = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
    max-width: 700px;
    margin: 0 auto; /* centers horizontally */
    border: ${props => props?.debug ? '1px solid yellow' : 0};
    // padding-bottom: 4rem; /* allows enough space for slogan */
`
