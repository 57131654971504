/*
// process postcode data:
// read in file generated from https://www.matthewproctor.com/australian_postcodes
const data = require('../assets/australian_postcodes.json')
// then in render return part:
    <textarea>
        {JSON.stringify(data.map(({ id, postcode, locality, state }) => ({ id, postcode, locality, state })), null, 2)}
    </textarea>
*/

import React, { useContext, useState } from 'react'
import Canvas from '../components/Canvas'
import { ContactForm } from '../forms/ContactForm'
import { breakpoints, contactHdr, contactText } from '../common/constants'
import { H1C, PC } from '../common/Builders'
import styled from 'styled-components'
import { MainContext } from '../contexts/MainContext'

// const width = 270
const height = 30

export const GetInTouch = () => {

    const [ msgSent,    setMsgSent ]    = useState(false)
    const [ msg,        setMsg ]        = useState({})

    /* // TODO: reCaptcha V3 - cf https://medium.com/@alexjamesdunlop/unnecessary-packages-b3623219d86
    const handleLoaded = () => {
        window.grecaptcha.ready(() => {
            window.grecaptcha
                .execute(`"${REACT_APP_CAPTCHA_V3_SITE_KEY}"`, { action: 'homepage' })
                .then(token => {
                    // ...
                })
        })
    }

    useEffect(() => {
        // Add reCaptcha
        const script = document.createElement('script')
        script.src = `https://www.google.com/recaptcha/api.js?render=${REACT_APP_CAPTCHA_V3_SITE_KEY}`
        script.addEventListener('load', handleLoaded)
        document.body.appendChild(script)
    }, [])
    */

    const {
        width,
    } = useContext(MainContext)

    const isNarrow = width < breakpoints.md
    const padding = isNarrow ? '.25rem' : 'inherit'

    return (
        <SectionContDiv
            $padding={padding}
        >
            <H1C>{contactHdr}</H1C>
            {
                msgSent
                    ? <PC>The following message has been sent:</PC>
                    : <PC>{contactText}</PC>
            }
            <Canvas
                // ref={canvasRef}
                { ...{ width, height } }
            />
            <ContactForm { ...{ msgSent, setMsgSent, msg, setMsg } } />
        </SectionContDiv>
    )
}

const SectionContDiv = styled.div`
    // padding-bottom: 4rem; /* allows enough space for slogan */
    padding: ${props => props.$padding || 'inherit'};
    padding-bottom: 5rem; /* allows enough space for slogan */
    border: 0px solid white;
`
