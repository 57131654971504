import React, { useEffect, useState } from 'react'
// import { ParallaxProvider } from 'react-scroll-parallax'
import './App.css'
import Main from './components/Main'
import {
    GlobalStyle,
} from './common/Builders'
import { MainContext } from './contexts/MainContext'
import { useLocalStorage } from './hooks/useLocalStorage'
import { currentBreakpointFromWidth } from './common/functions'

const App = () => {

    const [ active, setActive ] = useState(1)
    // const [ browserFingerprint, setBrowserFingerprint ] = useState()
    const [ browserFingerprint, setBrowserFingerprint ] = useLocalStorage('_bfp', '')
    const [ initialVisit, setInitialVisit ] = useLocalStorage('_init', )
    const [ width, setWidth ] = useState(window.innerWidth)
    const [ height, setHeight ] = useState(window.innerHeight)
    // const [ bgImgPool, setBgImgPool ] = useState([])
    const [ debugMode,          setDebugMode ]          = useState(false)
    const [ currentBreakpoint,  setCurrentBreakpoint ]  = useState()
    const [ showModalHTV,       setShowModalHTV ]       = useState(false)

    /* TODO: refactor like https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/ */
    const handleWindowResize = () => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize)
        return () => window.removeEventListener('resize', handleWindowResize)
    }, [])

    useEffect(() => {
        // console.log(36, `initialVisit: ${initialVisit}`)
        if (!initialVisit) setInitialVisit(new Date())
    }, [])

    useEffect(() => {
        if (width) setCurrentBreakpoint(currentBreakpointFromWidth(width))
    }, [width])


    /* disable landscape on small devices */
    if (height && !isNaN(parseInt(height)) && parseInt(height) < 540) return null

    return (
        <MainContext.Provider
            value={{
                active, setActive,
                browserFingerprint, setBrowserFingerprint,
                width,
                height,
                // bgImgPool, setBgImgPool, /* make a pool of bg imgs available */
                debugMode, setDebugMode,
                currentBreakpoint,
                showModalHTV, setShowModalHTV,
            }}
        >
            {/* <ParallaxProvider> */}
                <GlobalStyle />
                <Main />
            {/* </ParallaxProvider> */}
        </MainContext.Provider>
    )
}

export default App
