// SeawallsPledge.js

import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { bgLaborRed, bgLaborRedOldfashioned, laborBlue } from '../common/constants'

const textHdrMd = `# PLEDGE FOR COASTAL PRESERVATION: NO MORE SEAWALLS`
const textMainMd = `I pledge to protect and preserve our coastlines by opposing the construction 
of seawalls that disrupt natural coastal processes.

I recognize that seawalls can cause significant harm to natural habitats, 
disrupt coastal ecosystems, and accelerate erosion.

I commit to supporting sustainable, nature-based solutions that enhance the 
resilience of our shorelines, protect marine ecosystems, and ensure the 
long-term health of our oceans.

I want to ensure a future where our coasts thrive, free from the detrimental 
impacts of seawalls.

I will advocate for the protection of our coastlines, promote awareness about 
the impacts of seawalls, and work towards a future where our shores remain 
natural and vibrant.`
const textFooterMd = `### NARRABEEN&nbsp;WARD &nbsp;&nbsp;&nbsp; SUE&nbsp;WRIGHT &nbsp;&nbsp;&nbsp; LABOR&nbsp;CANDIDATE`

const bgImgPath = `${process.env.PUBLIC_URL}/images/aleksandar-jason-K14ern9JHoA-unsplash-bw.jpg`

export const SeawallsPledge = props => {

    const {  } = props

    return (
        <StyledPledgeCont
            $bgimg={bgImgPath}
        >
            <StyledPledgeHdr>
                <ReactMarkdown>{textHdrMd}</ReactMarkdown>
            </StyledPledgeHdr>
            <StyledPledgeMain>
                <ReactMarkdown>{textMainMd}</ReactMarkdown>
            </StyledPledgeMain>
            <StyledPledgeFooter>
                <ReactMarkdown>{textFooterMd}</ReactMarkdown>
            </StyledPledgeFooter>
        </StyledPledgeCont>
    )
}

const StyledPledgeCont = styled.div`
    background-image: url(${props => props.$bgimg});
    background-color: ${bgLaborRed};
    // background-blend-mode: screen;
    background-blend-mode: soft-light, overlay;
    padding: .33rem 2rem;
    background-size: cover;
    text-shadow: 2px 2px 6px black;
}
`

const StyledPledgeHdr = styled.div`
    text-align: center;
    text-wrap: balance;
`

const StyledPledgeMain = styled.div`
    font-size: 1.1rem;
`

const StyledPledgeFooter = styled.div`
    text-align: center;
    text-wrap: balance;
`
