import React from 'react'
import styled from 'styled-components'
import { DEBUG, laborRed, slogan, white } from '../common/constants'
import SvgLogoRubikFont from './SvgLogoRubikFont'

export const Slogan = props => {

    const {
        firstPage = false,
        showLogoInSlogan = false,
        sloganFontsize = '2rem',
    } = props

    // return (
    //     <Row>
    //         <Col colspan={12}>
    //         {
    //             firstPage
    //                 ? null
    //                 : <SvgLogoRubikFont  />
    //         }
    //         </Col>
    //         <Col colspan={12}>
    //             <StyledSlogan>
    //                 {/* {firstPage ? '(first page)' : null} */}
    //                 {slogan}
    //             </StyledSlogan>
    //         </Col>
    //     </Row>
    // )
    // return (
    //     <StyledSlogan>
    //         {/* {firstPage ? '(first page)' : null} */}
    //         {slogan}
    //         <div
    //             style={{ border: `0px solid white`, width: '160px', }}
    //         >
    //             <SvgLogoRubikFont  />
    //         </div>
    //     </StyledSlogan>
    // )
    // return (
    //     <StyledCont className={DEBUG ? 'StyledCont' : ''}>
    //         <StyledSlogan className={DEBUG ? 'StyledSlogan' : ''}>
    //             {/* {firstPage ? '(first page)' : null} */}
    //             {slogan}
    //         </StyledSlogan>
    //         <StyledLogo className={DEBUG ? 'StyledLogo' : ''}>
    //             <SvgLogoRubikFont  />
    //         </StyledLogo>
    //     </StyledCont>
    // )
    return (
            <StyledSlogan
                className={DEBUG ? `StyledSlogan sloganFontsize__${sloganFontsize}` : ''}
                $sloganFontsize={sloganFontsize}
            >
                {/* {firstPage ? '(first page)' : null} */}
                <StyledCont className={DEBUG ? 'StyledSlogan' : ''}>
                    {
                        // firstPage
                        //     ? null
                        //     : showLogoInSlogan
                        //         ? (
                        //             <StyledLogo className={DEBUG ? 'StyledLogo' : ''}>
                        //                 <SvgLogoRubikFont height={'56px'} />
                        //             </StyledLogo>
                        //         )
                        //         : null
                        !firstPage && showLogoInSlogan
                                ? (
                                    <StyledLogo className={DEBUG ? 'StyledLogo' : ''}>
                                        <SvgLogoRubikFont height={'56px'} />
                                    </StyledLogo>
                                )
                                : null
                    }
                    <StyledText>{slogan}</StyledText>
                </StyledCont>
            </StyledSlogan>
    )
}

export const StyledSlogan = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: ${laborRed};
    color: ${white};
    text-align: center;
    // font-size: 2rem;
    font-size: ${props => props.$sloganFontsize || '2rem'};
    // font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    text-shadow: 2px 2px 6px black;
    padding: .5rem .33rem;
    z-index: 20;
`

const StyledCont = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const StyledText = styled.div`
    flex: 1;
`

const StyledLogo = styled.div`
    // flex: 1;
    // width: 160px;
    padding-right: 1rem;
`
