// components/DonateBtn.js

import React from 'react'
import styled from 'styled-components'
import { laborBlue } from '../common/constants'
// import {
//     StyledSquareBtn,
//     StyledSquareBtnCont,
//     StyledSquareLink,
//     StyledSquareLinkActive,
// } from '../common/Builders'

export const DonateBtn = props => {

    const {
        isActive = false,
        inactiveText = '',
        textPreBtn = 'Campaign',
        paymentBtnText = 'Donate',
        squareDonationBtnLink,
    } = props

    if (!squareDonationBtnLink) return null

    return (
        <StyledSquareBtnCont className='StyledSquareBtnCont' opacity={isActive ? '1' : '.5'}>
            <StyledSquareBtn className='StyledSquareBtn'>
                <p>{textPreBtn}</p>
                {
                    isActive
                        ? (
                            <StyledSquareLinkActive href={squareDonationBtnLink} className='StyledSquareLink'>
                                {paymentBtnText}
                            </StyledSquareLinkActive>
                            
                        )
                        : (
                            <StyledSquareLink>
                                {inactiveText}
                            </StyledSquareLink>
                        )
                }
            </StyledSquareBtn>
        </StyledSquareBtnCont>
    )
}

// export const StyledSquareBtnCont = styled.div.attrs(({ active }) => ({
//     opacity: active ? '1' : '.5',
// }))`
export const StyledSquareBtnCont = styled.div`
    margin: 3rem auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    // width: 260px;
    width: 360px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: -2px 10px 5px rgba(0, 0, 0, 0);
    border-radius: 10px;
    font-family: SQ Market, SQ Market, Helvetica, Arial, sans-serif;
    opacity: ${props => props?.opacity || 1};
`

export const StyledSquareBtn = styled.div`
    font-size: 1.2rem;
    line-height: 1.25rem;
    padding: 1.25rem;
    color: black;
    text-align: center;
    text-transform: uppercase;
    & > p {
        font-size: 1.1rem;
        /* color: ${laborBlue}; */
        color: black;
        text-transform: none;
    }
`

//styled(ScrollLink).attrs(({ className }) => ({
export const StyledSquareLink0 = styled.a.attrs(({ href }) => ({
    'data-url': href || '',
    'data-something': (new Date()).toISOString(),
}))`
    display: inline-block;
    font-size: 1.2rem;
    // line-height: 3rem;
    // height: 3.1rem;
    line-height: 3.2rem;
    height: 3rem;
    color: #ffffff !important;
    min-width: 240px;
    background-color: #cc0023;
    text-align: center;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1) inset;
    border-radius: 0px;
    text-decoration: none;
    padding: 0 2rem;
    &:hover {
        color: #f0f0f0 !important;
    }
    &:active {
        color: #ffffff !important;
    }
`
export const StyledSquareLink = styled.a`
    display: inline-block;
    font-size: 1.2rem;
    line-height: 3.2rem;
    height: 3rem;
    color: #ffffff !important;
    min-width: 240px;
    background-color: #cc0023;
    text-align: center;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1) inset;
    border-radius: 0px;
    text-decoration: none;
    padding: 0 2rem;
    cursor: auto;
`
export const StyledSquareLinkActive = styled(StyledSquareLink).attrs(({ href }) => ({
    'data-url': href || '',
    'data-something': (new Date()).toISOString(),
}))`
    cursor: pointer;
    &:hover {
        color: #f0f0f0 !important;
    }
    &:active {
        color: #ffffff !important;
    }
`
