import React, { useRef, useEffect, useContext } from 'react'
import sha256 from 'crypto-js/sha256' /* NB: crypto-js is no longer maintained!!! */
import { Buffer } from 'buffer'
import { MainContext } from '../contexts/MainContext'

const txt = 'Browser uniqueness <canvas> 0.1.0 😍'

const Canvas = props => {

    const {
        browserFingerprint, setBrowserFingerprint,
    } = useContext(MainContext)

    const canvasRef = useRef(null)

    useEffect(() => {

    }, [browserFingerprint])

    useEffect(() => {
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        //Our first draw
        context.fillStyle = '#000000'
        context.fillRect(0, 0, context.canvas.width, context.canvas.height)

        context.textBaseline = 'top'
        // The most common type
        context.font = '14px \'Arial\''
        context.textBaseline = 'alphabetic'
        context.fillStyle = '#f60'
        context.fillRect(125,1,62,20)
        // Some tricks for color mixing to increase the difference in rendering
        context.fillStyle = '#069'
        context.fillText(txt, 2, 15)
        context.fillStyle = 'rgba(102, 204, 0, 0.7)'
        context.fillText(txt, 4, 17)
        context.fillText(txt, 7, 20)
        context.fillText(txt, 10, 23)

        const dataURL = canvas.toDataURL()
        // console.log(31, dataURL)
        const hash = sha256(dataURL + Buffer.from(navigator.userAgent).toString('base64'))
        // console.log(48, navigator.userAgent, Buffer.from(navigator.userAgent).toString('base64'))
        // console.log(49, hash.toString())
        setBrowserFingerprint(hash.toString())
    }, [setBrowserFingerprint])

    // return <canvas ref={canvasRef} {...props} />
    return <canvas ref={canvasRef} width="0" height="0" />
}

export default Canvas

/*
const Child = React.forwardRef(({ onButtonClick }, ref) => {
  return (
    <>
        <canvas ref={ref} />
        <button onClick={onButtonClick}>save as png</button>
    </>
  );
})
*/