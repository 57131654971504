// Recaptcha.js

import React, { useCallback, useEffect } from 'react'

const {
    REACT_APP_CAPTCHA_V2_SITE_KEY,
    // REACT_APP_CAPTCHA_V3_SITE_KEY,
} = process.env

const Recaptcha = props => {

    const {
        setRecaptchaPassed,
        setRecaptchaLoaded,
    } = props

    const handleReCaptchaV2Loaded = useCallback(() => {
        setRecaptchaLoaded(true)
        // console.log(24, window.grecaptcha)
    }, [setRecaptchaLoaded])

    useEffect(() => {
        // Add reCaptcha
        const script = document.createElement('script')
        script.src = 'https://www.google.com/recaptcha/api.js'
        window.onsubmit = () => {
            setRecaptchaPassed(true)
        }
        script.addEventListener('load', handleReCaptchaV2Loaded)
        document.body.appendChild(script)
    }, [setRecaptchaPassed, handleReCaptchaV2Loaded])

    return (
        <>
            {/* <p>Please verify that you are a person:</p> */}
            <div
                className="g-recaptcha"
                data-sitekey={REACT_APP_CAPTCHA_V2_SITE_KEY}
                data-callback="onsubmit"
            ></div>
        </>
    )
}

export default Recaptcha
