// Home.js

/* 
    BgImg slideshow transitions - using React-Spring:
    - https://www.react-spring.dev/docs/components/use-transition
    - https://codesandbox.io/s/1t9hh
*/

import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { animated, useTransition } from '@react-spring/web'
import { MainContext } from '../contexts/MainContext'
import ModalDonationThanks from '../components/ModalDonationThanks'
import {
    DEBUG,
    breakpoints,
    laborBlue,
    laborRed,
    navbarHeightInPx,
    splashPage,
} from '../common/constants'
import {
    H1C,
    StyledDebugDiv,
    media,
} from '../common/Builders'
import SvgLogoRubikFont from '../components/SvgLogoRubikFont'
import { ModalHTV } from '../components/ModalHTV'

const {
    bgImgs,
    textMdArr,
} = splashPage
// const urlPrefix = process.env.PUBLIC_URL
// const urlPrefix = 'https://dev-240527-1418.titbits.tech' /* helps to slow down and simulate slow loading */
const urlPrefix = 'https://images2.labor4narrabeen.com' /* helps to slow down and simulate slow loading */
const backgrounds = bgImgs && Array.isArray(bgImgs)
    ? bgImgs.map(({ src, alt }, i) => ({ key: i + 1, src: `${process.env.PUBLIC_URL}/images/${src}`, alt }))
    // ? bgImgs.map(({ src, alt }, i) => ({ key: i + 1, src: `${urlPrefix}/${src}?r=${Math.random()}`, alt })) /* helps to slow down and simulate slow loading */
    // ? bgImgs.map(({ src, alt }, i) => ({ key: i + 1, src: `${urlPrefix}/${src}`, alt })) /* helps to slow down and simulate slow loading */
    : null
const textMd = textMdArr && Array.isArray(textMdArr) && textMdArr.length
    ? textMdArr.join('\n')
    : null

const intervalMsec = 9000

export const Home = props => {

    /* this screen picks up the modalDonation bool, and only generates it (and displays it) if nec */
    const { modalDonation } = props

    const {
        debugMode,
        width,
        showModalHTV,
        setShowModalHTV,
    } = useContext(MainContext)

    const [ bgImgSlideIdx, setBgImgSlideIdx ] = useState(0)
    const [ imgsLoaded, setImgsLoaded ] = useState(false)
    const [ bgImgState, setBgImgState ] = useState(
        backgrounds && Array.isArray(backgrounds)
            ? backgrounds
                .map(({ src }) => ({ src, loaded: false, }))
                .reverse()
            : []
    )

    useEffect(() => {
        // console.log(86, {bgImgState})
        const allImagesLoaded = bgImgState.filter(({loaded}) => loaded !== true).length !== 0 ? false : true
        setImgsLoaded(allImagesLoaded)
    }, [bgImgState, setImgsLoaded])

    useEffect(() => {
        // setShowModalHTV(true)

        /* only run on larger devices */
        if (width < breakpoints.md) return

        const timer = setTimeout(() => setShowModalHTV(true), 4500)
        return () => clearTimeout(timer)
    }, [setImgsLoaded, setShowModalHTV])
    
    /* https://codesandbox.io/s/1t9hh?file=/src/App.tsx:153-323 */
    const transitions = useTransition(bgImgSlideIdx, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: intervalMsec },
        onRest: (_a, _b, item) => {
            // console.log(138, item)
            if (bgImgSlideIdx === item) {
                setBgImgSlideIdx(state => (state + 1) % bgImgState.length)
            }
        },
        exitBeforeEnter: true,
    })

    return (
        <HomeScreenRoot className={DEBUG ? 'HomeScreenRoot' : ''}>

            <ImgContainer className={DEBUG ? 'ImgContainer' : ''}>
                <StyledOverlayDiv className={DEBUG ? 'StyledOverlayDiv' : ''}>
                    {
                        debugMode
                            ? (
                                <div style={{ marginTop: '2rem', }}>
                                    {bgImgState.filter(({loaded}) => loaded !== true).length !== 0 ? 'still loading' : 'ALL LOADED'}
                                    <DebugStanza>
                                        <p>bgImgSlideIdx={bgImgSlideIdx}</p>
                                        <pre>background: {JSON.stringify(bgImgState, null, 2)}</pre>
                                    </DebugStanza>
                                    <DebugStanza>
                                        <pre>imgsLoaded: {JSON.stringify(imgsLoaded, null, 2)}</pre>
                                    </DebugStanza>
                                </div>
                            )
                            : null
                    }

                    {
                        width < breakpoints.md
                            ? (
                                <div
                                    style={{
                                        margin: '3rem 1rem',
                                        padding: '1rem 0',
                                        // border: `2px solid ${laborRed}`,
                                        background: `${laborRed}`,
                                        // flex: 1,
                                        // width: '100%',
                                        width: `calc(${width}px - 5%)`,
                                    }}
                                >
                                    <SvgLogoRubikFont
                                        fillColorText1={'white'}
                                        backgroundColorText2={laborBlue}
                                        fillColorText2={'white'}
                                    />
                                </div>
                            )
                            : null
                    }

                    {/* <ReactMarkdown */}
                    <StyledMarkdownSplashText
                        components={{ p: 'div' }}
                        // className="splash-text"
                        className="StyledMarkdownSplashText"
                    >
                        {textMd}
                    </StyledMarkdownSplashText>
                    {/* </ReactMarkdown> */}
                    {
                        DEBUG && debugMode ? <StyledDebugDiv/> : null
                    }
                </StyledOverlayDiv>

                {/* pre-loaded hidden images */}
                {backgrounds.map((e, i) => (
                    <img
                        key={`hiddenbgimg-${i+1}`}
                        src={e?.src}
                        style={{ display: 'none', height: 0, width: 0, }}
                        onLoad={() => {
                            // console.log(112, `img i=${i} loaded`)
                            if (!e?.src) return
                            setBgImgState(prev => prev.map(img => img?.src === e.src ? { ...img, loaded: true } : img))
                        }}
                        alt=""
                    />
                ))}

                <div
                    className="bgContainer"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                    }}
                >

                    {imgsLoaded && bgImgState && Array.isArray(bgImgState) && bgImgState.length
                        ? transitions((style, item) => (
                            <animated.img
                                className={`animatedImg item__${item}`}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    objectFit: 'cover',
                                    ...style,
                                }}
                                src={bgImgState[item]?.src}
                                // data-loaded={item.loaded ? 'yes' : 'no'}
                                alt=""
                            />
                        ))
                        : (
                            <img
                                className={`staticImage`}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    objectFit: 'cover',
                                }}
                                src={backgrounds[0]?.src}
                                // alt={backgrounds[0]?.alt}
                                alt=""
                            />
                        )
                    }
                    
                </div>

            </ImgContainer>

            {/* <StyledDebugDiv />
            <H1C>Sue Wright</H1C>
            <H1C>Labor for Narrabeen</H1C>
            <pre>{bgImgPool[0]}</pre>
            <pre>modalDonation: {modalDonation === true ? 'true' : modalDonation === false ? 'false' : '---'}</pre> */}

            {modalDonation === true
            ? (
                <ModalDonationThanks
                    isOpen={modalDonation}
                />
            )
            : null
            }
            
            {showModalHTV === true
            ? (
                <ModalHTV
                    isOpen={showModalHTV}
                />
            )
            : null
            }
            
            {/* <pre>background: {JSON.stringify(background)}</pre> */}

        </HomeScreenRoot>
    )
}
/* cf https://www.youtube.com/watch?v=6Luw79S_fMY for this solution */

const StyledOverlayDiv = styled.div`
    background: rgba(0, 0, 0, .25);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    color: white;
    font-size: 1.33rem;
    text-align: center;
    text-wrap: balance;
    display: flex;
    // align-items: flex-end;
    align-items: center;
    // margin: 4rem 60px 60px 60px;
    flex-direction: column;
    justify-content: flex-end; /* push to the bottom */
    padding-bottom: 6rem;

    /* for >= 377px width */
    ${media.sm`
        // background: blue;
    `}

    /* for >= 576px width */
    ${media.md`
        // background: yellow;
    `}

    /* for >= 768px width */
    ${media.lg`
        // background: purple;
    `}

    /* for >= 992px width */
    ${media.xl`
        // background: purple;
    `}
    
`

// const StyledBgImg = styled.img`
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
//     object-fit: cover;
// `
// const StyledImgAnimated = styled(StyledBgImg)`
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
//     object-fit: cover;
// `
const ImgContainer = styled.div`
    height: 100vh;
    width: 100%;
    object-fit: cover; /* best practice: no repeat and maintains aspect ratio */
    position: relative;
    display: flex;
    // justify-content: center; /* aligns horiz'ly */
    // align-items: center; /* aligns vertically */
`
const HomeScreenRoot = styled.div`
    // height: calc(100vh - 74px);
    height: calc(100vh - ${navbarHeightInPx}px);
    overflow: hidden;
    outline: 3px solid aliceblue;
    position: absolute;
    // top: 64px;
    top: ${navbarHeightInPx}px;
    // left: 9px;
    left: 0;
    // right: 9px;
    right: 0;
    // bottom: 9px;
    bottom: 0;
`

// const StyledSplashText = styled.div`
//     flex: 1;
// `

const DebugStanza = styled.div`
    font-size: .9rem;
`

const StyledMarkdownSplashText = styled(ReactMarkdown)`

    margin: 1rem;
    padding: 1rem;
    text-shadow: 2px 2px 6px black;
    background: rgba(176, 68, 68, 0.6);
    font-size: 1.33rem;

    // &:after {
    //     content: '~ XS ${breakpoints.xs}px';
    // }

    /* for >= 377px width */
    ${media.sm`
        // background: blue;
        font-size: 1.5rem;
        // &:after {
        //     content: '~ SM ${breakpoints.sm}px';
        // }
    `}

    /* for >= 576px width */
    ${media.md`
        // background: yellow;
        font-size: 2rem;
        // &:after {
        //     content: '~ MD ${breakpoints.md}px';
        // }
    `}

    /* for >= 768px width */
    ${media.lg`
        // background: purple;
        font-size: 3rem;
        margin: 4rem 9rem;
        padding: .5rem;
        // &:after {
        //     content: '~ LG ${breakpoints.lg}px';
        // }
    `}

    /* for >= 992px width */
    ${media.xl`
        // background: purple;
        font-size: 4rem;
        // &:after {
        //     content: '~ XL ${breakpoints.xl}px';
        // }
    `}
    
`


/* 
&:after {
        content: '+ XS ${breakpoints.xs}px';
*/