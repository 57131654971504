import React, { useEffect } from 'react'
import {
    Routes,
    Route,
} from 'react-router-dom'
import ReactGA from 'react-ga4'
import { RootCont } from './RootCont'
import { struct } from '../common/constants'
import DonationDeclaration from './DonationDeclaration'
import { Subpage } from './Subpage'
import { ScrollToTop } from './ScrollToTop'
import { HowToVote } from './HowToVote'

const Main = () => {

    useEffect(() => {
        if (!struct || !Array.isArray(struct)) return
        struct.filter(({ ga4 }) => ga4).forEach(({ ga4 }) => {
            ReactGA.initialize(ga4)
            /* Send pageview with a custom path */
            // ReactGA.send({ hitType: 'pageview', page: '/landingpage', title: 'Landing Page' })
        })
    }, [])

    return (
        <div className="Main">
            
            {/* ensure scroll to top on route change cf https://stackoverflow.com/a/61602724/24018747 */}
            <ScrollToTop />
            
            <Routes>
                <Route path='/' element={ <RootCont /> } />
                {/* <Route path='/contact' element={ <RootCont jumpto={'contact'} /> } /> */}
                {/* <Route path='/donate' element={ <RootCont jumpto={'donate'} /> } /> */}
                {
                struct && Array.isArray(struct)
                    // ? struct.map(({ key, path }, i) => (
                    ? struct.filter(e => e?.path).map(({ key, path }, i) => (
                        <Route
                            key={`routes${i+1}`}
                            // path={`/${e?.key || ''}`}
                            path={`${path || '/'}`}
                            element={ <RootCont jumpto={key || ''} /> }
                        />
                    ))
                    : null
                }
                <Route path='/donation_declaration' element={ <DonationDeclaration /> } />

                {/* for donation thanks path, cause a modal to be displayed */}
                {/* NOTE: the modal is created (if nec) on the Home section but
                    will cause a scroll-jump to Donate section */}
                <Route path='/donation_thanks' element={ <RootCont modalDonation={true} jumpto={'donate'} /> } />

                {/* <Route path='/sub/:key' element={ <Subpage { ...{ key }} /> } /> */}
                <Route path='/sub/*' element={ <Subpage hideSocials={true} /> } />

                {/* <Route path='/htv' element={ <HowToVote { ...{ standalone: true } } /> } />
                <Route path='/howtovote' element={ <HowToVote { ...{ standalone: true } } /> } />
                <Route path='/how-to-vote' element={ <HowToVote { ...{ standalone: true } } /> } />
                <Route path='/HowToVote' element={ <HowToVote { ...{ standalone: true } } /> } />
                <Route path='/How-To-Vote' element={ <HowToVote { ...{ standalone: true } } /> } /> */}

            </Routes>
            {/* 
            {
            struct && Array.isArray(struct)
                ? struct.map(({ key, path }, i) => (
                    <li key={`li-routes-${i+1}`}>
                        path={`${path || '/'}`}
                        <br/>                        
                        <Link to={`${path || '/'}`}>
                            element= jumpto={key || '/'}
                        </Link>
                    </li>
                ))
                : null
            }
            */}
        </div>
    )
}

export default Main
