import React, { useContext } from 'react'
import { Element } from 'react-scroll'
import {
    media,
    sectionDefaultLeftRightPadding,
} from '../common/Builders'
import {
    bgLaborRedOldfashioned,
    breakpoints,
    DEBUG,
    defaultSectionPaddingBottom,
    navbarHeightInPx,
    sectionMaxWidthsInPx,
    sloganFontsize,
} from '../common/constants'
import { MainContext } from '../contexts/MainContext'
import styled from 'styled-components'
import { Slogan } from './Slogan'
// import { currentBreakpointFromWidth } from '../common/functions'

export const Section = props => {

    const {
        children,
        name,
        bgcol,
        bgimg,
        color,
        // width_struct,
        leftRightPadding, /* overrides default setting */
        // overrideWidth,
        firstPage,
    } = props

    const {
        debugMode,
        height,
        width,
        currentBreakpoint,
    } = useContext(MainContext)

    /* these are triggered when a link to a section is clicked */
    // useEffect(() => {
    //     Events.scrollEvent.register('begin', (to, element) => {
    //         console.log(44, 'begin', to, element)
    //     })
    //     Events.scrollEvent.register('end', (to, element) => {
    //         console.log(47, 'end', to, element)
    //     })

    //     scrollSpy.update()

    //     // Returning a cleanup function to remove the registered events when the component unmounts.
    //     return () => {
    //         Events.scrollEvent.remove('begin')
    //         Events.scrollEvent.remove('end')
    //     }
    // }, [])
    
    /* for small devices, increase the bottom padding and decrease fontsize */
    const showLogoInSlogan = width < breakpoints.md ? true : false
    const paddingBottom = width < breakpoints.md
        ? '4rem'
        : defaultSectionPaddingBottom
    const _sloganFontsize = currentBreakpoint && sloganFontsize?.[currentBreakpoint]
        ? sloganFontsize[currentBreakpoint]
        : '2rem'
    // if (DEBUG) console.log(44, { currentBreakpoint, sloganFontsize, _sloganFontsize })

    const styledBgImg = bgimg
        ? ({
            backgroundImage: `url(${bgimg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        })
        : {}

    // console.log(29, { name })

    return (
        <Element 
            { ...{ name, className: name } }
            style={{
                background: bgcol || 'auto',
                ...styledBgImg,
                position: 'relative', /* needed for slogan */
                // paddingBottom: '4rem',
                // marginBottom: '4rem',

                /* gives white line at end of section */
                // marginBottom: '.3rem',

                /* gives oldfashioned red border at bottom */
                borderBottom: `3px solid ${bgLaborRedOldfashioned}`,
                // paddingBottom: '3px',
                paddingBottom: paddingBottom,
            }}
        >
            <StyledSection
                background={bgcol} /* TODO: this triggers warning in debug console ??? */
                color={color}
                // bgimg={bgimg}
                className={`StyledSection name__${name} leftRightPadding.sm__${leftRightPadding?.sm}`}
                $leftRightPadding={leftRightPadding?.[currentBreakpoint] || '0'}
                // paddingLeft={leftRightPadding}
                // paddingRight={leftRightPadding}
            >
                {/* bgimg={bgimg} */}
                {/* <pre>{JSON.stringify(
                        Object.keys({...props})
                            .filter(key => typeof props?.[key] === 'string')
                            .map(key => ({ [key]: props?.[key] })), null, 2)}
                    </pre> */}
                {
                    DEBUG && debugMode
                        ? (<>
                            <pre className='Section-PRE-48'>{JSON.stringify(props?.leftRightPadding, null, 2)}</pre>
                            <pre>{JSON.stringify({ width, height }, null, 2)}</pre>
                        </>)
                        : null
                }
                {children}
            </StyledSection>

            {/* slogan */}
            {/* showLogoInSlogan={showLogoInSlogan?1:0} */}
            {/* <br/>firstPage={firstPage?1:0} */}
            <Slogan
                firstPage={firstPage}
                showLogoInSlogan={showLogoInSlogan}
                sloganFontsize={_sloganFontsize}
            />

        </Element>
    )
}

// const StyledElement = styled(Element)`
//     background: rgb(21, 80, 147);
//     position: relative;
//     border-bottom: 3px solid rgb(194, 57, 49);
//     padding-bottom: 3px;
// `

export const StyledSection = styled.section`
    /* designing for mobile (smallest) first, i.e. xs width is default */

    height: 100vh;
    margin: 0;
    padding-top: ${navbarHeightInPx}px; /* enough for space behind the top menu */

    // padding-left: ${sectionDefaultLeftRightPadding};
    // padding-right: ${sectionDefaultLeftRightPadding};

    // /* allow left/right padding override for current breakpoint */
    // padding-left: ${props => props?.leftRightPadding?.xs || sectionDefaultLeftRightPadding};
    // padding-right: ${props => props?.leftRightPadding?.xs || sectionDefaultLeftRightPadding};

    padding-left: ${props => typeof props.$leftRightPadding !== 'undefined' ? props.$leftRightPadding : sectionDefaultLeftRightPadding};
    padding-right: ${props => typeof props.$leftRightPadding !== 'undefined' ? props.$leftRightPadding : sectionDefaultLeftRightPadding};
    
    color: ${props => props?.color ? props.color : 'white'};

    margin-left: auto;
    margin-right: auto;
    
    // overflow: scroll;
    overflow: auto;
    width: auto;

    &:-webkit-scrollbar {
        display: none;
    }

    /* min-width: 377px */
    ${media.sm`      
        /* allow left/right padding override for current breakpoint */
        padding-left: ${props => props?.leftRightPadding?.sm || sectionDefaultLeftRightPadding};
        padding-right: ${props => props?.leftRightPadding?.sm || sectionDefaultLeftRightPadding};
    `}

    /* min-width: 576px */
    ${media.md`
        width: 570px;
        max-width: 570px;
        width: ${sectionMaxWidthsInPx.md}px;
        max-width: ${sectionMaxWidthsInPx.md}px;

        /* allow left/right padding override for current breakpoint */
        padding-left: ${props => props?.leftRightPadding?.md || sectionDefaultLeftRightPadding};
        padding-right: ${props => props?.leftRightPadding?.md || sectionDefaultLeftRightPadding};
    `}
    
    /* min-width: 768px */
    ${media.lg`
        width: 740px;
        max-width: 740px;
        width: ${sectionMaxWidthsInPx.lg}px;
        max-width: ${sectionMaxWidthsInPx.lg}px;

        /* allow left/right padding override for current breakpoint */
        padding-left: ${props => props?.leftRightPadding?.lg || sectionDefaultLeftRightPadding};
        padding-right: ${props => props?.leftRightPadding?.lg || sectionDefaultLeftRightPadding};
    `}
    ${media.xl`
        // width: 900px; /* absolute max width */
        width: ${sectionMaxWidthsInPx.xl}px;
        max-width: ${sectionMaxWidthsInPx.xl}px;

        /* allow left/right padding override for current breakpoint */
        padding-left: ${props => props?.leftRightPadding?.xl || sectionDefaultLeftRightPadding};
        padding-right: ${props => props?.leftRightPadding?.xl || sectionDefaultLeftRightPadding};
    `}
}
`
