/* eslint-disable no-unreachable */
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Link as ScrollLink } from 'react-scroll'
import {
    ClickableSpan,
    StyledDebugDiv,
    media,
} from '../common/Builders'
import {
    DEBUG,
    breakpoints,
    // bgLaborBlue,
    laborBlue,
    laborRed,
    navbarHeightInPx,
    scrollDuration,
    struct,
    white,
    whiteish,
    zIndex,
} from '../common/constants'
import SvgLogoRubikFont from './SvgLogoRubikFont'
import { scrollToTop } from '../common/functions'
import { MainContext } from '../contexts/MainContext'
import { Burger, BurgerMenu } from './Burger'

const NavLink = props => {

    const {
        children,
        to,
        ga4,
        // active,
        setActive,
    } = props
    let { 
        flex, /* override flex: 1, optionally 2, 3 */
    } = props

    // flex = (flex && !isNaN(flex) && (flex === 2 || flex === 3)) ? flex : 1
    flex = !flex || isNaN(flex) ? 'none' : 1

    return (
        <StyledLinkMainMenu
            className={DEBUG ? 'StyledLinkMainMenu' : ''}
            activeClass={'activated'}
            to={to}
            spy={true}
            flex={flex}
            smooth={true}
            duration={scrollDuration}
            onSetActive={(e) => {
                if (DEBUG) console.log(52, 'on set active', e)
                setActive(e)
            }}
            // onSetInActive={(e) => console.log(52, 'on set IN active', e)}
            onClick={() => { if (DEBUG) console.log(56, 'onClick ga4', ga4)} }
        >
            {children}
        </StyledLinkMainMenu>        
    )
}

const NavBarNarrow = ({ open, setOpen }) => {

    /* UP TO HERE!!! Handling Google Analytics */
    /* cf 
    - https://www.npmjs.com/package/react-ga4
    - https://medium.com/@b.ramu439/implementing-google-analytics-in-react-with-react-ga4-c3e3ddb24e3a  
    */

    const handleMenuLinkClick = item => {
        console.log(63, { item })
        console.log(64, item?.ga4, item?.key, item?.path)
        setOpen(false)
    }

    return (
        <StyledHeader className={DEBUG ? 'StyledHeader' : ''}>

            {
                open
                    ? (
                        <StyledLogo>
                            <SvgLogoRubikFont
                                height={'57px'}
                                />
                        </StyledLogo>
                    )
                    : null
            }

            <Burger open={open} setOpen={setOpen} />
            
            <BurgerMenu
                open={open}
                setOpen={setOpen}
                className={DEBUG ? 'BurgerMenu' : ''}
            >
                {/* HOME not needed here */}
                {/* <NavLink
                    className={DEBUG ? 'StyledLinkMainMenu' : ''}
                    to='/'
                    flex={'none'}
                >
                    <StyledInnerMenuLink className={DEBUG ? 'StyledInnerMenuLink' : ''}>
                        HOME
                    </StyledInnerMenuLink>
                </NavLink> */}
                {
                    struct
                        .filter(({disabled}) => disabled === true ? false : true)
                        .map((e, i) => (<React.Fragment key={`mobilelink-${i+1}`}>
                        {
                            e?.menuLabel
                                ? (
                                    <NavLink
                                        className={DEBUG ? 'NavLink' : ''}
                                        to={`section${i+1}`}
                                        flex={0}
                                        // flex={3}
                                    >
                                        <StyledInnerMenuLink
                                            className={DEBUG ? 'StyledInnerMenuLink' : ''}
                                            // onClick={() => setOpen(false)}
                                            onClick={() => handleMenuLinkClick(e)}
                                        >
                                            {e?.menuLabel || '---'}
                                        </StyledInnerMenuLink>
                                    </NavLink>
                                )
                                : null
                        }
                    </React.Fragment>))
                }
                {DEBUG ? <StyledDebugDiv /> : null}
                {DEBUG ? <>Version: {process.env.REACT_APP_VERSION}</> : null}

                {/* <code>{location.pathname}</code> */}

            </BurgerMenu>

        </StyledHeader>
    )
}

const NavBarWide = ({ active, setActive }) => {

    return (
        <div style={{ position: 'relative', }}>
            <NavbarCont className={DEBUG ? 'NavbarCont' : ''}>
                <div
                    style={{ margin: 'auto 6px 0 6px', width: '200px', }}
                >
                    <ClickableSpan
                        className={DEBUG ? 'ClickableSpan' : ''}
                        onClick={() => scrollToTop()}
                    >
                        <SvgLogoRubikFont
                            fillColorText1={'white'}
                            backgroundColorText2={laborBlue}
                            fillColorText2={'white'}
                        />
                    </ClickableSpan>
                </div>
                {/* { DEBUG ? <>struct.length = { struct.length }</> : null } */}
                {
                struct
                    .filter(({disabled}) => disabled === true ? false : true)
                    .map((e, i) => (<React.Fragment key={`navlink-${i+1}`}>
                    {
                        e?.menuLabel
                            ? (
                                <NavLink
                                    // key={`navlink${i+1}`}
                                    n={i+1}
                                    // to={`section${i+1}`} {...{active, handleOnClick}}
                                    to={`section${i+1}`}
                                    flex={1}
                                    ga4={e?.ga4} /* Google Analytics */
                                    { ...{ active, setActive, }}
                                >
                                    <StyledInnerMenuLink
                                        className={DEBUG ? 'StyledInnerMenuLink' : ''}
                                    >
                                        {e?.menuLabel || '---'}
                                        {/* DEBUG: */}
                                        {/* {e?.order} */}
                                    </StyledInnerMenuLink>
                                </NavLink>
                            )
                            : null
                    }
                </React.Fragment>))
                }
            </NavbarCont>
        </div>
    )
}

export const Navbar = () => {

    const [ open, setOpen ] = useState(false)

    const {
        debugMode,
        width,
        active, setActive,
    } = useContext(MainContext)

    /* for small devices */
    return (
        <>
        {
            // width < breakpoints.md
            width < breakpoints.lg
            ? <NavBarNarrow { ...{ open, setOpen }} />
            : <NavBarWide { ...{ active, setActive, }} />
        }
        </>
    )
}

const StyledHeader = styled.header`
    position: relative;
    z-index: ${zIndex?.NavbarCont || 999};
    color: ${laborBlue}
`

const StyledInnerMenuLink = styled.div`

    /* animated expanding bottom border, cf https://stackoverflow.com/a/27164099/23517902 */
    &:after {
        content: '';
        display: block;
        margin: auto;
        height: 3px;
        height: 6px;
        width: 0px;
        background: transparent;
        transition: width .5s ease, background-color .5s ease;
        margin-top: 6px;
    }
    &:hover:after {
        // width: 100%;
        width: 50%;
        // background: ${laborBlue};
        background: ${whiteish};
    }

    text-shadow: 2px 2px 6px ${laborBlue};
    font-size: 1.67rem;
    // padding: .5rem;
    padding: 0;

    border: 0px solid yellow;
    
    ${media.md`
        border: 0px solid magenta;
        
    `}

    ${media.lg`
        border: 0px solid salmon;
    `}

    ${media.xl`
        border: 0px solid purple;
        margin: auto 2rem;
        // padding: 0 2rem;
        padding: 0;
        // padding: 6px;
        cursor: pointer;

        font-size: 1.1rem;
        font-weight: 600;

        /* animated expanding bottom border, cf https://stackoverflow.com/a/27164099/23517902 */

    `}
    // ${media.lg`    `}
`

// export const StyledLinkMainMenu = styled(ScrollLink).attrs(({ className }) => ({
//     border: className === 'active' ? '2px solid lightcoral' : 'none',
// }))`
export const StyledLinkMainMenu = styled(ScrollLink)`
    // flex: 1;
    flex: ${props => props?.flex ? props.flex : 1};
    border: 0px solid lightcoral;
    border: ${props => props.border};
    // width: 9rem;
    // text-align: center;
    // padding: 6px 9px;
    padding: 6px 0;
    // margin: 3px 18px;
    margin: 3px 3px;
    color: ${white};
    
    text-align: center;
    /* cf https://cssgradient.io/ */
    // background: radial-gradient(
    //     circle, 
    //     rgba(255,255,255,1) 0%,
    //     rgba(21,81,149,1) 60% 
    // );
    // background: rgb(21,81,149);
    // background: radial-gradient(circle, rgba(21,81,149,0.4051995798319328) 0%, rgba(117,136,158,1) 35%, rgba(21,81,149,1) 65%);

    /* using the react-scroll activeClass= attribute */
    &.activated {
        // background: white;
        // background: rgba(250, 250, 250, .2);
        // color: pink;
    }
    &.activated:after {
        content: ' ';
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: -3px;
        margin-top: -6px;
        // height: 3px;
        height: 6px;
        width: 20px;
        // background: rgba(250, 250, 250, .5);
        // background: #15519588;
        background: white;
        // transform: rotate(-15deg) translateX(-10px);
    }
    &:hover {
        color: ${white};
    }
`

const NavbarCont = styled.nav`
    // z-index: 1000; /* needed to prevent Row/Col appearing on top */
    z-index: ${zIndex?.NavbarCont || 999}; /* needed to prevent Row/Col appearing on top */
    height: ${navbarHeightInPx}px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    // text-shadow: 2px 2px 6px ${laborBlue};
    text-shadow: 2px 2px 6px black;
    /* background: ${white}; */
    /* background: ${laborBlue}; */
    background: ${laborRed};
    color: ${white};

    /* move these into StyledInnerMenuLink */
    // font-size: 1.1rem;
    // font-weight: 600;
`

const StyledLogo = styled.div`
    position: absolute;
`
