

import React, { useContext } from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import {
    breakpoints,
    DEBUG,
    laborRed,
    white,
} from '../common/constants'
import { MainContext } from '../contexts/MainContext'
import { useNavigate } from 'react-router-dom'
import { media } from '../common/Builders'

const htvImgPath = `${process.env.PUBLIC_URL}/images/NBS-2024-02141-p1.png`

const linkText = '&gt;&gt; How to vote Labor in Narrabeen'

export const HowToVote = props => {

    const {
        standalone = false,
        marginTopinPx = 0,
    } = props

    const {
        debugMode,
        width,
        showModalHTV,
        setShowModalHTV,
    } = useContext(MainContext)

    console.log(26, { standalone })

    const navigate = useNavigate()

    const notfixed = width < breakpoints.md ? true : false

    const onClickHowToVote = () => {
        /* only on larger devices */
        if (width < breakpoints.md) {
            navigate('/sub/howtovote')
        }
        if (width >= breakpoints.md) setShowModalHTV(!showModalHTV)
    }

    if (standalone) return (
        <StyledHowToVoteStandaloneDiv $margintopinpx={marginTopinPx}>
            <StyledHowToVoteStandaloneImg
                src={htvImgPath}
                alt="How to Vote Labor, Narrabeen Ward, Northern Beaches Council"
                title="How to Vote Labor, Narrabeen Ward, Northern Beaches Council"
            />
        </StyledHowToVoteStandaloneDiv>
    )

    return (
        <StyledHowToVoteCont $notfixed={notfixed} className='StyledHowToVoteCont'>
        {/*
            showModalHTV
                ? <div>{linkText}</div>
                : (
                    <div>
                        <H1C>showModalHTV: {showModalHTV ? 'yes' : 'no'}</H1C>

                        <H1C
                            onclick={() => setShowModalHTV(!showModalHTV)}
                        >{linkText}***</H1C>
                    </div>
                )
        */}
        {/*
            <h5>showModalHTV: {showModalHTV ? 'yes' : 'no'}</h5>
        */}
        {
            showModalHTV
                ? null
                : (
                    <StyledHowToVoteDiv
                        // onClick={() => {
                        //     console.log(48, { showModalHTV })
                        //     setShowModalHTV(!showModalHTV)
                        // }}
                        onClick={onClickHowToVote}
                    >
                        <ReactMarkdown
                            components={{
                                p(props) {
                                    return <div>{props?.children}</div>
                                },
                            }}
                        >
                            {linkText}
                        </ReactMarkdown>
                    </StyledHowToVoteDiv>
                )
        }
        </StyledHowToVoteCont>
    )

}

const StyledHowToVoteCont = styled.div`
    // position: fixed;
    /* for small devices make position absolute so it doesn't remain fixed on the screen: */
    position: ${props => props?.$notfixed ? 'absolute' : 'fixed'};
    right: 0;
    // top: calc(21vh + 120px); /* add to height of socials */
    top: calc(21vh - 60px); /* add to height of socials */
    // width: 120px;
    padding-right: .5rem;
    color: ${laborRed};
    background: ${white};
    z-index: 200;
    display: flex;
    flex-direction: column;
    // justify-content: flex-start;
    align-items: flex-end;
    font-weight: 900;
    max-width: 100vw;
`

const StyledHowToVoteDiv = styled.div`
    padding: .33rem;
    cursor: pointer;
`

const StyledHowToVoteStandaloneDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: ${props => props.$margintopinpx ? `calc(100vh - ${props.$margintopinpx}px)` : 0};
    margin-top: ${props => props.$margintopinpx ? `${props.$margintopinpx}px`: 0};
`

const StyledHowToVoteStandaloneImg = styled.img`
    display: block;

    // max-width: 100vw;
    // width: 100vw;
    // height: fit-content;
    // // height: auto;
    
    // max-width: 100%;
    // height: fit-content;
    // height: auto;
    // aspect-ratio: 405/287;

    // width: 100%;
    // height: auto;
    // aspect-ratio: attr(width) / attr(height);

    // display: inline-block;
    // max-width: 100%;
    // max-width: 100vw;
    // aspect-ratio: 1;

    // width: 100vw;
    // block-size: fit-content;

    object-fit: contain;
    width: 100vw;
    max-width: 100vw;

    /* min-width: 576px */
    ${media.md`
        max-width: 100vw;
        width: 100vw;
        max-height: 100vh;
        width: 100vh;
        aspect-ratio: auto;
    `}

}
`
