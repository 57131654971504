// Builders.js

import styled, { createGlobalStyle, css } from 'styled-components'
import { Link as ScrollLink } from 'react-scroll'
import {
    navbarHeightInPx,
    defaultBackground,
    whiteish,
    white,
    laborRed,
    laborBlue,
    laborBlueVeryLight,
    // bgLaborBlue,
    // bgLaborRed,
    breakpoints,
    laborBlueExtremelyLight,
    sectionMaxWidthsInPx,
    bgLaborRedOpac60pc,
    zIndex,
    DEBUG,
} from './constants'
// import bgImg from '../assets/images/2022-12-30-sue-wright-3.webp'
// import { NavLink } from 'react-router-dom'

// const {
//     Content,
// } = Layout
/*
const device_sizes = {
    xs: '400px', // for small screen mobile
    sm: '600px', // for mobile screen
    md: '900px', // for tablets
    lg: '1280px', // for laptops
    xl: '1440px', // for desktop / monitors
    xxl: '1920px', // for big screens
}
  
const device = {
    xs: `(max-width: ${device_sizes.xs})`,
    sm: `(max-width: ${device_sizes.sm})`,
    md: `(max-width: ${device_sizes.md})`,
    lg: `(max-width: ${device_sizes.lg})`,
    xl: `(max-width: ${device_sizes.xl})`,
    xxl: `(max-width: ${device_sizes.xxl})`,
}
*/

/* cf https://www.codevertiser.com/styled-components-media-queries/ */
/* then inside style def:
    ${media.sm`
        flex-direction: column;
    `}
*/
/*
const media0 = {
    xs: (...args) => css`
      @media (max-width: ${breakpoints.xs}px) {
        ${css(...args)};
      }
    `,
    sm: (...args) => css`
      @media (max-width: ${breakpoints.sm}px) {
        ${css(...args)};
      }
    `,
    md: (...args) => css`
      @media (max-width: ${breakpoints.md}px) {
        ${css(...args)};
      }
    `,
    lg: (...args) => css`
      @media (max-width: ${breakpoints.lg}px) {
        ${css(...args)};
      }
    `,
    xl: (...args) => css`
      @media (max-width: ${breakpoints.xl}px) {
        ${css(...args)};
      }
    `,
    xxl: (...args) => css`
      @media (max-width: ${breakpoints.xxl}px) {
        ${css(...args)};
      }
    `,
}
*/

/* strategy: mobile first */
export const media = {
    xs: (...args) => css`
        @media only screen and (max-width: ${breakpoints.xs}px) {
            ${css(...args)};
        }
    `,
    sm: (...args) => css`
        @media only screen and (min-width: ${breakpoints.xs + 1}px) {
            ${css(...args)};
        }
    `,
    md: (...args) => css`
        @media only screen and (min-width: ${breakpoints.sm}px) {
            ${css(...args)};
        }
    `,
    lg: (...args) => css`
        @media only screen and (min-width: ${breakpoints.md}px) {
            ${css(...args)};
        }
    `,
    xl: (...args) => css`
        @media only screen and (min-width: ${breakpoints.lg}px) {
            ${css(...args)};
        }
    `,
    xxl: (...args) => css`
        @media only screen and (min-width: ${breakpoints.xl}px) {
            ${css(...args)};
        }
    `,
}


export const GlobalStyle = createGlobalStyle`
    html, body {
        margin: 0;
        padding: 0;
    }
    *, *::after, *::before {
        box-sizing: border-box;
    }
    body {
        background: white;
        // background: ${defaultBackground};
        font-family: Raleway, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
    }
    a, a:visited {
        color: ${laborBlueVeryLight};
    }
    a:hover {
        color: ${laborBlueExtremelyLight};
    }
    a:active {
        color: ${laborRed};
    }
    label {
        color: ${white} !important;
    }
    article.donor-declaration a,
    article.donor-declaration a:hover,
    article.donor-declaration a:visited {
        color: ${laborBlue} !important;
    }
    article.donor-declaration {
        // width: 78vw;
        max-width: 700px;
        padding: 1vh 2vw;
        margin: auto;
    }

    /* override styles for on hover donor declaration button */
    /* cf https://stackoverflow.com/a/54192997/23517902 */
    .ant-btn-background-ghost {
        background: transparent;
        // border: 2px solid ${whiteish};
        margin-left: .33rem;
        &:hover {
            // border-color: crimson !important;
            // border-color: ${laborRed}44 !important;
            border-color: ${white}44 !important;
            // background: #33333388 !important;
            background: #ffffff28 !important;
            // background: transparent !important;
            color: ${white} !important;
        }
    }

    /* override ant- */
    .ant-col,
    .ant-checkbox-wrapper,
    .ant-list,
    .ant-list-header {
        font-family: Raleway, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif !important;
        font-size: 1rem;
    }
    .ant-list span,
    .ant-list-header {
        color: ${white} !important;
    }
    .ant-list-item {
        padding: 0 !important;
    }

    form p {
        color: ${whiteish}; /* DEBUG */
    }

    .splash-text {
        // margin: 1rem 6rem;
        // padding: 3rem;
        margin: 4rem 9rem;
        text-shadow: 2px 2px 6px black;
        background: ${bgLaborRedOpac60pc};
    }
    
    div.spash-text > div {
        border: 0px solid yellow;
        text-align: left;
    }

    .donate-blurb {
        line-height: 1.75;
    }

    p {
        line-height: 1.7;
    }

    .modal-howtovote.ant-modal {
        width: 100%;
        // transform-origin: 85px 43px;
        overflow: hidden;
        height: -webkit-fill-available;
    }

`

export const sectionDefaultLeftRightPadding = '4rem'
// background-color: lightcoral;
// background: #f0808090;
// export const StyledSection = styled.section`
/* designing for mobile (smallest) first using media queries, i.e. xs width is default */
// export const StyledSection = styled.section.attrs(({ bgimg, $leftRightPadding }) => ({
//     bgimg,
//     // leftRightPadding: leftRightPadding || '4rem', /* default left right padding: 4rem */
//     // paddingLeft: paddingLeft || '4rem', /* default left right padding: 4rem */
//     // paddingRight: paddingRight || '4rem', /* default left right padding: 4rem */
//     // 'padding-left': $leftRightPadding || '4rem',
//     // 'padding-right': $leftRightPadding || '4rem',
//     // 'padding-left': `${sectionDefaultLeftRightPadding}`,
//     // 'padding-right': `${sectionDefaultLeftRightPadding}`,
// }))`
export const StyledSection0 = styled.section`
    // height: calc(100vh - ${navbarHeightInPx}px);
    height: 100vh;
    margin: 0;
    // margin: ${navbarHeightInPx}px 0 0 0;
    // padding-top: 10px;
    padding-top: ${navbarHeightInPx}px; /* enough for space behind the top menu */

    padding-left: ${sectionDefaultLeftRightPadding};
    padding-right: ${sectionDefaultLeftRightPadding};
    // padding-left: ${props => typeof props.$leftRightPadding !== 'undefined' ? props.$leftRightPadding : sectionDefaultLeftRightPadding};
    // padding-right: ${props => typeof props.$leftRightPadding !== 'undefined' ? props.$leftRightPadding : sectionDefaultLeftRightPadding};

    // background: ${props => props?.background ? props.background : defaultBackground};

    // background-image: url(${props => props?.bgimg ? props.bgimg : 'none'});
    // background-repeat: no-repeat;
    // background-size: cover;
    
    color: ${props => props?.color ? props.color : 'white'};

    // width: 70vw;
    margin-left: auto;
    margin-right: auto;
    
    // overflow: scroll;
    overflow: auto;

    /* designing for mobile (smallest) first, i.e. xs width is default */
    // width: 360px; /* allow for at least 8px on each side */
    // max-width: 360px;
    // width: ${sectionMaxWidthsInPx.xs}px;
    // max-width: ${sectionMaxWidthsInPx.xs}px;
    width: auto;

    // border: 5px solid cyan; /* DEBUG */

    /* allow left/right padding override for current breakpoint */
    padding-left: ${props => props?.leftRightPadding?.xs || sectionDefaultLeftRightPadding};
    padding-right: ${props => props?.leftRightPadding?.xs || sectionDefaultLeftRightPadding};

    &:-webkit-scrollbar {
        display: none;
    }

    // ${media.xs`
    //     width: 361px; /* allow for at least 8px on each side */
    //     max-width: 361px;
    //     border: 5px solid white; /* DEBUG */
    // `}

    /* min-width: 377px */
    ${media.sm`
        // width: 360px; /* allow for at least 16px on each side */
        // max-width: 360px;
        // width: ${sectionMaxWidthsInPx.sm}px;
        // max-width: ${sectionMaxWidthsInPx.sm}px;
        // border: ${DEBUG ? 3 : 0}px solid pink; /* DEBUG */

        /* DEBUG: */
        // &:before {
        //     // content: ' SM sectionDefaultLeftRightPadding = ${sectionDefaultLeftRightPadding} ';
        //     content: ' SM leftRightPadding = ${props => props?.leftRightPadding?.sm || 'nup'} ';
        //     background: black;
        //     padding: .33rem;
        //     border: 1px solid salmon;
        // }
        
        /* allow left/right padding override for current breakpoint */
        padding-left: ${props => props?.leftRightPadding?.sm || sectionDefaultLeftRightPadding};
        padding-right: ${props => props?.leftRightPadding?.sm || sectionDefaultLeftRightPadding};

    `}

    /* min-width: 576px */
    ${media.md`
        width: 570px;
        max-width: 570px;
        width: ${sectionMaxWidthsInPx.md}px;
        max-width: ${sectionMaxWidthsInPx.md}px;
        // border: ${DEBUG ? 3 : 0}px solid purple; /* DEBUG */

        /* DEBUG: */
        // &:before {
        //     // content: ' SM sectionDefaultLeftRightPadding = ${sectionDefaultLeftRightPadding} ';
        //     content: ' MD leftRightPadding = ${props => props?.leftRightPadding?.md || sectionDefaultLeftRightPadding} ';
        //     background: black;
        //     padding: .33rem;
        //     border: 1px solid yellow;
        // }

        /* allow left/right padding override for current breakpoint */
        padding-left: ${props => props?.leftRightPadding?.md || sectionDefaultLeftRightPadding};
        padding-right: ${props => props?.leftRightPadding?.md || sectionDefaultLeftRightPadding};

    `}
    
    /* min-width: 768px */
    ${media.lg`
        width: 740px;
        max-width: 740px;
        width: ${sectionMaxWidthsInPx.lg}px;
        max-width: ${sectionMaxWidthsInPx.lg}px;
        // border: ${DEBUG ? 3 : 0}px solid yellow; /* DEBUG */

        // // padding-left: 4rem;     /* left/right padding to allow for elements to be pulled left with a -ve margin value, e.g. quote */
        // // padding-right: 4rem;    /* left/right padding to allow for elements to be pulled left with a -ve margin value, e.g. quote */
        // padding-left: ${props => props.$leftRightPadding?.lg};
        // padding-right: ${props => props.$leftRightPadding?.lg};

        /* allow left/right padding override for current breakpoint */
        padding-left: ${props => props?.leftRightPadding?.lg || sectionDefaultLeftRightPadding};
        padding-right: ${props => props?.leftRightPadding?.lg || sectionDefaultLeftRightPadding};

        
    `}
    ${media.xl`
        // width: 900px; /* absolute max width */
        // max-width: 900px;
        width: ${sectionMaxWidthsInPx.xl}px;
        max-width: ${sectionMaxWidthsInPx.xl}px;
        // border: ${DEBUG ? 3 : 0}px solid blue; /* DEBUG */

        /* allow left/right padding override for current breakpoint */
        padding-left: ${props => props?.leftRightPadding?.xl || sectionDefaultLeftRightPadding};
        padding-right: ${props => props?.leftRightPadding?.xl || sectionDefaultLeftRightPadding};

    `}
    // ${media.xl`
        // border: 0px solid lightgrey; /* DEBUG */
    // `}
}
`

/* 
@media only screen and (max-width: 376px) {
  .kCRhjg {
    width:361px;
    max-width:361px;
    border:9px solid white;
  }
}
@media only screen and (min-width: 376px) {
  .kCRhjg {
    width:361px;
    max-width:361px;
    border:1px solid pink;
  }
}
@media only screen and (min-width: 576px) {
  .kCRhjg {
    width:544px;
    max-width:544px;
    border:1px solid purple;
    padding:0 4rem 1rem 4rem;
  }
}
@media only screen and (min-width: 768px) {
  .kCRhjg {
    width:736px;
    max-width:736px;
    border:1px solid yellow;
  }
}
@media only screen and (min-width: 992px) {
  .kCRhjg {
    width:944px;
    max-width:944px;
    border:1px solid blue;
  }
}
 */
export const StyledIntroBgImgDiv = styled.div`
    position: absolute;
    top: 90px;
    right: 60px;
    bottom: 60px;
    left: 60px;
    // border: 3px solid crimson;
    background-image: url(${props => props?.bgimg ? props.bgimg : 'none'});
    background-repeat: no-repeat;
    background-size: cover;
`

export const StyledContactFormLabel = styled.label`
    color: ${white};
    font-size: 1.2rem;
    font-weight: 700;
`

/* shows breakpoint */
/* also an example of media query embedded inside :after pseudo element */
// export const StyledDebugDiv0 = styled.div` /* previous strategy of desktop first */
//     border: 1px solid white;
//     padding: 3px;
//     margin: 3px;
//     width: 120px;
//     text-align: center;
//     &:after {
//         ${/* media.xxl */`
//         content: 'XXL ${breakpoints.xxl}px';
//             background: purple;
//         `}
//         ${media.xl`
//         content: '.XL ${breakpoints.xl}px';
//             background: darkgrey;
//         `}
//         ${media.lg`
//         content: 'LG ${breakpoints.lg}px';
//             background: magenta;
//         `}
//         ${media.md`
//         content: 'MD ${breakpoints.md}px';
//             background: green;
//         `}
//         ${media.sm`
//         content: 'SM ${breakpoints.sm}px';
//             background: blue;
//         `}
//         ${media.xs`
//         content: 'XS ${breakpoints.xs}px';
//             background: black;
//         `}
//     }
// `
/* using mobile first strategy */
export const StyledDebugDiv = styled.div`
    border: 1px solid white;
    padding: 3px;
    margin: 3px;
    width: 120px;
    text-align: center;
    &:after {
        content: '+ XS ${breakpoints.xs}px';
        background: crimson;
        // ${media.xs`
        //     content: 'XS ${breakpoints.xs + 1}px';
        //     background: black;
        // `}
        ${media.sm`
            content: 'SM ${breakpoints.sm}px';
            background: blue;
        `}
        ${media.md`
            content: 'MD ${breakpoints.md}px';
            background: green;
        `}
        ${media.lg`
            content: 'LG ${breakpoints.lg}px';
            background: magenta;
        `}
        ${media.xl`
        content: '.XL ${breakpoints.xl}px';
            background: darkgrey;
        `}
    }
`
/* use ref to get and show dom element width */
export const StyledDebugWidthDiv = styled.div`

`

export const StyledInterimDiv = styled.div`
    background: ${laborRed};
    padding: 3rem 1rem;
    color: ${white};
    font-size: 1.2rem;
`

export const StyledEmailAt = styled.span`
&:after {
    content: '@';
}
`

export const H1C = styled.h1`
    text-align: center;
    text-wrap: balance;
`

export const ClickableSpan = styled.span`
    cursor: pointer;
`

export const StyledSectionH1 = styled(H1C)`
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    text-shadow: 2px 2px 6px black;
`

export const PC = styled.p`
    text-align: center;
`

export const Styled0000 = styled.div`
`
