/* eslint-disable no-unreachable */

/*  using Intersection Observer API
    cf  - https://www.freecodecamp.org/news/reveal-on-scroll-in-react-using-the-intersection-observer-api/
        - https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 */

import React, { useContext, useEffect, useRef, useState } from 'react'
import { animated, useSpring } from '@react-spring/web'
import {
    paymentBtnText,
    chbxDeclReadBtn,
    chbxEnrolled,
    chbxABN,
    chbxDeclRead,
    donorDeclHdr,
    donorDeclOkText,
    donorDeclarationTextMd,
    donateHdr,
    squareDonationBtnLink,
    textPreBtn,
    donateBankXferHdr,
    donateTextArrMd,
    modalDonorDeclMaxWidthInPx,
    // donateBankXferMdArr,
    donateBankXferMdActiveArr,
    donateBankXferMdInactiveArr,
    DEBUG,
    breakpoints,
    // laborRed,
    // white,
} from '../common/constants'
import { DonateForm } from '../forms/DonateForm'
import { H1C } from '../common/Builders'
// import styled from 'styled-components'
import { MainContext } from '../contexts/MainContext'

/* root defaults to browser viewport */
const intersectObsrvrOpts = {
    // rootMargin: '100px',
    // rootMargin: '-100px', /* -ve means it will fire when partially visible, e.g. -300px: when 300px is visible, will fire */
    // threshold: 1.0,
    threshold: .5, /* only run when this percentage is visible */
}

export const Donate = () => {

    /* 
    TODO: 
    - See https://squareup.com/help/au/en/article/6957-add-custom-tracking-code-to-your-website
    - add email donate@labor4narrabeen.com for enquiries
    */

    // const [ toggle, setToggle ] = useState(false)
    const [ isIntersecting, setIsIntersecting ] = useState(false)
    const [ debugIntersection, setDebugIntersection ] = useState({})
   
    const ref = useRef(null)

    const {
        width,
        debugMode,
    } = useContext(MainContext)

    const isSmallDevice = width < breakpoints.md

    const springProps = useSpring({
        opacity: isIntersecting ? 1 : 0,
        marginLeft: isIntersecting ? 0 : -500,
        // transform: isIntersecting ? 'translateX(0%)' : 'translateX(-100%)', /* doesn't work */
        // transform: isIntersecting ? 'translateX(0px)' : 'translateX(-600px)',
        // delay: 500,
        config: { duration: 1000, }
    })

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([ entry ]) => {
                // if (DEBUG) console.log(79, entry)
                setIsIntersecting(entry.isIntersecting)
                // setDebugIntersection({...entry}) /* DEBUG */
                setDebugIntersection(entry?.intersectionRatio) /* DEBUG */
            },
            intersectObsrvrOpts
        )
        if (DEBUG) console.log(`isIntersecting: ${isIntersecting}, [debugIntersection: ${debugIntersection}]`)
        observer.observe(ref.current)

        return () => observer.disconnect()
    }, [isIntersecting])

    return (
        <animated.div
            ref={ref}
            // style={{
            //     // ...springProps,
            // }}
            style={
                isSmallDevice ? {} : {...springProps}
            }
        >
            {/* <h1>{donateHdr}</h1> */}
            <H1C>{donateHdr}</H1C>

            {/* { DEBUG ? <pre>{JSON.stringify({debugIntersection, toggle}, null, 2)}</pre> : null } */}

            {/* <button onClick={() => setToggle(prev => !toggle)}>toggle {toggle?'on':'off'}</button>
            <animated.h2
                // style={springProps}
                style={{...springProps,}}
            >
                This is animated
            </animated.h2> */}

            <DonateForm
                { ...{
                    chbxABN,
                    chbxDeclRead,
                    chbxDeclReadBtn,
                    chbxEnrolled,
                    donateTextArrMd,
                    donorDeclarationTextMd,
                    donorDeclHdr,
                    donorDeclOkText,
                    modalDonorDeclMaxWidthInPx,
                    paymentBtnText,
                    squareDonationBtnLink,
                    textPreBtn,
                    // donateBankXferMdArr,
                    donateBankXferMdActiveArr,
                    donateBankXferMdInactiveArr,
                    donateBankXferHdr,
                    isSmallDevice,
                    debugMode,
                } }
            />

        </animated.div>
    )
}
