// components/DonateModal.js

import React, { useContext } from 'react'
import { Modal } from 'antd'
import { MainContext } from '../contexts/MainContext'
import { H1C } from '../common/Builders'
import DonationDeclaration from './DonationDeclaration'

const DonateModal = props => {

    const {
        donorDeclOkText,
        donorDeclHdr,
        handleOk,
        handleCancel,
        isDeclOpen,
        modalDonorDeclMaxWidthInPx,
    } = props

    const { width } = useContext(MainContext)

    return (
        <Modal
            width={Math.min(modalDonorDeclMaxWidthInPx, Math.round(width * .9))}
            open={isDeclOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={donorDeclOkText}
        >
            <H1C>{donorDeclHdr}</H1C>
            <DonationDeclaration { ...{ donorDeclOkText }} />
        </Modal>
    )
}

export default DonateModal
