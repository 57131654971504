// Burger.js

/*
Based on https://css-tricks.com/hamburger-menu-with-a-side-of-react-hooks-and-styled-components/
*/

import React from 'react'
import styled from 'styled-components'
import { 
    DEBUG,
    laborBlue,
    laborRed,
    navbarHeightInPx,
    white,
    zIndex,
} from '../common/constants'

export const Burger = ({ open, setOpen }) => {

    return (
        <StyledBurger
            className={DEBUG ? 'StyledBurger' : ''}
            open={open}
            onClick={() => setOpen(!open)}
        >
            <div />
            <div />
            <div />
        </StyledBurger>
    )
}

export const BurgerMenu = ({ open, children, setOpen }) => {

    // open = true
    return (
        <StyledMenu
            open={open}
            onClick={() => setOpen(!open)}
        >
            {children}
        </StyledMenu>
    )

    // return null
}

/* cf https://css-tricks.com/hamburger-menu-with-a-side-of-react-hooks-and-styled-components/ */
const StyledMenu = styled.nav`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // background: ivory;
    background: ${laborRed};
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-110%)'};
    // top: 4rem;
    top: ${navbarHeightInPx}px;
    // height: calc(100vh - 4rem);
    height: calc(100vh - ${navbarHeightInPx}px);
    text-align: left;
    position: absolute;
    // right: 0.7rem;
    // left: -0.7rem;
    right: 0;
    left: 0;
    transition: transform 0.3s ease-in-out;
    // padding: 1rem 2rem;
    padding: 2rem;

    li {
        padding-right: 0;
        padding-left: 3px;
        text-align: left;
    }
/*
    a {
        font-size: 2rem;
        padding: 1rem .2rem;
        font-weight: bold;
        color: #282c34;
        text-decoration: none;
        transition: color 0.3s linear;

        @media (max-width: 576px) {
            font-size: 1.5rem;
            text-align: center;
        }
    
        &:hover {
            color: #343078;
            text-decoration: underline;
        }
    }
 */

    /* attempted overflow fix 20240609 */
    @media (max-width: 576px) {
        width: 100%;
    }

`

const StyledBurger = styled.button`
    position: absolute;
    // top: 9px;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    // width: 2.4rem;
    width: 4rem;
    // height: 3rem;
    height: ${navbarHeightInPx}px;
    border: none;
    // border: 1rem solid ${laborRed};
    cursor: pointer;
    padding: 6px 3px;
    // z-index: 10;
    z-index: ${zIndex.BurgerMenu || 999};

    // justify-content: space-between;
    justify-content: space-evenly;
    align-items: center;
    background: ${white};

    &:focus {
        outline: none;
    }

    div { // div inside the burger
        width: 2.05rem;
        height: 0.375rem;
        background: ${laborBlue};
        // background: ${({ open }) => open ? laborRed : laborBlue};
        border-radius: 0;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 3px;
        color: ${white};

        &:first-child {
            // transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
            // transform: ${({ open }) => open ? 'rotate(40deg) translateX(1px)' : 'rotate(0)'};
            transform: ${({ open }) => open ? 'rotate(45deg) translateX(4px)' : 'rotate(0)'};
            width: ${({ open }) => open ? '2.25rem' : '2.05rem'};
        }
    
        &:nth-child(2) {
            opacity: ${({ open }) => open ? '0' : '1'};
            transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
        }
    
        &:nth-child(3) {
            // transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
            // transform: ${({ open }) => open ? 'rotate(-40deg) translateX(1px)' : 'rotate(0)'};
            transform: ${({ open }) => open ? 'rotate(-45deg) translateX(4px)' : 'rotate(0)'};
            width: ${({ open }) => open ? '2.25rem' : '2.05rem'};
        }
    }
`
