/* eslint-disable no-unreachable */
// QuoteLeft.js

/* replaces deprecated Quote.js */

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
    // DEBUG,
    // laborBlue,
    whiteish,
} from '../common/constants'
import {
    Col,
    // Image,
    Row,
} from 'antd'
// import { media } from '../common/Builders'

// let thisDEBUG = false
// thisDEBUG = true
// thisDEBUG = !!(DEBUG && thisDEBUG)

// const QuoteAcross = props => {

//     const {
//         size = 'medium',  /* enum: short, medium, long */
//         text = '',
//     } = props

//     const [ quoteSize, setQuoteSize ] = useState(size)

//     useEffect(() => {
//         let _size = [ 'short', 'medium', 'long' ].includes(size)
//             ? size
//             : 'medium' /* default is 'medium' */
//         setQuoteSize(_size)
//     }, [size])

//     return (
//         <>
//             <StyledQuoteRow
//                 justify="space-around"
//                 align="middle"
//             >
//                 <StyledQuote { ...{ size: quoteSize }}>
//                     {text}
//                 </StyledQuote>
//             </StyledQuoteRow>
//         </>
//     )
// }

const QuoteLeft = props => {

    const {
        size = 'medium',  /* enum: short, medium, long */
        text,
        imgsrc,
        // imgalt,
        // bgcol = 'transparent',
        negLeftMargin = 0,
    } = props

    const [ quoteSize, setQuoteSize ] = useState('medium')

    useEffect(() => {
        let _size = [ 'short', 'medium', 'long' ].includes(size)
            ? size
            : 'medium' /* default is 'medium' */
        setQuoteSize(_size)
    }, [size])

    const spanCol1      = 12
    const offsetCol1    = 0
    const spanCol2      = 12
    const offsetCol2    = 0

    return (
        <>
            <StyledQuoteRow
                justify="space-around"
                align="middle"
                negleftmargin={negLeftMargin} /* NOTE: also applied to right margin */
            >
                <ColLeft
                    span={spanCol1}
                    offset={offsetCol1}
                    key='col-1'
                >
                    {/* <StyledQuote {...{size: quoteSize, bgcol}}> */}
                    <StyledQuote { ...{ size: quoteSize }}>
                        {/* <StyledPre>{JSON.stringify({ spanCol1, offsetCol1, spanCol2, offsetCol2, })}</StyledPre> */}
                        {text}
                        {/* <pre>{JSON.stringify(imgalt, null, 2)}</pre> */}
                    </StyledQuote>
                </ColLeft>
                <ColWithImage
                    key='col-2'
                    span={spanCol2}
                    offset={offsetCol2}
                    // style={{ ...(thisDEBUG && { border: '1px solid green', })}}
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/${imgsrc}`}
                        height={280}
                        alt=""
                    />
                </ColWithImage>
            </StyledQuoteRow>
        </>
    )
}

export const StyledQuoteRow = styled(Row).attrs(({ /* contDivWidthsInPx, */ /* negLeftMarginsInPx, */ negleftmargin = 0, }) => ({
    // negLeftMargin: negLeftMargin || 0,
    negleftmargin: negleftmargin || 0,
}))`
    // border: 1px solid pink; /* debug */
    margin-left: -${props => props.negleftmargin}px;
    margin-right: -${props => props.negleftmargin}px;
`

const ColLeft = styled(Col).attrs(({ imgleft }) => ({
    
}))`
    // border: 1px solid yellow;
    // border: 3px solid ${whiteish}; 
    z-index: 90;
`

const ColWithImage = styled(Col).attrs(({ imgleft }) => ({
}))`
    border: 0px solid green;
    text-align: ${props => props?.imgleft ? 'left' : 'right'};
    margin-right: -3rem;
    margin-right: ${props => props?.imgleft ? '-3rem' : 0};
    margin-left: ${props => props?.imgleft ? 0 : '-3rem'};
`
// const ColWithImageLeft = styled(Col)`
//     border: 0px solid green;
//     text-align: right;
//     margin-left: -3rem;
// `

export const StyledQuote = styled.div.attrs(({ size }) => ({
    size: size === 'long' ? 1.2 : size === 'short' ? 2 : 1.33,
}))`
    margin: 1rem -3rem;
    padding: 1rem 0 1rem 2.5rem;
    font-weight: normal;
    font-size: ${props => props.size}rem;
    // border: 1px solid crimson;
    position: relative;
    // background: ${props => props?.bgcol || 'transparent'}; /* replace with prop value */
    line-height: 2.5rem;

    /* big opening quote */
    &:before {
        content: '\\0201c';
        font-size: ${props => props.size * 2}rem;
        // line-height: 2rem;
        margin-left: -1.5rem;
        position: absolute;
    }
    /* big closing quote */
    &:after {
        content: '\\0201d';
        font-size: ${props => props.size * 2}rem;
        // line-height: ${props => props.size}rem;
        margin-left: .5rem;
        position: absolute;
    }

`

// const StyledQuote2 = styled.div.attrs(({ size }) => ({
//     size: size === 'long' ? 1.2 : size === 'short' ? 2 : 1.33,
// }))`
//     font-size: ${props => props?.size ? props.size : 1.33}rem;
//     // border: 3px solid crimson;
//     &:before {
//         content: "\\0201c";
//     }
// `

// const StyledPre = styled.pre`
//     font-size: .9rem;
//     color: lightgrey;
// `

export default QuoteLeft
