// EventFramed.js

import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
    CalendarTwoTone,
    // MailOutlined,
    MailTwoTone,
    // MobileOutlined,
    // RightCircleOutlined,
} from '@ant-design/icons'
import {
    Button,
    // Col,
    Flex,
    List,
    // Row,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {   
    // faCoffee,
    faLocationDot,
} from '@fortawesome/free-solid-svg-icons'
import {
    DEBUG,
    eventHdrs,
    eventLabels,
    timesGridColMaxWidthInPx,
    // white,
    whiteish,
} from '../common/constants'
import { detect_iOS, detect_macOS } from '../common/functions'

// const dateTimeLocale = 'fr-FR'
const dateTimeLocale = 'en-AU'
const dateTimeConfig = {
    dateStyle: 'full',
    // timeStyle: 'long',
    timeStyle: 'short',
    timeZone: 'Australia/Sydney',
}
const justify = 'flex-start'
const alignItems = 'flex-start'
const gap = 'middle'

const mapUrlPre = 'http://maps.apple.com/?daddr='

// const Bullet = () => <RightCircleOutlined />
const Bullet = () => <>&mdash;</>

const EventFramed = props => {

    const {
        data,
        isNarrow,
        past = false,
    } = props
    const {
        date,
        leader = '',
        subtitle =  '',
        details = [],
        venue = {},
        rsvp = {},
    } = data
    // const {
    //     name,
    //     address,
    //     details,
    //     google_maps,
    // } = venue

    const dateFormatted = new Intl.DateTimeFormat(dateTimeLocale, dateTimeConfig).format(new Date(date))

    const detailsMd = details && Array.isArray(details) && details.length
        ? details.join('\n\n')
        : null

    return (
        <>
            <pre>{dateFormatted}</pre>
            <h1>{leader}</h1>
            <h3>{subtitle}</h3>

            <ReactMarkdown>{detailsMd}</ReactMarkdown>

            <TimeDetails
                { ...{ dateFormatted, venue, rsvp, isNarrow, past, } }
            />
        </>
    )
}

const TimeDetails = ({ dateFormatted, venue, isApple, rsvp, isNarrow, past }) => {

    const { text: rsvpText, links: rsvpLinks } = rsvp || {}

    return (
        <Flex>
            <StyledTimeDetails className='StyledTimeDetails'>
                <StyledTitle
                    className='StyledTitle'
                    gap={gap}
                    justify={justify}
                    align={alignItems}
                >
                    <div><CalendarTwoTone /></div>
                    <div>{eventHdrs.when}</div>
                </StyledTitle>
                <StyledDetail className='StyledDetail'>
                    <div>{dateFormatted}</div>
                </StyledDetail>

                {
                    venue?.name || venue?.address || venue?.details 
                        ? (
                            <>
                                <StyledTitle
                                    className='StyledTitle'
                                    gap={gap}
                                    justify={justify}
                                    align={alignItems}
                                >
                                    {/* <div><FontAwesomeIcon icon={faLocationIcon} /></div> */}
                                    <div><FontAwesomeIcon icon={faLocationDot} /></div>
                                    <div>{eventHdrs.where}</div>
                                </StyledTitle>
                                <StyledDetail className='StyledDetail'>
                                    {venue?.name ? <div>{venue?.name}</div> : null}
                                    {venue?.address ? <div>{venue?.address}</div> : null}
                                    {venue?.details ? <div>{venue?.details}</div> : null}
                                    {
                                        !past
                                            ? (
                                                <MapLinks
                                                    { ...{ venue } }
                                                    isNarrow={isNarrow}
                                                />
                                            )
                                            : null
                                    }
                                </StyledDetail>
                            </>
                        )
                        : null
                }
                {/* <div><FontAwesomeIcon icon={faCoffee} /></div> */}
            </StyledTimeDetails>
            {
                rsvpText && Array.isArray(rsvpLinks) && rsvpLinks.length && !past
                ? (
                    <StyledTimeDetails className='StyledTimeDetails rsvp-col' leftmargin='2rem'>
                        <StyledTitle
                            className='StyledTitle'
                            gap={gap}
                            justify={justify}
                            align={alignItems}
                        >
                            <div><MailTwoTone /></div>
                            <div>{eventHdrs.rsvp}</div>
                        </StyledTitle>
                        <div>
                            <List
                                //header={`${rsvpText}: ` || ''}
                                // grid={{ gutter: 64, column: 1, }}
                            >
                                <span>{`${rsvpText}: ` || ''}</span>
                            {
                                rsvpLinks.map((e, i) => (
                                    <List.Item
                                        key={`rsvpLink${i+1}`}
                                    >
                                        {
                                            e?.link
                                            ? (
                                                // <a href={e.link} target="_blank">
                                                <Link to={e.link} target="_blank">
                                                    <Bullet /> {e?.text ? e.text : e.link}
                                                </Link>
                                            )
                                            : <span><Bullet /> {e?.text}</span>
                                        }
                                    </List.Item>
                                ))
                            }
                            </List>
                        </div>
                    </StyledTimeDetails>
                )
                : null
            }
        </Flex>
    )
}

// const StyledDetail = styled.div`
const StyledDetail = styled(Flex).attrs(({ gap }) => ({
    vertical: true,
    gap: 'middle',
}))`
    padding-bottom: 1.25rem;
    border: 0px solid red;
`

const StyledTimeDetails = styled(Flex).attrs(({ direction, gap, leftmargin }) => ({
    vertical: true,
    gap: gap ? gap : 0,
    leftmargin: leftmargin || 0,
    // maxWidth: leftmargin 
    //     ? `calc(${timesGridColMaxWidthInPx}px + ${leftmargin})`
    //     : `${timesGridColMaxWidthInPx || 370}px`,
}))`
    // border-bottom: 1px solid ${whiteish};

    margin: .5rem 0 3rem ${props => props.leftmargin};

    // padding: .5rem 0;
    padding: 0;
    // padding: .5rem 0 3rem ${props => props.leftmargin};

    // max-width: 320px;
    max-width: ${timesGridColMaxWidthInPx || 370}px;
    // max-width: ${props => props.maxWidth};
    width: ${timesGridColMaxWidthInPx || 370}px;
`

const StyledMapLinks = styled(Flex).attrs(({ direction, gap }) => ({
     /* will only display vertically if explicitly specified */
    vertical: direction === 'vertical' ? true : false,
    gap: gap ? gap : 'middle',
}))`
    padding: .67rem 0;
`

const StyledTitle = styled(Flex)`
    width: 100%;
    border-bottom: 1px solid ${whiteish};
    margin: .5rem 0 1rem 0;
    padding: 0.25rem;
`

const MapLinks = props => {

    const {
        venue,
        isNarrow,
    } = props

    const [ isApple, setIsApple ] = useState(false)
    const [ macMapsAppUrl, setMacMapsAppUrl ] = useState()

    useEffect(() => {
        // setIsApple( detect_iOS() || detect_macOS() ? true : false )
        setIsApple( detect_iOS() || detect_macOS() ? true : DEBUG?.forceMac || false )
    }, [])

    useEffect(() => {
        const _macMapsAppUrl = venue?.address && isApple
            ? `${mapUrlPre}${encodeURIComponent(venue?.address).replace(/%20/g, '+')}`
            : null
        setMacMapsAppUrl(_macMapsAppUrl)
    }, [isApple, venue?.address])

    return (
        <StyledMapLinks
            // direction={'vertical'}
            // direction={''} /* will only display vertically if explicitly specified */
            direction={isNarrow ? 'vertical' : ''} /* will only display vertically if explicitly specified */
        >
            <div>
            {
                macMapsAppUrl
                ? (
                    <Button
                        onClick={() => window.open(macMapsAppUrl)}
                    >{eventLabels?.appleMapDirections}</Button>
                )
                : null
            }
            </div>
            <div>
            {
                venue?.google_maps
                ? (
                    <Button
                        onClick={() => window.open(venue?.google_maps)}
                    >{eventLabels?.googleMapDirections}</Button>
                )
                : null
            }
            </div>
            {/* navigator.platform={navigator.platform}<br/> */}
            {/* navigator.userAgent={navigator.userAgent} */}
        </StyledMapLinks>
    )
}

export default EventFramed
