/* For browser fingerprinting cf
    - https://fingerprint.com/blog/canvas-fingerprinting/
    - https://browserleaks.com/canvas#how-does-it-work
 */

import React, { useContext, useEffect, useRef } from 'react'
import { scroller } from 'react-scroll'
import { FloatButton, Switch } from 'antd'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { MainContext } from '../contexts/MainContext'
import { Navbar } from './Navbar'
// import Intro from '../screens/Intro'
import { Home } from '../screens/Home'
import { About } from '../screens/About'
import { Issues } from '../screens/Issues'
import { GetInTouch } from '../screens/GetInTouch'
import { Donate } from '../screens/Donate'
import { Ward } from '../screens/Ward'
import { Interim } from './Interim'
import { Events } from '../screens/Events'
import { Section } from './Section'
import {
    DEBUG,
    // bgImgs,
    laborRed,
    struct,
    scrollDuration,
    acknowledgementCountry,
    white,
    sectionMaxWidthsInPx,
    candidateName,
    laborBlueExtremelyLight,
    websiteByArrMd,
    laborBlue,
    bgLaborRedOldfashioned,
    breakpoints,
    // breakpoints,
} from '../common/constants'
import {
    candidateAddressAsString,
    // shuffle,
} from '../common/functions'
import { media } from '../common/Builders'
import { Socials } from './Socials'
import { HowToVote } from './HowToVote'

const components = {
    // Intro,
    Home,
    About,
    GetInTouch,
    Donate,
    Interim,
    Events,
    Issues,
    Ward,
}

/*  HOC to wrap component without mutating it 
    - allows variable to be used for component name
*/
/*  cf
    - https://stackoverflow.com/a/45582312/23517902
    - https://www.robinwieruch.de/react-higher-order-components/
    - https://blog.logrocket.com/understanding-react-higher-order-components/
*/
// const wrapper = Component => props => <Component {...props} componentname={`${Component}`} />/*  componentname={'Component'} */
const wrapper = Component => props => <Component {...props}/>/*  componentname={'Component'} */

export const RootCont = props => {

    // console.log(54, struct)

    const {
        jumpto,
        modalDonation = false,
    } = props

    const {
        width,
        browserFingerprint,
        // bgImgPool,
        // setBgImgPool,
        debugMode, setDebugMode,
    } = useContext(MainContext)

    const ref = useRef()

    useEffect(() => {
        if (ref?.current && jumpto) {
            const idx = struct.findIndex(e => e?.key && jumpto === e.key)
            const found = struct.find(e => e?.key && jumpto === e.key)
            if (DEBUG) console.log(71, {jumpto, idx, found})
            /* TODO: set active section to trigger scrollTo */
            scroller.scrollTo(`section${idx+1}`, { smooth: true, duration: scrollDuration, })
            // window.scroller = scroller /* expose scroller function to debug console */
        }
    }, [jumpto])

    const componentProps = {
        ...{ modalDonation },
    }

    /* restrict smaller devices */
    // if (width && width < breakpoints?.lg) {
    //     return (
    //         <>
    //             <H1C>Still under development</H1C>
    //             <Row>
    //                 <Col span={12} offset={6}>
    //                 Currently not yet available for devices with width &lt; {breakpoints?.lg}px
    //                 <pre>Version: {process.env.REACT_APP_VERSION}</pre>
    //                 <pre>
    //                     App:
    //                 </pre>
    //                     <h5>{process.env.REACT_APP_NAME}</h5>
    //                 </Col>
    //             </Row>
    //         </>
    //     )
    // }

    return (
        <>
            <Navbar />
            {
                struct
                    .filter(({disabled}) => disabled === true ? false : true)
                    .map((e, i) => (
                    <React.Fragment key={`section-${i+1}`}>
                    {
                        e?.type && e.type === 'section'
                            ? (
                            <Section
                                // key={`section-${i+1}`}
                                name={`section${i+1}`} /* needed for navbar menu link */
                                section_key={`${e?.key}`}
                                bgcol={e?.bgcol || laborRed}
                                // bgimg={bgImgPath(e)} /* select a random image to use as bg */
                                // w={JSON.stringify(e?.sectionContDivWidthInPx)}
                                // width_struct={e?.sectionContDivWidthInPx}
                                leftRightPadding={e?.leftRightPadding}
                                overrideWidth={e?.width}
                                firstPage={e?.order === 1 ? 1 : 0}
                            >
                            {
                                /* modalDonation bool (and any other var) is passed to each sectional component
                                   in a props object, with each prop only needing to be picked up by one */
                                /* prop 'componentname' used to pass name of component, used to get values out of struct */
                                components[e?.component]
                                    ? wrapper(components[e?.component])({ ...componentProps, componentname: e?.component})
                                    : <></>
                            }
                            </Section>)
                            : e?.type && e.type === 'interim' && e?.component
                                ? (<div key={`section-${i+1}`}>
                                    {/* <p>some interim stuff</p> */}
                                    <p>&nbsp; </p>{/* placeholder */}
                                    {/* <Interim /> */}
                                    {/* <pre>{e.component}</pre> */}
                                    {wrapper(components[e.component])()} {/* UP TO HERE!!!!  */}
                                </div>)
                                : null
                    }
                    </React.Fragment>
                ))
            }

            <StyledFooter ref={ref}>{/* NOTE: ref is here is used in useEffect */}
                <StyledAuthorisation>
                    Authorised by {candidateName}:
                    {' '}
                    {candidateAddressAsString()}
                </StyledAuthorisation>
                <StyledAcknowledgement>
                    <StyledAcknowledgementHdr>
                        {acknowledgementCountry.hdr}
                    </StyledAcknowledgementHdr>
                    <StyledAcknowledgementText>
                        {acknowledgementCountry.text}
                    </StyledAcknowledgementText>
                </StyledAcknowledgement>
                <StyledWebsiteByCont>
                    <ReactMarkdown
                        components={{
                            p(props) {
                                return (
                                    <StyledWebsiteBy>{props?.children}</StyledWebsiteBy>
                                )
                            }
                        }}
                    >
                        {websiteByArrMd.join('\n')}
                    </ReactMarkdown>
                </StyledWebsiteByCont>
            </StyledFooter>

            {
                DEBUG && debugMode
                ? (
                    <>
                        <div>browser fingerprint: {browserFingerprint}</div>
                        <div>user agent: {navigator.userAgent}</div>
                    </>
                )
                : null
            }

            <Socials />

            {
                width < breakpoints.md
                    // ? null
                    ? (                        
                        <div className='HowToVote'>
                            <HowToVote />
                        </div>
                    )
                    : (                        
                        <div className='HowToVote'>
                            <HowToVote />
                        </div>
                    )
            }

            <FloatButton.BackTop />

            {/* DEBUG TOGGLE only display in DEVELOPMENT */}
            {
                DEBUG
                    ? (
                        <DebugSwitch>
                            <Switch
                                className='DebugSwitch'
                                checked={!!debugMode}
                                onChange={() => setDebugMode(prev => !prev)}
                            />
                        </DebugSwitch>
                    )
                    : null
            }
        </>
    )
}

const DebugSwitch = styled.div`
    position: fixed;
    top: 60px;
    left: 60px;
    z-index: 200;
`

const StyledAuthorisation = styled.div`
    margin-bottom: 2rem;
`
const StyledAcknowledgement = styled.div`
    font-weight: 400;

    ${media.sm`
        width: ${sectionMaxWidthsInPx.sm}px;
        max-width: ${sectionMaxWidthsInPx.sm}px;
        // border: 4px solid pink; /* DEBUG */
    `}
    ${media.md`
        width: ${sectionMaxWidthsInPx.md}px;
        max-width: ${sectionMaxWidthsInPx.md}px;
        // border: 3px solid purple; /* DEBUG */
    `}
    ${media.lg`
        width: ${sectionMaxWidthsInPx.lg}px;
        max-width: ${sectionMaxWidthsInPx.lg}px;
        // border: 2px solid yellow; /* DEBUG */
        padding-left: 4rem;     /* left/right padding to allow for elements to be pulled left with a -ve margin value, e.g. quote */
        padding-right: 4rem;    /* left/right padding to allow for elements to be pulled left with a -ve margin value, e.g. quote */
    `}
    ${media.xl`
        width: ${sectionMaxWidthsInPx.xl}px;
        max-width: ${sectionMaxWidthsInPx.xl}px;
        // border: 1px solid blue; /* DEBUG */
    `}
`
const StyledAcknowledgementHdr = styled.div`
    font-weight: 500;
    margin: 1rem 0;
    font-size: 1.5rem;
`
const StyledAcknowledgementText = styled.div`
    font-weight: 400;
    line-height: 1.33;
`

export const StyledFooter = styled.footer`
    background: ${laborRed};
    padding: 3rem 1rem;
    color: ${white};
    font-size: 1.2rem;
    font-weight: 700;
`

const StyledWebsiteByCont = styled.div`
    color: ${laborBlueExtremelyLight};

    background: ${bgLaborRedOldfashioned};

    font-family: "Rubik", sans-serif;
    // font-size: 1.2rem;
    font-size: 1rem;
    font-weight: 400;
    width: 300px;
    margin: auto;
    margin-top: 1rem;
    padding: 1rem 3rem;

    &:hover {
        // font-weight: 600;
        color: pink;
    }

    ${media.md`
        width: 500px;
        border: 0px solid ${laborBlueExtremelyLight};
    `}
`

const StyledWebsiteBy = styled.div`
    text-align: center;
    text-wrap: balance;
    // text-decoration: underline;
    // color: pink;
    padding: .25rem 0;
`
