import React, { useContext, useState } from 'react'
import {
    Button,
    Col,
    Flex,
    Form,
    Input,
    Row,
    Space,
} from 'antd'
import styled from 'styled-components'
import {
    useNavigate,
} from 'react-router-dom'
import { StyledContactFormLabel } from '../common/Builders'
import Recaptcha from '../components/Recaptcha'
import { MainContext } from '../contexts/MainContext'
import {
    breakpoints,
    // contactText,
    DEBUG,
    laborRed,
    // struct,
    white,
} from '../common/constants'
// const { Option } = Select

const layout = {
    labelCol: {
        // span: 8,
        span: 6 ,
    },
    wrapperCol: {
        // span: 16,
        span: 18,
    },
    layout: 'horizontal',
    // labelWrap: false,
}
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
}

const btnPreSend = 'Send your message'
const btnInTransmission = 'Message being sent ... '
// const btnPostSend = 'Message sent'

const formStruct = [
    { order: 5,  id: 'firstname', label: 'First name', required: true, },
    { order: 10, id: 'lastname', label: 'Last name', /* required: true, */ },
    { order: 15, id: 'suburb', label: 'Suburb', required: true, },
    { order: 20, id: 'postcode', label: 'Post code', /* required: true, */ },
    { order: 25, id: 'email', label: 'Email', required: true, type: 'email', },
    { order: 30, id: 'mobile', label: 'Mobile number', /* required: true, */ },
    { order: 35, id: 'comments', label: 'Your message', required: true, multiline: true, rows: 4, },
]

// const sendMailUrl = 'https://labor4narrabeen.com/7174787/240813.php'
const sendMailUrl = 'https://mail.labor4narrabeen.com/7174787/240813.php'
const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':'*',
}

export const ContactForm = props => {

    const { msgSent, setMsgSent, msg, setMsg } = props

    const [ recaptchaLoaded,    setRecaptchaLoaded ]    = useState(false)
    const [ recaptchaPassed,    setRecaptchaPassed ]    = useState(false)
    const [ resetForm,          setResetForm ]          = useState(false)
    const [ inTransmission,     setInTransmission ]     = useState(false)

    const {
        width,
        debugMode,
    } = useContext(MainContext)

    const isSmallDevice = width < breakpoints.md

    /* if small device, change layout definition */
    if (isSmallDevice) {
        layout.layout = 'vertical'
        layout.wrapperCol.span = 24
        layout.labelCol = 24
        // layout.labelWrap = false
        // tailLayout.wrapperCol.offset = 4
        // tailLayout.wrapperCol.span = 20
        // tailLayout.wrapperCol.offset = 2
        // tailLayout.wrapperCol.span = 22
        tailLayout.wrapperCol.offset = 0
        tailLayout.wrapperCol.span = 24
    }

    const navigate = useNavigate()
    // const path = struct.find(({key}) => key === 'contact')?.path

    // useEffect(() => {
    //     navigate(path)
    // }, [resetForm])

    const [ form ] = Form.useForm()

    // const submitForm = e => {
    //     if (DEBUG) console.log(70, {e})
    // }

    const onFinish = async values => {
        // console.log(81, values)
        try {
            setInTransmission(true)
            let res = await fetch(sendMailUrl, {
                mode: 'cors',
                headers,
                method: 'POST',
                body: JSON.stringify(values),
            })
            const resJson = await res.json()
            if (DEBUG) console.log(90, { status: res.status, resJson })
            if (res.status === 200) {
                setInTransmission(false)
                setMsgSent(true)
                setMsg({...values})
            }
            if (res.status !== 200) {
                // alert(res.status)
            }
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setInTransmission(false)
        }
    }

    const onFinishFailed = errorInfo => {
        console.log(78, {errorInfo})
    }

    const handleResetForm = e => {
        e.preventDefault()
        setResetForm(true)
    }

    const [ justify, alignItems, ] = [ 'center', 'center' ]

    if (resetForm) {
        return navigate(0)
        // return <Navigate to={path} replace={true} />
    }

    if (msgSent && Object.keys(msg).length && formStruct && Array.isArray(formStruct)) return (
        <Flex
            // style={boxStyle}
            justify={justify}
            align={alignItems}
            vertical
        >

            <StyledMessageSent className={DEBUG ? 'StyledMessageSent' : ''}>
                {/* <pre>{JSON.stringify({ item }, null, 2)}</pre> */}
                {/* <pre>{JSON.stringify({ msg }, null, 2)}</pre> */}
                {/* <pre>{JSON.stringify({ layout }, null, 2)}</pre> */}
                {/* <pre>{JSON.stringify({ formStruct }, null, 2)}</pre> */}
                {/* <Row>
                    <Col {...(layout.labelCol)}>label <pre>{JSON.stringify({...(layout.labelCol)}, null, 2)}</pre></Col>
                    <Col {...(layout.wrapperCol)}>text <pre>{JSON.stringify({...(layout.wrapperCol)}, null, 2)}</pre></Col>
                </Row> */}
                {
                    formStruct
                        .sort((a, b) => a.order > b.order ? 1 : -1)
                        .map(({id,label,required,multiline,rows,type}, i) => (
                            <StyledMsgRow
                                key={`msgitem-${i+1}`}
                            >
                                <StyledColMsgLabel {...(layout.labelCol)}>{label}:</StyledColMsgLabel>
                                <StyledColMsgText {...(layout.wrapperCol)}>{msg?.[id] || '-'}</StyledColMsgText>
                            </StyledMsgRow>
                        ))
                }
            </StyledMessageSent>
            <div>&nbsp;</div>
            <Button
                // onClick={e => navigate(path)}
                onClick={handleResetForm}
            >RESET FORM</Button>
        </Flex>
    )

    return (
        <Flex
            // style={boxStyle}
            justify={justify}
            align={alignItems}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                style={{
                    flex: 1,
                    maxWidth: 700,
                    color: 'white',
                    // border: '1px solid white',
                }}
                // labelWrap
            >
                {
                    DEBUG && debugMode
                        ? <pre>{JSON.stringify({layout, isSmallDevice, tailLayout}, null, 2)}</pre>
                        : null
                }
                {
                    formStruct
                    ? formStruct.map(({id,label,required,multiline,rows,type}, i) => (
                        <Form.Item
                            key={`formitem-${i+1}`}
                            name={id}
                            label={<StyledContactFormLabel>{label}</StyledContactFormLabel>}
                            rules={[{ required: required, }, ]}
                            type={type}
                            maxLength={256}
                        >
                            {multiline ? <Input.TextArea rows={rows} maxLength={256*4}/> : <Input />} 
                        </Form.Item>
                    ))
                    : null
                }
                <Form.Item layout={{}} {...tailLayout}>
                    {/* <p>reCaptcha is {recaptchaLoaded ? '' : 'NOT' } loaded</p> */}
                    <Recaptcha
                        { ...{ setRecaptchaPassed, setRecaptchaLoaded } }
                    />
                    {/* <p>recaptchaPassed: {recaptchaPassed ? 'YES' : 'NO' }</p> */}
                </Form.Item>
                {
                    recaptchaLoaded
                        ? (
                            <Form.Item {...tailLayout}>
                                <Space>
                                    {
                                        msgSent
                                            ? (
                                                <>
                                                    <div>Message sent.</div>
                                                    <pre>{JSON.stringify({ msg }, null, 2)}</pre>
                                                </>
                                            )
                                            : (
                                                <Button
                                                    // type={recaptchaPassed ? 'primary' : 'default'}
                                                    type={inTransmission ? 'danger' : recaptchaPassed ? 'primary' : 'default'}
                                                    htmlType="submit"
                                                    disabled={!recaptchaPassed}
                                                    ghost={!recaptchaPassed}
                                                    // style={{ color: recaptchaPassed ? '' : 'white' }}
                                                    style={{ 
                                                        color: inTransmission
                                                            ? laborRed
                                                            : recaptchaPassed
                                                                ? ''
                                                                : 'white',
                                                    }}
                                                    /* TODO: */
                                                    // onClick={() => alert('This contact form is not yet connected. (Still under development!)')}
                                                    // onClick={submitForm}
                                                    onFinish={onFinish}
                                                    onFinishFailed={onFinishFailed}
                                                >
                                                    {/* { recaptchaPassed ? 'Send your message' : ' ... waiting for verification ' } */}
                                                    {
                                                        inTransmission ?
                                                            btnInTransmission
                                                            : recaptchaPassed
                                                                ? btnPreSend : ' ... ' }
                                                </Button>
                                            )
                                    }
                                </Space>
                            </Form.Item>
                        )
                        : null
                }
            </Form>
        </Flex>
    )
}

const StyledMessageSent = styled.div`
    flex: 1; /* to ensure takes up full width */
    font-size: 1.1rem;
    color: ${white};
    // border: 1px solid crimson;
`
const StyledMsgRow = styled(Row)`
    margin-bottom: 1rem;
`
const StyledColMsgLabel = styled(Col)`
    font-size: 1.1rem;
    color: ${white};
    // border: 1px solid ${white};
    margin: .33rem 0 0 0;
    padding: 0 .5rem 0 0;
`
const StyledColMsgText = styled(Col)`
    font-family: monospace !important;
    font-size: 1.2rem;
    color: ${white};
    // border: 1px solid ${white};
    margin: .33rem 0;
`
