// components/ModalDonationThanks.js

import React, { useContext, useState } from 'react'
import { Modal } from 'antd'
import { MainContext } from '../contexts/MainContext'
import { H1C } from '../common/Builders'
import { modalDonationThnxMaxWidthInPx } from '../common/constants'

const ModalDonationThanks = props => {

    const {
        isOpen,
    } = props

    const { width } = useContext(MainContext)

    const [ modalOpen, setModalOpen ] = useState(!!isOpen)

    // const success = () => {
    //     Modal.success({
    //           content: 'some messages...some messages...',
    //     })
    // }

    return (
        <Modal
            width={Math.min(modalDonationThnxMaxWidthInPx, Math.round(width * .9))}
            open={modalOpen}
            onOk={() => setModalOpen(false)}
            onCancel={() => setModalOpen(false)}
            // okText={donorDeclOkText}
            cancelText={null}
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            <H1C>Thanks for your donation</H1C>
            <div>Your contribution to this campaign is much appreciated.</div>
        </Modal>
    )
}

export default ModalDonationThanks
