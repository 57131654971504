// Ward.js

import React, { useContext } from 'react'
import ReactMarkdown from 'react-markdown'
import {
    H1C,
    media,
    StyledDebugDiv,
} from '../common/Builders'
import { breakpoints, DEBUG, ward } from '../common/constants'
import { MainContext } from '../contexts/MainContext'
import styled from 'styled-components'
// import { WardPdfModal } from '../components/WardPdfModal'

const {
    bodyTextBottomMdArr,
    bodyTextMdArr,
    mapImgs,
    mapPdfs,
    mapCaptionTextMdArr,
} = ward

export const Ward = props => {

    const {
        width,
        debugMode,
    } = useContext(MainContext)

    // const [ isPdfOpen, setIsPdfOpen ] = useState(false) /* modal */

    // const showModal = () => {
    //     setIsPdfOpen(true)
    // }
    
    // const handleOk = () => {
    //     setIsPdfOpen(false)
    // }

    const openPdf = url => {
        window.open(url, '_blank', 'noreferrer')
    }

    const wardDetails = bodyTextMdArr && Array.isArray(bodyTextMdArr)
        ? <ReactMarkdown>{bodyTextMdArr.join('\n')}</ReactMarkdown>
        : null

    const textBottom = bodyTextBottomMdArr && Array.isArray(bodyTextBottomMdArr)
        ? <ReactMarkdown>{bodyTextBottomMdArr.join('\n')}</ReactMarkdown>
        : null

    // const mapImg = mapImgs && Array.isArray(mapImgs)
    //     ? <img src={`${process.env.PUBLIC_URL}/${mapImgs[0]?.src}`} height={280} alt="" />
    //     : null
    const mapPdfPath = mapPdfs && Array.isArray(mapPdfs)
        ? `${process.env.PUBLIC_URL}/${mapPdfs[0]?.src}`
        : null

    const mapCaptionText = mapCaptionTextMdArr && Array.isArray(mapCaptionTextMdArr)
        ? <ReactMarkdown>{mapCaptionTextMdArr.join('\n')}</ReactMarkdown>
        : null
    
    const mapCaption = <MapCaption>{mapCaptionText}</MapCaption>

    const bgImgPath = `${process.env.PUBLIC_URL}/${mapImgs[0]?.src}`
    const bgImgAlt = mapImgs[0]?.alt || ''

    /* small devices */
    // const mapPanel = width < breakpoints.md
    const mapPanel = width < breakpoints.lg
        ? (
            <StyledWardLeft>
                {/* <div>Click to view map as a PDF</div> */}
                {mapCaption}
                <img
                    src={bgImgPath}
                    alt={bgImgAlt}
                    /* TODO: move to styled component */
                    style={{ maxWidth: `${Math.round(width * .8)}px`, border: '3px solid white', }}
                />
            </StyledWardLeft>
        )
        : (
            <StyledWardLeft
                bgimg={bgImgPath}
            >
                {/* [map]
                {mapImgs[0]?.src || null}
                {mapImg} */}
                {mapCaption}
            </StyledWardLeft>
        )

    return (
        <>
            <H1C>Narabeen Ward</H1C>
            <StyledWard
                className={DEBUG ? 'StyledWard' : ''}
                title='Click to view map as PDF'
                // title='Click to enlarge'
                // onClick={() => showModal()} /* TODO: Modal not loading PDF */
                onClick={() => openPdf(mapPdfPath)} /* hack: open in new tab */
            >
                {
                    DEBUG && debugMode
                    ? (
                        <>
                            <StyledDebugDiv />
                            width: { width }
                        </>
                    )
                    : null
                }

                <StyledWardRight>
                    {wardDetails}
                </StyledWardRight>

                {/* ward map: */}
                {mapPanel}

            </StyledWard>

            {/* TODO: Modal not loading PDF */}
            {/* <WardPdfModal
                mapPdfPath={mapPdfPath}
                isOpen={isPdfOpen}
                setOpen={setIsPdfOpen}
            /> */}
            <StyledWardBottom>
                {textBottom}
            </StyledWardBottom>
        </>
    )
}

const MapCaption = ({ children }) => <StyledWardMapCaption>{children}</StyledWardMapCaption>

const StyledWardMapCaption = styled.div`
    font-size: .9rem;
    /* for >= 768px width */
    ${media.lg`
        background: rgba(60, 60, 60, .6);
        text-align: center;
        margin-top: -.67rem;
    `}
`

const StyledWard = styled.div`

    // width: 800px;
    // margin-left: auto;
    // margin-right: auto;
    // display: flex;
    // flex-direction: row-reverse;
    display: flex;
    flex-direction: column;
    padding-left: .25rem;
    padding-right: .25rem;

    /* for >= 377px width */
    ${media.sm`
        font-size: 1.25rem;
    `}

    /* for >= 576px width */
    ${media.md`
        font-size: 1.25rem;
        width: 800px;
        margin-left: auto;
        margin-right: auto;
    `}

    /* for >= 768px width */
    ${media.lg`
        // background: purple;
        font-size: 1.1rem;
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
    `}

    /* for >= 992px width */
    ${media.xl`
        // font-size: 1.25rem;
        font-size: 1rem;
    `}
`

/* ward map: */
const StyledWardLeft = styled.div`
    flex: 2;
    background: url(${props => props?.bgimg || ''});
    margin: 1rem;
    padding: 1rem;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    // border: 0px dashed magenta;

    /* for >= 576px width */
    ${media.md`
        // margin: 2rem;
        margin: 0;
        // border: 0px dashed beige;
    `}

    /* for >= 768px width */
    ${media.lg`
        padding: 0; /* so caption text is neatly formatted */
    `}
`

const StyledWardRight = styled.div`
    flex: 3;
    // border: 1px solid yellow;

    /* for >= 576px width */
    ${media.md`
        font-size: 1rem;
        li {
            font-size: 1.1rem;
            margin: .33rem;
        }
        margin-left: 1rem;
    `}

    /* for >= 768px width */
    ${media.lg`
        li {
            font-size: 1.1rem;
            margin: .33rem 0 .33rem -1rem;
        }
    `}

    /* for >= 992px width */
    ${media.xl`
        li {
            font-size: 1.1rem;
            margin: .33rem;
        }
    `}

`

const StyledWardBottom = styled.div`
    // border: 1px solid yellow;
    // margin: 1rem 2rem 4rem 2rem;
    margin: 1rem .1rem 2rem .1rem;
    padding: 0 3rem;

    /* for >= 377px width */
    ${media.sm`
        font-size: 1.25rem;
    `}

    /* for >= 576px width */
    ${media.md`
        font-size: 1.25rem;
        margin: 1rem 2rem 4rem 2rem;
        padding: 0 3rem;
    `}

    /* for >= 768px width */
    ${media.lg`
        font-size: 1.25rem;
        width: 100%;
    `}

    /* for >= 992px width */
    ${media.xl`
        // font-size: 1.25rem;
        font-size: 1rem;
    `}

`
