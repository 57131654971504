// DonateForm.js

/* Container for the checkboxes, donor declaration modal and Square payment button */

import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Col, Divider, Row, } from 'antd'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import DonateModal from '../components/DonateModal'
import { DonateBtn } from '../components/DonateBtn'
// import EmailObjusc from '../components/EmailObjusc'
// import { H1C } from '../common/Builders'
import { DonateDirectDeposit } from '../components/DonateDirectDeposit'
import { DEBUG } from '../common/constants'

const outerCol1 = {
    offset: 0,
    span: 10,
    // style: { padding: '3px', }
}
const outerCol2 = {
    offset: 0,
    span: 12,
    // style: { padding: '3px', }
}
const innerCol = {
    offset: 1,
    span: 23,
    style: { padding: '3px', }
}
const rowStyle = {
    margin: '3px',
    padding: '3px',
}

export const DonateForm = props => {

    const {
        chbxABN,
        chbxDeclRead,
        chbxDeclReadBtn,
        chbxEnrolled,
        donateTextArrMd,
        donorDeclHdr,
        donorDeclOkText,
        modalDonorDeclMaxWidthInPx,
        paymentBtnText,
        squareDonationBtnLink,
        textPreBtn,
        // donateBankXferMdArr,
        donateBankXferMdActiveArr,
        donateBankXferMdInactiveArr,
        donateBankXferHdr,
        isSmallDevice = false,
        debugMode = false,
    } = props

    outerCol1.span = isSmallDevice ? 24 : outerCol1.span
    outerCol2.span = isSmallDevice ? 24 : outerCol2.span

    const textMd = donateTextArrMd && Array.isArray(donateTextArrMd)
        ? donateTextArrMd.join('\n\n')
        : ''

    // const bankXferTextMd = donateBankXferMdArr && Array.isArray(donateBankXferMdArr)
    //     ? donateBankXferMdArr.join('\n\n')
    //     : ''

    const bankXferTextActiveMd = donateBankXferMdActiveArr && Array.isArray(donateBankXferMdActiveArr)
        ? donateBankXferMdActiveArr.join('\n\n')
        : ''

    const bankXferTextInactiveMd = donateBankXferMdInactiveArr && Array.isArray(donateBankXferMdInactiveArr)
        ? donateBankXferMdInactiveArr.join('\n\n')
        : ''

    const [ isEnrolled, setIsEnrolled ] = useState(false)
    const [ isBusiness, setIsBusiness ] = useState(false)
    const [ isDeclRead, setIsDeclRead ] = useState(false)
    // const [ chbxDeclOpen, setChbxDeclOpen ] = useState(false)
    const [ isDeclOpen, setIsDeclOpen ] = useState(false) /* modal */
    const [ showDonateBtn, setShowDonateBtn ] = useState(false)

    const showModal = () => {
        setIsDeclOpen(true)
    }
    
    const handleOk = () => {
        setIsDeclOpen(false)
        setIsDeclRead(true) /* agreeing modal sets declaration agreement */
    }
    
    const handleCancel = () => {
        setIsDeclOpen(false)
        // setChbxDeclOpen(false) /* cancelling modal cancels declaration agreement */
        setIsDeclRead(false) /* cancelling modal cancels declaration agreement */
    }

    useEffect(() => {
        setShowDonateBtn(isDeclRead && (isEnrolled || isBusiness))
    }, [ isEnrolled, isBusiness, isDeclRead ])

    return (
        <>
            {
                DEBUG && debugMode
                    ? <pre>{JSON.stringify({ outerCol1, outerCol2, }, null, 2)}</pre>
                    : null
            }
            
            <Row>
                {/* <Col span={12} style={outerColStyle}> */}
                <Col {...outerCol1}>
                    {/* <pre>{textMd}</pre> */}
                    <ReactMarkdown
                        className="donate-blurb"
                    >{textMd}</ReactMarkdown>
                    <Row style={rowStyle}>
                        <Col {...innerCol}>
                            <Checkbox
                                checked={!!isEnrolled}
                                // onChange={e => alert(JSON.stringify(e, null, 2))}
                                onChange={e => setIsEnrolled(e.target.checked)}
                            >{chbxEnrolled}</Checkbox>
                        </Col>
                    </Row>
                    <Row style={rowStyle}>
                        <Col {...innerCol}>
                            <Checkbox
                                checked={!!isBusiness}
                                onChange={e => setIsBusiness(e.target.checked)}
                            >{chbxABN}</Checkbox>
                        </Col>
                    </Row>
                        {/* <p>isDeclRead: {isDeclRead ? 'yes' : 'no'}</p> */}
                        {/* <p>test: {test ? 'yes' : 'no'}</p> */}
                    <Row style={rowStyle}>
                        <Col {...innerCol}>
                            <Checkbox
                                // disabled={true}
                                defaultChecked={false}
                                checked={!!isDeclRead}
                                // onChange={e => setIsDeclRead(e.target.checked)}
                                onChange={e => setIsDeclRead(false)}
                                onClick={() => showModal()}
                            />
                            <label
                                // style={{ 'padding-inline-start': '8px', }}
                                style={{ paddingInlineStart: '8px', }}
                            >
                                <Link
                                    onClick={showModal}
                                >
                                    {chbxDeclRead}
                                    {/* <Button
                                        ghost
                                        style={{ marginTop: '-3px', }}
                                    >{chbxDeclReadBtn}</Button> */}
                                </Link>
                                <Button
                                    ghost
                                    onClick={showModal}
                                >{chbxDeclReadBtn}</Button>
                            </label>
                        </Col>
                    </Row>                
                </Col>

                <Col
                    // span={12}
                    // style={{ background: 'white', minHeight: '300px', }}
                    {...outerCol2}
                >
                    <DonateModal
                        {...{
                            donorDeclOkText,
                            donorDeclHdr,
                            isDeclOpen,
                            setIsDeclOpen,
                            handleOk,
                            handleCancel,
                            modalDonorDeclMaxWidthInPx,
                        }}
                    />

                    {/* {
                        showDonateBtn 
                            ? (<DonateBtn
                                { ...{
                                    paymentBtnText,
                                    squareDonationBtnLink,
                                    textPreBtn,
                                }}
                            />)
                            : null
                    } */}

                    {/* bank xfer stanza */}
                    {/* <ReactMarkdown
                        components={{
                            // a(props) {
                            //     console.log(20, {props})
                            //     return <button>{props?.children}</button>
                            // }
                            a(props) {
                                const { children, className, node, ...rest } = props
                                console.log(25, { children, className, node, ...rest })
                                return SurveyBtn(props)
                            },
                            // p: 'div',
                            p(props) {
                                return <StyledSurveyDiv>{props?.children}</StyledSurveyDiv>
                            },
                            ul(props) {
                                return <StyledUl>{props?.children}</StyledUl>
                            }
                        }}
                    ></ReactMarkdown> */}

                    <DonateBtn
                        { ...{
                            paymentBtnText,
                            squareDonationBtnLink,
                            textPreBtn,
                            isActive: !!showDonateBtn,
                            inactiveText: paymentBtnText,
                        }}
                    />

                    {/* <div>
                        <H1C>OR</H1C>
                        <ReactMarkdown
                            components={{
                                // code({ node, inline, className, children, ...props }) {
                                //     const match = /language-(\w+)/.exec(className || '')
                                //     // return !inline && match ? (
                                //     //     <div>
                                //     //         <p>{match}</p>
                                //     //     </div>
                                //     // ) : (
                                //     //     <code className={className} {...props}>
                                //     //         {children}
                                //     //     </code>
                                //     // )
                                //     // return (
                                //     //     <div>
                                //     //         <p>className: {className}</p>
                                //     //         <p>match: {JSON.stringify(match)}</p>
                                //     //         <p>inline: {JSON.stringify(inline)}</p>
                                //     //     </div>
                                //     // )
                                //     return !inline && Array.isArray(match) && match.length && match[1] === 'email' 
                                //         ? <EmailObjusc email={props?.children}/>
                                //         : <code className={className} {...props}>{children}</code>
                                // }
                            }}
                        >
                            {bankXferTextMd}
                        </ReactMarkdown>
                    </div> */}
                    {/* <DonateDirectDeposit { ...{ bankXferTextMd, isActive: !!showDonateBtn, } } /> */}
                    <DonateDirectDeposit
                        { ...{
                                bankXferTextMd: !!showDonateBtn ? bankXferTextActiveMd : bankXferTextInactiveMd,
                                isActive: !!showDonateBtn,
                                donateBankXferHdr,
                        } }
                    />

                </Col>
            </Row>

            {/* TODO: need more elegant solution for this */}
            {/* add space to ensure slogan doesn't cover text */}
            <Divider />
            {
                Array.from({length: 5}, (_, i) => <Row key={`space-${i}`} id={`space-${i}`}><Col>&nbsp;</Col></Row>)
            }
            <Divider />
        </>
    )
}

