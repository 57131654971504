import EmailObjusc from "../components/EmailObjusc"


export const DEBUG = process.env.NODE_ENV === 'development' /* detect if in development mode */
    ? {
        forceMac: true,
        // forceMac: false,
    }
    : null /* not development so nothing defined */

/* 
frontend/src/components/QuoteLeft.js:99:    z-index: 90;
frontend/src/components/Burger.js:101:    // z-index: 10;
frontend/src/components/RootCont.js:211:    z-index: 200;
frontend/src/components/Socials.js:62:    z-index: 200;
frontend/src/screens/IssuesParallax.js:137://     z-index: 2;
frontend/src/screens/Home.js:237:    z-index: 10;
 */
export const zIndex = {
    NavbarCont: 1000,
    QuoteLeft: 90,
    Burger: 1000,
    RootCont: 200,
    Socials: 200,
    IssuesParallax: 2,
    Home: 10,
}

export const breakpoints = {
    xs: 376,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1920,
}

/* corresponding to breakpoints */
export const sectionMaxWidthsInPx = {
    // xs: 360,
    // sm: 570,
    // md: 740,
    // lg: 900, /* absolute max width */
    // xl: 1100,
    // xxl: 1860,
    xs: 300,
    sm: 360,
    md: 570,
    lg: 740,
    xl: 900,
    xxl: 1100,
}

export const sloganFontsize = {
    xs: '1.33rem',
    sm: '1.33rem',
    md: '1.33rem',
    lg: '2rem',
    xl: '2rem',
    xxl: '2rem',
}

// export const navbarHeightInPx = 30
export const navbarHeightInPx = 60
// export const defaultBackground = '#f0808090'
export const defaultBackground = '#c50303'
export const whiteish = '#ffffff9a'
export const white = '#ffffff'
export const laborRed = '#bf2519'
export const bgLaborRed = '#bf25199a'
export const bgLaborRedOpac60pc = 'rgba(176, 68, 68, 0.6)'
export const bgLaborRedOldfashioned = '#C23931'
export const laborBlue = 'hsl(212, 75%, 33%)' // '#155195'
export const laborBlueExtremelyLight = 'hsl(212, 76%, 90%)'
export const laborBlueVeryLight = 'hsl(212, 74%, 85%)'
export const bgLaborBlue = '#1551959a'
export const darkCharcoal = '#363636'

export const scrollDuration = 700

export const defaultSectionPaddingBottom = '3px'

/*  defines structure of sections and interstitial elements
    only listed in navMenu if menuLabel defined  */
export const struct = [
    {
        key: 'home',
        path: '/',
        // menuLabel: 'HOME',
        title: '{home}',
        bgcol: laborRed,
        // component: 'Intro',
        component: 'Home',
        type: 'section',
        order: 1, /* first section MUST be 1 */
        // bgimgs: [
            // 'assets/images/unsplash/kate-trysh-ZUWls_bDgAk-unsplash.jpg',
            // 'assets/images/unsplash/javier-allegue-barros-i5Kx0P8A0d4-unsplash.jpg',
            // 'assets/images/unsplash/andres-mora-LbJD0y3l-50-unsplash.jpg',
            // 'assets/images/unsplash/roman-holoschchuk-SNa3JzPTqJY-unsplash.jpg',
            // 'assets/images/unsplash/william-krause-aSwigmwLS8A-unsplash.jpg',
            // 'assets/images/unsplash/asia-culturecenter-KSV8wvI2R6E-unsplash.jpg',
        // ],
        ga4: 'G-G5XDH5YCFF', /* Google Analytics Measurement ID property */
    },
    {
        key: 'about',
        path: '/about',
        menuLabel: 'About me',
        title: 'About Sue Wright', /* used for H1 title in section */
        // bgcol: laborBlue,
        bgcol: darkCharcoal,
        component: 'About',
        type: 'section',
        order: 4,
        sectionContDivWidthInPx: {
            xl: 800,
        },
        leftRightPadding: { /* overwrites (adjusts) the default padding */
            // sm: '.67rem',
            // xs: '.5rem',
            md: '0',
            sm: '.33rem',
            xs: '.15rem',
        },
        ga4: 'G-C8Z2325TL0', /* Google Analytics Measurement ID property */
    },
    {
        key: 'issues',
        path: '/issues',
        menuLabel: 'Issues',
        title: 'Issues',
        // bgcol: laborBlue,
        bgcol: darkCharcoal,
        component: 'Issues',
        // component: 'Section2',
        type: 'section',
        order: 2,
        leftRightPadding: { /* overwrites (adjusts) the default padding */
            sm: '.67rem',
            xs: '.5rem',
        },
        ga4: 'G-DYRCKCK9FZ', /* Google Analytics Measurement ID property */
    },
    {
        key: 'contact',
        path: '/contact',
        menuLabel: 'Get in touch',
        title: 'Get in touch',
        bgcol: darkCharcoal,
        component: 'GetInTouch',
        type: 'section',
        order: 5,
        leftRightPadding: { /* overwrites (adjusts) the default padding */
            // sm: '.67rem',
            // xs: '.5rem',
            md: '.25rem',
            sm: '.2rem',
            xs: '.15rem',
        },
    },
    // {
    //     key: 'interim',
    //     type: 'interim',
    //     component: 'Interim',
    //     order: 6,
    // },
    {
        key: 'donate',
        path: '/donate',
        menuLabel: 'DONATE',
        title: 'Donate to the campaign',
        // bgcol: laborBlue,
        bgcol: darkCharcoal,
        component: 'Donate',
        type: 'section',
        // order: 9, /* if order: not defined, will be placed last */
        leftRightPadding: { /* overwrites (adjusts) the default padding */
            xl: 0,
            lg: 0,
            md: 0,
            sm: 0,
            xs: 0,
        },
        foo: 'bar',
    },
    {
        key: 'events',
        path: '/events',
        menuLabel: 'Events',
        title: 'Campaign events',
        bgcol: darkCharcoal,
        component: 'Events',
        type: 'section',
        order: 3,
        // disabled: true,
        leftRightPadding: { /* overwrites (adjusts) the default padding */
            // sm: '.67rem',
            // xs: '.5rem',
            md: '0',
            sm: '.33rem',
            xs: '.15rem',
        },
    },
    {
        key: 'ward',
        path: '/narrabeen_ward',
        menuLabel: 'Narrabeen Ward',
        title: 'Narrabeen Ward',
        bgcol: darkCharcoal,
        // bgcol: laborRed,
        component: 'Ward',
        type: 'section',
        order: 3,
        width: { /* override sectionMaxWidthsInPx */
            xl: 1200,
            lg: sectionMaxWidthsInPx?.lg,
            md: sectionMaxWidthsInPx?.md,
            sm: sectionMaxWidthsInPx?.sm,
            xs: sectionMaxWidthsInPx?.xs,
        },
        leftRightPadding: { /* overwrites (adjusts) the default padding */
            sm: '.67rem',
            xs: '.15rem',
        },
    },
]
/* if attr "order" is missing, put at last position */
// .map((e, i, _self) => ({ ...e, order: e?.order || Math.max(..._self.map(e => e?.order || 0)) }))
.map((e, i, _self) => ({ ...e, order: e?.order || Math.max(..._self.map(e => parseFloat(e?.order) || 0)) + 1 }))
.sort((a, b) => b?.order > a?.order ? -1 : 1)

export const slogan = 'Community First Always'

/* bg img pool e.g. to be used at random */
export const bgImgs = [
    'assets/images/unsplash/kate-trysh-ZUWls_bDgAk-unsplash.jpg',
    'assets/images/unsplash/javier-allegue-barros-i5Kx0P8A0d4-unsplash.jpg',
    'assets/images/unsplash/andres-mora-LbJD0y3l-50-unsplash.jpg',
    'assets/images/unsplash/roman-holoschchuk-SNa3JzPTqJY-unsplash.jpg',
    'assets/images/unsplash/william-krause-aSwigmwLS8A-unsplash.jpg',
    'assets/images/unsplash/asia-culturecenter-KSV8wvI2R6E-unsplash.jpg',
]

export const splashPage = {
    textMdArr: [
        // 'Residents of **Narrabeen** need a councillor who **listens** to their concerns and strives ',
        'Residents of the **Narrabeen Ward** need a councillor who will **listen** to their concerns and strives ',
        'to achieve **solutions** best suited to **our community**.',
    ],
    bgImgs: [ /* served from public/images directory */
        {
            src: 'IMG_0145.JPEG',
            alt: 'Narrabeen lake near Malcolm Street',
        },
        {
            // src: 'images/politics_in_the_pub_12.jpg',
            src: 'politics_in_the_pub_12.jpg',
            alt: 'Outdoors politics in the pub',
        },
        {
            src: 'IMG_0133.JPEG',
            alt: 'Cycle break, early morning near Deep Creek entrance to Narrabeen lake',
        },
        {
            // src: 'images/rally_save_monavalehospital_AL5P1149.jpg',
            src: 'rally_save_monavalehospital_AL5P1149.jpg',
            alt: "People's rally to save Mona Vale Hospital",
        },
        {
            src: 'IMG_0139.JPEG',
            alt: 'Narrabeen beach landscape',
        },
        {
            // src: 'images/rally_crowd__AL5P1044.jpg',
            src: 'rally_crowd__AL5P1044.jpg',
            alt: 'Save our hospital rally65',
        },
        // {
        //     src: 'IMG_0138.JPEG',
        //     alt: 'Narrabeen beach',
        // },
        {
            src: 'IMG_0144.JPEG',
            alt: 'Narrabeen lake near Malcolm Street',
        },
    ],
    // animationDelayInSecs: 12,
    animationDelayInSecs: 21,
}

export const candidateName = 'Sue Wright'

export const candidateEmail = 'Sue.Wright@Labor4Narrabeen.com'

export const candidateAddress = [
    // '6/98-100 McIntosh Road',
    '98-100 McIntosh Road',
    'Narraweena NSW 2099',
]

export const modalDonorDeclMaxWidthInPx = 800
export const modalDonationThnxMaxWidthInPx = 400

export const links = [
    {
        text: 'Political donations for local government elections',
        url: 'https://elections.nsw.gov.au/funding-and-disclosure/political-donations/what-is-a-political-donation#politicaldonationsforlocalgovernmentelections',
    },
    {
        slug: 'political-donations-nec',
        text: 'Guidelines under the Electoral Funding Act 2018',
        url: 'https://elections.nsw.gov.au/about-us/legislation/funding-legislation/guidelines-under-the-electoral-funding-act-2018#politicaldonations'
        // url: 'https://elections.nsw.gov.au/about-us/legislation/funding-legislation/guidelines-under-the-electoral-funding-act-2018'
    },
    {
        slug: 'enrolment-nec',
        text: 'enrolment',
        url: 'https://elections.nsw.gov.au/voters/enrolment',
    },
    {
        slug: 'election-date-official',
        text: '2024 NSW Local Government elections, Saturday, 14 September 2024',
        url: 'https://elections.nsw.gov.au/elections/local-government-elections/2024-nsw-local-government-elections',
    },
    {
        slug: 'community-survey-1',
        text: 'Community Feedback Survey for Council Election',
        url: 'https://www.surveymonkey.com/r/ZJDNMND',
    },
    {
        slug: 'polling-narrabeen',
        text: 'Where to vote, Northern Beaches Narrabeen Ward',
        url: 'https://elections.nsw.gov.au/elections/find-my-electorate/councils/northern-beaches/narrabeen-ward#map-wrap',
    },
]

export const squareDonationBtnLink = 'https://square.link/u/CwariPpI?src=embed'

export const donorDeclarationTextMd = [
    'I declare that:',
    '* if the donation is made in an individual capacity, I am enrolled on the electoral roll ' + 
        'or I have supplied to the NSW Electoral Commission identification that is acceptable ' + 
        'to the NSW Electoral Commission showing the individual\'s full name and an Australian ' + 
        'residential address; and if the donation is made by an organisation, the ABN provided ' + 
        'above is true and accurate;',
    '* this donation is not made by or on behalf of a "prohibited donor" (being a "property ' + 
        'developer", "tobacco industry business entity" or "liquor or gambling industry ' + 
        'business entity"), or a "close associate" of a "prohibited donor" as defined in ' + 
        'Division 6 of Part 3 of the Electoral Funding Act 2018 (NSW); and', 
   '* I have not exceeded the donation cap of $7,600 to NSW Labor local government groups ' + 
        '(including up to $3,600 to a single local government elected member or candidate); ' + 
        'or $7,600 to NSW Labor local government elected members or candidates (including up ' + 
        'to $3,600 to a single local government elected member or candidate) in the 2023/24 ' + 
        'financial year.',
   'If you make a political donation of $1,000 or more (in a single contribution or ' + 
        'aggregated within a financial year), you must complete and lodge a declaration with ' + 
        'the NSW Electoral Commission in accordance with section 12 of the Electoral Funding ' + 
        'Act 2018.',
    'A political donation includes a contribution or entry fee or an annual or ' + 
        'other subscription. ',
    'You must also disclose a political donation of less than $1,000 ' + 
        'if the total amount of political donations made by you in respect of the same party ' + 
        '(or associated entity), elected member, group, candidate, third-party campaigner or ' + 
        'person in the same financial year is $1,000 or more. ',
    'Penalties apply for failing to lodge a declaration. ',
]

export const squareDonateUrl = 'https://square.link/u/CwariPpI'

export const enrolmentSlug = 'enrolment-nec'
export const donorSlug = 'political-donations-nec'
export const donateHdr = 'Support the campaign today!'
// export const textPreBtn = 'Donate online using your credit card'
// export const textPreBtn = 'Donate online using your credit card'
// export const textPreBtn = 'Donate online'
export const textPreBtn = 'Make a donation online'
// export const btnText = 'Donate'
export const paymentBtnText = 'Proceed to payment'
// export const chbxEnrolled = 'I confirm, I am enrolled to vote.'
export const chbxEnrolled = 'I am enrolled to vote.'
export const chbxABN = 'This is a contribution from a business (registered with an ABN).'
// export const chbxDeclRead = 'I have read, and I comply with, the donor declaration.'
export const chbxDeclRead = 'I have read, and I comply with, the '
export const chbxDeclReadBtn = 'donor declaration'
export const donorDeclHdr = 'DONOR DECLARATION'
export const donorDeclOkText = 'I AGREE'

export const donateEmailAddr = 'donation@Labor4Narrabeen.com'
export const donateEmailAddrObfusc = <EmailObjusc email={donateEmailAddr} />
// export const donateBankXferMdArr = [
export const donateBankXferHdr = 'Donate via direct deposit'
export const donateBankXferMdActiveArr = [
    // '## Donate via direct deposit',
    'Account name: **Narrabeen Ward Fundraising**',
    'Bank: **NAB**',
    'BSB: **082-146**',
    'Account number: **557852817**',
    '',
    // `Please send us an email - ${donateEmailAddrObfusc} - with the address at which you are enrolled to vote, `
    '***NOTE:*** To meet regulatory requirements, you must be enrolled to vote AND we need to know who our donors are. ',
    'So if you make a bank transfer, please send us an email - to **`donation@labor4narrabeen.com`** - '
        + 'with your full name, address at which you are enrolled (or ABN in the case of a company).',
    'Many thanks.'
    // '```email',
    // `${donateEmailAddr}`,
    // '```',
    // 'with the address at which you are enrolled to vote, ',
        // + 'or include it in the `description` part of your bank transfer.',
]
export const donateBankXferMdInactiveArr = [
    '## Donate via direct deposit',
    'NOTE: To meet regulatory requirements, you must be enrolled to vote or have an ABN. '
        + 'Please select the checkboxes as appropriate',
]

export const enrolmentLink = links && Array.isArray(links) ? links.find(({ slug }) => slug === enrolmentSlug) : null
export const donorLink = links && Array.isArray(links) ? links.find(({ slug }) => slug === donorSlug) : null
export const donateTextArrMd = [
    `[${enrolmentSlug}]: ${enrolmentLink?.url}`,
    `[${donorSlug}]: ${donorLink?.url}`,
    // 'Will you support Sue Wright\'s bid for a Labor voice on the '
    //     + 'Northern Beaches Council with a contribution today? ',
    'Your financial contribution will support Sue Wright\'s bid for a Labor voice on the '
    + 'Northern Beaches Council in the upcoming local government election.',
    // `If you are [enrolled to vote][${enrolmentSlug}], `
    //     + '&mdash; or are a registered business (with an ABN) &mdash; '
    //     + 'and are not excluded from donating by regulation '
    //     + `(see [NSW Electoral Commission website][${donorSlug}]), `
    //     + 'please click the button to proceed to payment.',
]

/* Login: order the events first by attrib "order", then by date */
export const events = [
    {
        order: 1,
        date: new Date('30 June 2024 14:30'),
        duration_mins: 120,
        venue: {
            name: 'Dee Why Bowling Club',
            address: '223 Fisher Rd North, Dee Why 2099',
            details: 'Level access to the venue and plenty of parking available',
            // google_maps: '223+Fisher+Rd+N,+Dee+Why+NSW+2099'}
            // google_maps: 'https://maps.app.goo.gl/P3v811P7rrjH92WQ9',
            google_maps: 'https://maps.app.goo.gl/YmSMR1LTJkynMMwH7',
        },
        admission_cost: 'Gold coin donation',
        // leader: 'Meet \'n Greet Sue Wright',
        // subtitle: 'Labor candidate for Narrabeen Ward, Northern Beaches Council Election 2024',
        leader: 'Meet \'n Greet Sue Wright, '
            + 'Labor candidate for Narrabeen Ward, Northern Beaches Council Election 2024',
        subtitle: '',
        details: [
            'Come and meet Sue, along with her running mates Ryan O\'Sullivan '
                + 'and Rhonda Furnell, at an informal afternoon tea, and discover '
                + 'why we think Sue\'s voice is just what the community needs to provide '
                + 'a better balance of views on the Northern Beaches Council. ',
            '',
            '---',
            '',
            'Raffle tickets: 3 for $5 (available at the door)',
            'Light refreshments (tea and coffee, sandwiches & cakes provided.)',
            'BYO: "buy your own drinks" at the bar.'
        ],
        rsvp: {
            text: [
                'Please RSVP if you wish to attend'
            ],
            links: [
                {
                    link: 'mailto:rsvp@labor4narrabeen.com', 
                    text: 'Email: rsvp@labor4narrabeen.com',
                },
                {
                    link: null, 
                    text: 'Text to Marg O\'Connell on 0447 093 939', 
                },
            ]
        }
    },
    // {
    //     order: 9,
    //     date: new Date('27 July 2024 10:00'),
    //     duration_mins: 120,
    //     venue: [
    //         'Some pub',
    //         'Somewhere on the Northern Beaches',
    //     ],
    //     details: [
    //         'TEST 02'
    //     ],
    //     rsvp_text: [
    //         'Please RSVP to'
    //     ],
    //     rsvp_links: [
    //         {
    //             link: 'mailto:rsvp@labor4narrabeen.com', 
    //             text: 'Email: rsvp@labor4narrabeen.com',
    //         },
    //         {
    //             link: null, 
    //             text: 'Text to Marg O\'Connell on 0447 093 939', 
    //         },
    //     ],
    // },
    {
        date: new Date('21 August 2024 18:30'),
        leader: 'Council Candidate Forum (National Parks Association)',
        duration_mins: 90,
        venue: {
            name: 'Lakeview Hall at The tramshed Narrabeen',
            address: '1395A Pittwater Rd, Narrabeen NSW 2101',
            details: null,
            google_maps: 'https://maps.app.goo.gl/8UkPQenhfKh9FtUQ9',
        },
        details: [
            'MC Wendy Harmer',
            'Bookings essential!',
            '',
            'The National Parks Association and Friends of Narrabeen Lagoon Catchment have teamed up to host a ',
            'candidate forum with representatives from the different parties/groups. They are encouraging people ',
            'to attend and find out more about where the candidates stand on planning and environmental issues.',
            '',
            'See the [NPA website][meet-the-candidates] for bookings and more information. ',
            '',
            '[meet-the-candidates]: https://npanswsrb.org.au/activities/meet-the-candidates/',
        ],
    },
    {
        date: new Date('31 August 2024 09:00'),
        // date: new Date('31 October 2024 09:00'), /* TEST */
        leader: 'Coastal Environment Centre Open Day',
        subtitle: 'Northern Beaches LEAN',
        duration_mins: 300,
        venue: {
            name: 'Coastal Environment Centre Narrabeen',
            address: 'Entrance Pelican Path, Lake Park Rd, North Narrabeen NSW 2101',
            details: null,
            google_maps: 'https://maps.app.goo.gl/ny1vABtk83BqPgzG8'
        },
        details: [
            '- Meet native animals',
            '- Grab a free native plant and free gardening advice',
            '- Learn how easy it is to compost or create a worm farm',
            '- Chat with our local environment groups about volunteering opportunities',
            '- Learn about the importance of insects',
            '- Sustainability stalls',
            '- Take a rock platform tour',
            '- Enjoy a tasty sausage sizzle to support the local Scouts',
            '- Kids can meet Tilly the Turtle and get their face painted',
            '- Live entertainment and much more!',
        ],
    },
    {
        date: new Date('14 September 2024 08:00'),
        leader: 'Polling day - 2024 NSW Local Government elections',
        duration_mins: 600,
        details: [
            '[NEC]: https://elections.nsw.gov.au/',
            'Checkout [the NSW Electoral Commission website][NEC] for details on voting.'
        ],
    }
]
export const timesGridColMaxWidthInPx = 367;
export const eventHdrs = {
    when: 'When',
    where: 'Where',
    rsvp: 'RSVP',
}
export const eventLabels = {
    appleMapDirections:  'Directions in Apple Maps',
    googleMapDirections: 'Google map and directions',
}

/* The "ABOUT" page content */
const officialElectionDateLinkSlug = 'election-date-official'
const electionDateOfficialLink = links && Array.isArray(links)
    ? links.find(({ slug }) => slug === officialElectionDateLinkSlug)
    : null
// export const aboutTextArrMd = [
export const about = {
    quotes: [
        // Quotes attributable to Sue Wright, NSW Labor candidate for Wakehurst:
        'I understand the complexities of the challenge ahead of me in the '
            + 'Narrabeen Ward, but my number one priority in this campaign '
            + 'is to listen to local residents and provide them with a '
            + 'voice and a different choice in this election.',
        // 'Local residents deserve a voice, and their vote should count.  ',
        'Working with our community, for our community.',
    ],
    quoteImgs: [ /* served from public/images directory */
        {
            // src: 'images/politics_in_the_pub_12.jpg',
            // src: 'images/AL5P1044_cropped.jpg',
            // src: 'images/sue_portrait.jpg',
            src: 'images/sue_portrait2.jpg',
        },
        {
            // src: 'images/sue-wakehurst_parkway-image5.png',
            src: 'images/221210_NSW_LaborParty_HStewart__SHOT_33_Sue_Wright_67797.jpg',
        },
    ],
    bodyText0: [
        `[${officialElectionDateLinkSlug}]: ${electionDateOfficialLink?.url}`,
        // 'Sue is running to represent represent the Labor Party in the upcoming '
        //     + `[NSW Local Government Election on 14th September 2024][${officialElectionDateLinkSlug}]. `,
        // '',
        // 'Sue has been a hard-working member of the community for ' 
        //     + 'many years, working on issues such as fighting to keep '
        //     + 'Mona Vale Hospital open and the NBH to account, '
        //     + 'campaigning to improve ferry and bus services, '
        //     + 'working with Minister John Graham to secure $13 million in funding '
        //     + 'for foodproofng the Wakehurst Parkway, and much more. ',
        '',
        'Fourth generation Northern Beaches resident and local activist, Sue Wright, has been selected as the ',
        `Labor candidate for the Narrabeen Ward in the [2024 NSW LGA Election][${officialElectionDateLinkSlug}]. `,
        '',
        'Sue\'s family has lived on the Northern Beaches since 1915, first moving to Ocean Street, Narrabeen. ',
        'She grew up in Narrabeen and on Collaroy Plateau and loves and wants to protect and improve all that ',
        'this beautiful part of the world has to offer. ',
        '',
        'She has been an active member of the community with involvement in local grassroots organisations ',
        'including Save the Mona Vale Hospital Committee, Save the Manly Ferries Alliance and Don\'t Privatise ',
        'Our Buses, as well as Mothers Against Nuclear Testing, the Labor Environment Action Network (LEAN) and ',
        'Greenpeace. ',
        '',
        'Sue also stood as the Labor Candidate for Wakehurst in the 2024 State Election. ',
        'Sue raised her two children in the area and was closely involved in the school community at Collaroy ',
        'Plateau Public School, volunteering on the P&C and as the coordinator of the Uniform Shop and the very ',
        'popular Unit Lunch. ',
        '',
        'Sue was involved with the Collaroy Plateau Netball Club, playing, coaching and umpiring for the club. ',
        '',
        'She has also been associated with Narrabeen Sports High School P&amp;C for the last ten years as the ',
        'coordinator of their much-loved Car Boot Sale, which is the primary fundraiser for the school. ',
        '',
        'Sue\'s approach to and experience with politics has primarily been as a community activist, working and ',
        'collaborating with fellow activists and committee members of different political parties and persuasions.  ',
        '',
        'If elected, Sue realises there is much to be done in the Narrabeen Ward and this can be best achieved by ',
        'listening to the residents and working with her fellow councillors. ',
        '',
    ],
    // bodyText: [
    bodyTextTeaser: [
        `[${officialElectionDateLinkSlug}]: ${electionDateOfficialLink?.url}`,
        '[article-northern-beaches-advocate]: https://www.northernbeachesadvocate.com.au/2024/08/11/labor-sets-sights-on-council/',
        '',
        '> ***See [this article in the Northern Beaches Advocate][article-northern-beaches-advocate] ',
        'about the Labor candidates for Northern Beaches council election***.',
        '',
        'Fourth generation Northern Beaches resident, 2023 state election candidate and local activist, Sue Wright, ',
        'has been selected as the Labor candidate for the Narrabeen Ward in the 2024 NSW LGA Election.',
        'Sue\'s family has lived on the Northern Beaches since 1918, first moving to Ocean Street, Narrabeen. ',
        'She grew up in Narrabeen and on Collaroy Plateau, and wants to protect and enrich all that this beautiful ',
        'part of the world has to offer. ',
    ],
    bodyTextReadMore: [
        `[${officialElectionDateLinkSlug}]: ${electionDateOfficialLink?.url}`,
        '',
        '> "My family has a long history with the Narrabeen and Collaroy Plateau area. My brother and I are the ',
        '4th generation in our family fortunate enough to call this stunning corner of Sydney home, my children ',
        'and my nephews are the 5th. From childhood to adulthood, I\'ve witnessed firsthand the unique charm and ',
        'challenges of our community.',
        '',
        '> On my father\'s side, the house my grandfather and his family lived in at 207 Ocean St from 1918 is now a café, ',
        'but the house where my grandmother\'s family moved to in 1922 is still there at 1224 Pittwater Rd, on the ocean ',
        'side of Goodwin Street.',
        '',
        '> On my mother\'s side, my grandparents moved their young family from Marrickville in 1948, and lived on the corner ',
        'of Ocean and Devitt Streets, before buying land and building a house in Veterans Parade on Collaroy Plateau. ',
        'My grandfather helped to found South Narrabeen Surf Club and he ran A.E. Biggs Sand and Cement, a fixture in ',
        'South Narrabeen, for his mate Arthur Biggs, for over 35 years."',
        '',
        '> "I raised my two children in the area and was closely involved in the school community at Collaroy Plateau ',
        'Public School, volunteering on the P&C, in the Uniform Shop and as the coordinator of our very popular Unit Lunch. ',
        'I also had a long involvement with the Collaroy Plateau Netball Club, not only as a player, but also as an umpire ',
        'and a coach."',
        '',
        'Sue has been an active member of the community with involvement in local grassroots organisations including ',
        '**Save the Mona Vale Hospital Committee**, **Save the Manly Ferries Alliance**, **Don\'t Privatise Our Buses**, ',
        '**Mothers Against Nuclear Testing**, as well as being a member of Greenpeace and the Northern Beaches Coordinator ',
        'for the Labor Environment Action Network (LEAN).',
        '',
        'Sue has also been associated with Narrabeen Sports High School P&C for the last ten years as the coordinator ',
        'of their much-loved Car Boot Sale, which is the primary fundraiser for the school.',
        '',
        'Sue proudly stood as the Labor Candidate for Wakehurst in the 2024 State Election and worked with ',
        'The Hon Minister John Graham to secure $13 million in funding for the state government and Northern Beaches ',
        'Council\'s Wakehurst Parkway flood proofing project. ',
        '',
        'Prior to the state election, her approach to and experience with politics had primarily been as a community ',
        'activist, working and collaborating with other like minded activists and her fellow committee members, all of ',
        'different political parties and backgrounds.',
        '',
        'If elected, Sue realises there is a lot we can do in the Narrabeen Ward together and that this can be best ',
        'achieved by listening to our residents and working alongside her fellow councillors.',
        '',
        '> "With a determination to foster positive change, I am dedicated to ensuring that the Northern Beaches ',
        'remains a vibrant and secure place for all who live here."'
    ],
}

export const contactHdr = 'Get in contact with Sue'
export const contactText = 'If you wish to contact me, please provide your details and message here:'

export const acknowledgementCountry = {
    hdr: 'Acknowledgement of Country',
    text: 'In the spirit of reconciliation, Sue Wright acknowledges '
        + 'the Garigal and Gayamaygal of the Eora nation '
        + 'the Traditional Custodians of the '
        + 'lands and place we call Narrabeen '
        + 'and their connections to land, sea and community. We acknowledge and pay our respects to '
        + 'their Elders past, present and future, and extend that respect to all Aboriginal and Torres Strait '
        + 'Islander peoples today.'
}

const surveySlug1 = 'community-survey-1'
const surveyLink1 = links && Array.isArray(links)
    ? links.find(({ slug }) => slug === surveySlug1)
    : null
export const issues = {
    hdr: 'What are the issues important to Narrabeen residents?',
    textMd: [
        `[${surveySlug1}]: ${surveyLink1?.url}`,
        'For the Narrabeen Ward and the Northern Beaches, I am focused on bringing to council measures to',
        '- help residents with the cost of living pressures;',
        '- protect and enrich our local coastline and bushlands and increase our greenscape in suburban areas;',
        '- facilitate environmentally responsible affordable housing, especially for our essential workers; and',
        '- make our community a safer place for our residents to live in.',
        'Let me know what\'s important to you by having your say:',
        '',
        // // `To this end, let me know your thoughts on the issues of importance to you by [completing this brief survey][${surveySlug1}]. `,
        // // `To this end, let me know your thoughts on the issues of importance to you by completing this brief survey: `,
        // 'To this end, let me know your thoughts on the issues of importance to you by having your say: ',
        // // `[COMMUNITY ISSUES SURVEY][${surveySlug1}]`
        `[Complete a brief SURVEY][${surveySlug1}]`
    ],
}

export const socials = [
    {
        medium: 'facebook',
        url: 'https://www.facebook.com/profile.php?id=61563161579607',
        order: 1,
        col: '#1877F2',
    },
    {
        medium: 'twitter',
        // url: 'https://x.com/Labor4Narrabeen',
        url: 'https://twitter.com/Labor4Narrabeen',
        order: 1,
        col: '#000',
    },
]

const pollingNarrabeenSlug = 'polling-narrabeen'
const pollingLink = links && Array.isArray(links)
    ? links.find(({ slug }) => slug === pollingNarrabeenSlug)
    : null
// export const wardInfoMdArr = [
export const ward = {
    bodyTextMdArr: [
        'If you live in any of the following suburbs, you are in the **Narrabeen Ward** ',
        'of the Northern Beaches Council ',
        'for the 2024 ',
        `[New South Wales Local Government election][${officialElectionDateLinkSlug}] :`,
        '- Collaroy', 
        '- Collaroy Plateau', 
        '- Cromer', 
        '- Elanora Heights', 
        '- Ingleside', 
        '- Narrabeen', 
        '- North Narrabeen', 
        '- Oxford Falls', 
        '- Terrey Hills', 
        '- Warriewood', 
        '- Wheeler Heights', 
        '',
        `[${officialElectionDateLinkSlug}]: ${electionDateOfficialLink?.url}`,
        // [enrolled to vote][${enrolmentSlug}], `
    ],
    mapImgs: [
        {
            src: 'images/map-northern_beaches-wards-PP5001-W.png',
            alt: 'Map of LGA Northern Beaches Wards, reference PP5001-W',
        },
    ],
    mapPdfs: [
        {
            src: 'pdf/northern-beaches-council-ward-map_PP5001-W.pdf',
            alt: 'PDF Map of LGA Northern Beaches Wards, reference PP5001-W',
        },
    ],
    bodyTextBottomMdArr: [
        // 'pollingLink',
        // `Use the NSW Electoral Commission's map of [polling booths][${pollingNarrabeenSlug}] .`,
        // Where to vote and other important locations
        'Where to vote and other important locations are shown on ',
        `[this map provided by the NSW Electoral Commission][${pollingNarrabeenSlug}] .`,
        '',
        `## The elections are [set for Saturday, 14 September 2024][${officialElectionDateLinkSlug}].`,
        '',
        `Check your [enrolment online][${enrolmentSlug}].`,
        '',
        `[${enrolmentSlug}]: ${enrolmentLink?.url}`,
        `[${officialElectionDateLinkSlug}]: ${electionDateOfficialLink?.url}`,
        `[${pollingNarrabeenSlug}]: ${pollingLink?.url}`,
        '',
    ],
    mapCaptionTextMdArr: [
        '_Click to view map as a PDF_',  
    ],
}

/* 2nd level pages with backlink */
export const subpages = [
    {
        key: 'candidates-forum',
        hdrMdArr: [
            'Questions and my answers for ***Candidates Forum*** held on ',
            '21 August 2024 at Narrabeen.',
        ],
        linkTextMd: 'Candidates Forum Q & A',
        teaserMdArr: [
            'At the link below are the questions and my answers for the ***Candidates Forum*** held on ',
            '21 August 2024 at Narrabeen. The forum was convened by the National Parks Association ',
            'and the Friends of Narrabeen Lagoon',
        ],
        contentMdArr: [
            'Here are the questions and my answers for the ***Candidates Forum*** held on ',
            '21 August 2024 at Narrabeen. The forum was convened by the National Parks Association ',
            'and the Friends of Narrabeen Lagoon and I thank both for organising the meeting. ',
            'If elected these are the policies I will be seeking to introduce. ',
            `Should you have questions on any of these issues please don't hesitate to [contact me][contact].`,
            '',
            `[contact]: /contact`,
            '',
            '## ***Candidate introduction and Climate Change: What attributes would you bring as a Councillor of the Northern Beaches?*** ',
            '',
            'My family has a long history with the Narrabeen area. My brother and I are the ',
            'fourth generation in our family to live in Narrabeen, my children and my nephews ',
            'are the fifth. I grew up at Narrabeen and Collaroy Plateau and sent my now adult ',
            'children to local public schools. ',
            'I have owned my own businesses – a florist in Curl Curl and a toy library at South Narrabeen. ',
            'I\'m determined. I can be a true pain in the butt to politicians of any party and bureaucrats who ',
            'think they can ignore our community\'s needs. But I\'m also a good listener. If someone is an expert ',
            'or if they have a different view to mine, I listen to what they have to say. ',
            'I might not agree, but I listen. ',
            'For the last forty years I have been, amongst other things, an activist - my activism includes: ',
            '- much needed fundraising for public primary and high schools, ',
            '- with the help of many other people, being the organiser of the **Yes** rally at Village Park ',
            'in Mona Vale in September last year. It was a great day, with huge community support. ',
            '- fighting for the retention of Mona Vale hospital, trying to retain a fully public hospital ',
            'on the Northern Beaches ',
            '- opposing the privatisation of our bus services ',
            '',
            'Three things I\'m particularly proud of are: ',
            '',
            '### 1. That during the 2023 state election I worked with The Hon. John Graham, the Minister for Roads, to secure $13 million in funding to go to the Council toward the Wakehurst Parkway floodproofing. ',
            '',
            '### 2. Being a committee member of ***Save the Manly Ferries*** which resulted in the reintroduction of the Freshwater class ferries with over 75000 petition signatures presented, with Labor keeping their promise when they won government.',
            '',
            '### 3. As a founding member in 2021, then last year being elected as coordinator of ***Northern Beaches Labor Environmental Action Network***. ',
            '',
            'LEAN is a group of mostly Labor pro environmental members who want the Party to be more environmentally aware. ',
            'Although I strongly support the Labor government there are some policies I would like to see changed. ',
            'I am part of a growing group of Labor activists who are working to have the government take some bold ',
            'decisions on deforestation, bio security and most importantly climate change. My purpose in leading ',
            'Northern Beaches LEAN is to influence the decision makers in the Albanese government to make stronger ',
            'environmental laws. ',
            'As a member of the State executive I want to take some of the LEAN policies to the Northern Beaches council. ',
            'I am extremely proud to be representing the Labor Party in the Narrabeen Ward in the Council Election ',
            'in September. We need a Labor voice on council, one that can work collaboratively with other councillors. ',
            'Our Council also needs to collaborate with my fellow Labor party members in the state and federal governments, ',
            'and our local MPs, on projects like roads, infrastructure, housing, bushland and coastal management. ',
            'We need a Labor voice on council to have a balanced Northern Beaches council. ',
            '',
            '## ***What do I hope to achieve for the Northern Beaches LGA if elected?***',
            '',
            'For the Narrabeen Ward and the Northern Beaches I am focused on bringing to Council measures to: ',
            '- Help residents with cost-of-living pressures in whatever way I can',
            '- Facilitate housing for our pensioners, homeless people and essential workers without compromising community character. ',
            '- Protect and enrich our local coastline and bushlands.',
            '',
            'My priority is ramping up Council\'s policies on the environment. This will include reviewing ',
            'the effectiveness of the Councils Tree Canopy Plan. ',
            'Although well intentioned it clearly has some serious flaws. The recent incident at Ruskin Rowe ',
            'in Avalon clearly showed the plan is not working. But that\'s only one part of the Plan. ',
            'Its target for new trees of 5000 is not enough. We must be bold and seek a much higher number. ',
            'Why not 20000 new trees, maybe more. ',
            '',
            'Preservation of our beaches is another top priority. ',
            '',
            'I WILL NOT VOTE FOR COUNCIL TO ALLOCATE ANY MORE FUNDS FOR THE ERECTION OF SEAWALLS AT NEWPORT, ',
            'COLLAROY OR NARRABEEN. NONE. I WILL GO FURTHER THAN THAT, I WILL BE SEEKING THAT COUNCIL AMEND ',
            'ITS LEP SO AS TO PROHIBIT VERTICAL SEAWALLS AT ANY MORE OF ITS BEACHES. ',
            'WHY SHOULD RATEPAYERS PAY FOR THE REDUCTION AND EVENTUAL DESTRUCTION OF THEIR BEACHES? ',
            '',
            '**Increase our greenscape in suburban areas**. ',
            'Linked to the Tree Canopy Plan is improving our streetscape. ',
            'My plan is to have the community involved in planting in their precinct. ',
            'Council provides the trees, shrubs and other plants, residents plant and maintain them. ',
            '',
            '**Make our community a safer place to live and work.** ',
            'As a woman I am very aware of improving the safety of our public spaces. ',
            'Bus shelters at night are not lit and can be dark and very intimidating. ',
            'They all should be lit with solar powered lights providing a safer environment for woman and children. ',
            '',
            '## ***Do you consider the effects of climate change a high priority for a Councillor, in their decision making? If so, how will your decisions reflect this?*** ',
            '',
            '### Climate Change as a Priority: ',
            '',
            '- Climate change is a top priority in all decision-making processes. ',
            '- I will Commit to integrating climate action into all council policies, ',
            'especially in planning and development controls. ',
            '- I will Advocate for renewable energy use in council operations and community projects. ',
            '- I would recommend that Decisions be guided by the principles of sustainability, ',
            'aiming to reduce the Northern Beaches\' carbon footprint. ',
            '',
            '## ***LIZARD ROCK PLANNING PROPOSAL: Do you oppose the Lizard Rock planning proposal? If so, what actions would you commit to as a Councillor, to address this?*** ',
            '',
            'I have opposed the Lizard Rock planned development from the start. ',
            'Northern Beaches LEAN submitted an objection to the proposal, a copy is on my website. ',
            'Earlier this year I spoke to Paul Scully and explained my reasons for opposing the plan. ',
            '',
            'I OPPOSE THE LIZARD ROCK PLANNING DEVELOPMENT FOR A NUMBER OF REASONS. ',
            'IT IS A BUSHFIRE RISK AND AN ENVIRONMENTAL TRAGEDY JUST WAITNG TO HAPPEN. ',
            'HABITATS, WILDLIFE, INCREASED FLOOD RISKS DUE TO MORE HARD SURFACES, ',
            'NO PUBLIC TRANSPORT OPTIONS LEADING TO INCREASED TRAFFIC CONGESTION – ',
            'WHICH IN TURN MEANS MORE POLUTION, LACKING INFRASTRUCTURE AND DID I MENTION THE ',
            'potential environmental impact on sensitive bushland. ',
            '',
            'I will collaborate with community efforts to halt this proposal, ',
            'emphasising the need for conservation over development. ',
            'Advocate for a comprehensive review of the planning proposal, highlighting alternative ',
            'solutions that protect the natural environment and potential residents. ',
            'Listen to the community\'s concerns and relay them to the local, state and federal ministers. ',
            '',
            '## ***ENVIRONMENTAL COMPLIANCE: The Council\'s environmental compliance department is struggling to prevent vegetation & biodiversity loss due to unauthorized clearing across the LGA. What actions would you take to address this?*** ',
            '',
            'Unauthorised clearing can only be stopped or prosecuted if the culprits are caught in the act. ',
            'This requires the public to report it and the Council to respond immediately. ',
            'This would be facilitated if the following action in the Tree Canopy Plan adopted by ',
            'Council in Sept 23 is implemented: ',
            '',
            '> "Develop a system of notification for council approved tree removals with signs posted ',
            'at properties alerting the community to the proposed removal, providing opportunity for ',
            'community feedback and identifying that permission exists for tree removal".',
            '',
            'The public needs to be urged to report illegal clearing and "tree loppers" canvasing ',
            'for work door-to door. Maximum fines also need to be applied when culprits are identified.',
            '',
            'I would ',
            '- Push for increased resources and funding for the environmental compliance department. ',
            'More arborists and arborist apprenticeships employed by the Council, not contracted out, ',
            'are desperately needed.',
            '- Advocate for stricter enforcement and penalties for unauthorised clearing across the LGA. ',
            '- Support community education programs on the importance of preserving local vegetation and biodiversity. ',
            '- Promote real-time monitoring and reporting systems to address violations promptly.',
            '',
            '***NET ZERO AND DEVELOPMENT: Do you support the inclusion of "Towards Net Zero" aims ',
            'in the Development Control Plan (DCP) for all new residential and non-residential ',
            'developments across the LGA? Specifically, do you support requiring developments ',
            'to be all-electric and renewable?***',
            '',
            'Support for "Towards Net Zero" in DCP:',
            '',
            '- Fully support the inclusion of net-zero targets in the Development Control Plan for all new developments.',
            '- Advocate for all-electric and renewable energy-powered developments across the LGA.',
            '- Encourage incentives for developers who meet or exceed net-zero standards.',
            '- Ensure new developments contribute positively to the environment, with a focus on sustainability ',
            'and energy efficiency.',
            '',
            '***SOCIAL AND AFFORDABLE HOUSING: Council\'s*** Toward 2040 Local Strategic Planning Statement ***seeks',
            'a minimum of 10% social and affordable rental housing to be included in new planning proposals ',
            'which is consistent with Council\'s existing affordable housing policy. However, Council has ',
            'contradicted its own requirements in the masterplan for Brookvale and reduced the minimum requirement ',
            'to 5%, based on a "feasibility analysis which has indicated that the redevelopment would not be viable ',
            'with a requirement to contribute more than 5% affordable housing"***',
            '',
            '## ***Given the rental crisis, how do you feel about Councils inconsistency with its own plans and what would you do as a Councillor to address this crisis?***',
            '',
            '- I was surprised and disappointed that council reduced its own minimum requirement of 10% of new proposals ',
            'to 5% in the Brookvale Masterplan. Supposedly because a feasibility study recommended against the 10%. ',
            'Who prepared this feasibility study? What evidence did they have? Don\'t we live in a market economy. ',
            'Wouldn\'t the market make adjustments for the 10%. I think the Council may have been poorly advised. ',
            '- Strongly advocate for maintaining an absolute minimum of 10% social and affordable housing in all new developments.',
            '- Call for a reassessment of the feasibility analysis to explore alternative solutions that maintain the 10% target.',
            '- Work with stakeholders to find a balance between development feasibility and the critical need for affordable housing.',
            '',
            '## ***SYNTHETIC TURF INSTALLATIONS: Do you share community concerns about the growing use of synthetic turf '
                + 'and the release of micro plastics into the waterways? '
                + 'What actions do you think Council should be taking to address these concerns?***',
            '',
            'I DO SHARE THE COMMUNITY\'S CONCERNS ABOUT THE INCREASED USE OF SYNTHETIC TURF ON SPORTING FIELDS ',
            'AND AM VERY CONCERNED ABOUT THE RELEASE OF MICROPLASTICS INTO OUR WATERWAYS. ',
            'SYNTHETIC TURF INCREASES TEMPERATURES, DOESN\'T ALLOW THE SOIL TO BREATHE, ',
            'DECREASES WATER ABSORPTION LEADING TO MORE FLOODING.',
            '',
            '- Advocate for a moratorium on new synthetic turf installations until further environmental ',
            'impact assessments are conducted.',
            '- Promote the use of natural grass and other eco-friendly alternatives in public spaces.',
            '- Push for stricter regulations on the use of synthetic materials in council projects. ',
            '',
            'I ALSO THINK THAT WE SHOULD INCREASE THE NUMBER OF COUNCIL EMPLOYED, NON CONTRACTED, ',
            'GROUNDSKEEPERS AND APPRENTICES TO MAINTAIN THE GRASS FIELDS PROPERLY.',
            '',
            '## ***NORTHERN BEACHES TREE LOSS: Between 2019-22 the Northern Beaches LGA experienced the second highest '
                + 'loss of tree canopy in Greater Sydney @5.2% (665 hectares). Council data indicates that since 2016, '
                + 'we have lost about 30,000 trees not including those removed illegally or under the state 10/50 clearing laws. '
                + 'A similar number excluding for the later reasons have been replanted however it will be decades '
                + 'before they reach maturity. Councils Tree canopy plan was adopted in 2023 however, the action plan '
                + 'is to be achieved within the existing operational budget. How do you feel about Council developing '
                + 'important environmental plans without allocating additional resources to meet the objectives of the plan?***',
            '',
            'I am Deeply concerned about the significant loss of tree canopy in the LGA and the slow recovery rate.',
            'There has been a lack of additional resources allocated to the Tree Canopy Plan, that was adopted in 2023. ',
            'Council has to increase the staff and resources to the staff responsible for implementing the ',
            'Tree Canopy Plan. It has to shift its priorities away from the huge capital works to more on improving ',
            'the urban environment, the "where people live". That requires a fundamental shift in its budget. ',
            'This can only be achieved with strong leadership and by listening to the community. ',
            '',
            '- Advocate for increased funding and resources specifically for tree planting and maintenance. ',
            'The council could work with the state and federal governments on grants available.',
            '- Support stronger protections for existing mature trees and enhanced penalties for illegal clearing.',
            '- Promote community tree-planting initiatives and programs to accelerate the recovery of the tree canopy - ',
            '"Buy a Tree for the Future".',
            '',
            'The preparatory work needed already exists in Council\'s multiple planning documents.',
            '',
            'The requirements and specifications are all documented. They just need to be applied as appropriate ',
            'to each streetscape, park, shopping precinct, public amenity, public walk/cycleway etc in the urban heat, ',
            'low canopy hotspots. ',
        ]
    }
]

export const websiteByArrMd = [
    '[Marc Greyling]: https://marcgreyling.tech?utm_source=website&utm_medium=link&utm_campaign=labor4narrabeen.com&utm_id=20240914',
    'Website development by [Marc Greyling]',
    '',
    'All rights reserved',
]

//officialElectionDateLinkSlug
//electionDateOfficialLink

/* 
Cost of living presures
Affordable housing
Improvements to transport infrastructure
Halting privatisation and ensuring quality delivery of services offering job opportunities for local residents
https://www.surveymonkey.com/r/ZJDNMND
*/

/* TODO: privacy policy, cf https://www.nicoletteboele.com.au/privacy-policy
 */

