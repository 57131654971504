import React from 'react'
import { StyledEmailAt } from '../common/Builders'

const EmailObjusc = ({ email }) => {

    if (!email || typeof email !== 'string' || !email.indexOf('@')) return null

    const [ name, domain ] = email.split('@')

    return (
        <>
            <StyledEmailAt>{name}</StyledEmailAt>
            <span>{domain}</span>
        </>
    )
}

export default EmailObjusc
