// Subpage.js

import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Divider, FloatButton } from 'antd'
import { useSpring, animated } from '@react-spring/web'
import { H1C, media } from '../common/Builders'
import { bgLaborRedOldfashioned, darkCharcoal, DEBUG, subpages, white, whiteish } from '../common/constants'
import SvgLogoRubikFont from './SvgLogoRubikFont'
import { Socials } from './Socials'
import { HowToVote } from './HowToVote'

/* define responsive widths for content in this section */
/* refer to const sectionMaxWidthsInPx */
const contDivWidthsInPx = {
    xs: 340,
    sm: 500,
    md: 640,
    lg: 700,
    xl: 800,
    // xxl: 1860,
}

const animationDuration = 900

export const Subpage = ({  key, hideSocials = false }) => {

    const [ isLeaving, setIsLeaving ] = useState(false)

    const location = useLocation()
    const navigate = useNavigate()

    // let _key = location?.pathname.replace('/sub/', '')
    const r = location?.pathname.match(/^\/sub\/([^/]+)/)
    
    const item = subpages.find(e => e?.key === r[1])
    const {
        hdrMdArr,
        contentMdArr,
    } = item || {}

    const opacityAnimation = useSpring({
        opacity: isLeaving ? 0 : 1,
        from: {
            opacity: isLeaving ? 1 : 0,
        },
        config: { duration: animationDuration },
        loop: false,
    })

    useEffect(() => {
        // const timer = isLeaving ? setTimeout(() => navigate(-1), Math.round(animationDuration * 1.1)) : () => null
        const timer = isLeaving ? setTimeout(() => navigate('/'), Math.round(animationDuration * 1.1)) : () => null
        return () => clearTimeout(timer)
    }, [isLeaving, navigate])

    // if (r[1] === 'howtovote') return (
    //     <animated.div style={opacityAnimation}>
    //         <StyledSubPageCont>
    //             <HowToVote standalone={true} />
    //         </StyledSubPageCont>
    //     </animated.div>
    // )

    return (
        <animated.div style={opacityAnimation}>
            {
                hideSocials
                    ? null 
                    : <Socials />
            }
            <FloatButton.BackTop />
            <StyledSubPageCont>
                <StyledFixedHeader className={DEBUG ? 'StyledFixedHeader' : ''}>
                    <StyledBack
                        // onClick={() => navigate('/')}
                        // onClick={() => navigate(-1)}
                        onClick={() => setIsLeaving(true)}
                    >&lt; back</StyledBack>
                    <StyledLogoRight>
                        <StyledLogo>
                            <SvgLogoRubikFont
                                height={'57px'}
                                />
                        </StyledLogo>
                    </StyledLogoRight>
                </StyledFixedHeader>
                {/* { DEBUG && (<pre>{JSON.stringify(location, null, 2)}</pre>) } */}
                {
                    r[1] === 'howtovote'
                        ? (
                            <HowToVote standalone={true} marginTopinPx="60" />
                        )
                        : (
                            <StyledMain className={DEBUG ? 'StyledMain' : ''}>
                                <StyledHeader className={DEBUG ? 'StyledHeader' : ''}>
                                    <ReactMarkdown>{hdrMdArr && Array.isArray(hdrMdArr) && hdrMdArr.join('\n')}</ReactMarkdown>
                                </StyledHeader>
                                <StyledContentCont className={DEBUG ? 'StyledContentCont' : ''}>
                                    <ReactMarkdown
                                        components={{
                                            h2(props) {
                                                return (
                                                    <>
                                                        <Divider
                                                            style={{ borderColor: whiteish, }}
                                                        />
                                                        <h1>{props?.children}</h1>
                                                    </>
                                                )
                                            }
                                        }}
                                    >{contentMdArr && Array.isArray(contentMdArr) && contentMdArr.join('\n')}</ReactMarkdown>
                                </StyledContentCont>
                            </StyledMain>
                        )
                }
            </StyledSubPageCont>
        </animated.div>
    )
}

const StyledLogo = styled.div`
    // position: absolute;
`

const StyledFixedHeader = styled.nav`
    display: flex;
    background: ${bgLaborRedOldfashioned};
    padding-left: 1rem;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 60px;
`

const StyledMain = styled.main`
    margin-top: 60px;
    padding-top: 10px;
`

const StyledLogoRight = styled.div`
    flex: 1;
    text-align: right;
    margin-right: 1rem;
`

const StyledBack = styled.h1`
    flex: 1;
    cursor: pointer;
`

const StyledHeader = styled(H1C)`
    margin: 0 auto;

    ${media.sm`
        width: auto;
    `}
    ${media.md`
        width: ${contDivWidthsInPx.md}px;
        max-width: ${contDivWidthsInPx.md}px;
    `}
    ${media.lg`
        width: ${contDivWidthsInPx.lg}px;
        max-width: ${contDivWidthsInPx.lg}px;
    `}
    ${media.xl`
        width: ${contDivWidthsInPx.xl}px;
        max-width: ${contDivWidthsInPx.xl}px;
    `}    
`

const StyledSubPageCont = styled.section`
    background: ${darkCharcoal};
    color: ${white};
    margin-bottom: 2rem;
`

const StyledContentCont = styled.div`
    padding: 0 1rem 2rem 1rem;
    margin: 0 auto 2rem auto;

    ${media.sm`
        width: auto;
    `}
    ${media.md`
        width: ${contDivWidthsInPx.md}px;
        max-width: ${contDivWidthsInPx.md}px;
    `}
    ${media.lg`
        width: ${contDivWidthsInPx.lg}px;
        max-width: ${contDivWidthsInPx.lg}px;
    `}
    ${media.xl`
        width: ${contDivWidthsInPx.xl}px;
        max-width: ${contDivWidthsInPx.xl}px;
    `}    

`
