import React, { useContext, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { Button, Col, Row } from 'antd'
import { animated, useSpring } from '@react-spring/web'
import {
    // H1C,
    // StyledDebugDiv,
    StyledSectionH1,
    media,
} from '../common/Builders'
import {
    // DEBUG,
    // aboutTextArrMd,
    about,
    breakpoints,
    // breakpoints,
    candidateEmail,
    darkCharcoal,
    DEBUG,
    laborBlue,
    laborRed,
    // sectionMaxWidthsInPx,
    struct,
    // white,
    whiteish,
} from '../common/constants'
import EmailObjusc from '../components/EmailObjusc'
// import Quote from '../components/Quote'
import QuoteLeft from '../components/QuoteLeft'
import { MainContext } from '../contexts/MainContext'
// import { currentBreakpointFromWidth } from '../common/functions'
// import { QuoteAcross } from '../components/QuoteAcross'

/* define responsive widths for content in this section */
/* refer to const sectionMaxWidthsInPx */
const contDivWidthsInPx = {
    xs: 340,
    sm: 500,
    md: 640,
    lg: 700,
    xl: 800,
    // xxl: 1860,
}

/* TODO: */
/* NOTE: the following is stuffed so don't bother for the time being */
/* generate struct of calculated negative left margins for quote elements */
// const negLeftMarginsInPx = Object.fromEntries(
//     Object.entries(sectionMaxWidthsInPx).map(([ key, val ]) => {
//         const divWidth = contDivWidthsInPx?.[key] || Math.max(...Object.values(contDivWidthsInPx))
//         // return [ key, contDivWidthsInPx?.[key] || Math.max(...Object.values(contDivWidthsInPx)) ]
//         return [ key, Math.round((val - divWidth)/2.5) ] /* UP TO HERE!!!! */
//         // return [ key, (val - divWidth)/3 ] /* UP TO HERE!!!! */
//     })
// )

export const About = props => {

    // const [ currentBreakpoint,  setCurrentBreakpoint ]  = useState()
    const [ readMore,           setReadMore ]           = useState(false)

    /* HOC wrappeer passes the component's name via this prop */
    const { componentname } = props

    // const [ negLeftMarg, setNegLeftMarg ] = useState()

    const {
        debugMode,
        width,
        currentBreakpoint,
        // active,
    } = useContext(MainContext)

    // /* calculate negative left margin for quote based on width and defined contDivWidthsInPx */
    // /* i.e. half the difference */
    // useEffect(() => {
    //     breakpoints = 
    //     setNegLeftMarg(width)
    // }, [width])

    /* moved to App.js */
    // useEffect(() => {
    //     if (width) setCurrentBreakpoint(currentBreakpointFromWidth(width))
    // }, [width])

    /* TODO: animate readmore - NOT WORKING!!! */
    const springProps = useSpring({
        maxHeight: '1200px',
        from: { maxHeight: '0' },
    })

    /* find details in struct based on component name */
    const {
        title,
        bgcol,
    } = struct.find(e => e?.component === componentname) || {}

    // const { bodyText, quotes, quoteImgs } = about || {}
    const { bodyTextTeaser, bodyTextReadMore, quotes, quoteImgs } = about || {}
    
    // // const aboutTextMd = aboutTextArrMd.join('\n')
    // const aboutTextMd = bodyText && Array.isArray(bodyText) && bodyText.length
    //     ? bodyText.join('\n')
    //     : null
    const aboutTextTeaserMd = bodyTextTeaser && Array.isArray(bodyTextTeaser) && bodyTextTeaser.length
        ? bodyTextTeaser.join('\n')
        : null
    const aboutTextReadMoreMd = bodyTextReadMore && Array.isArray(bodyTextReadMore) && bodyTextReadMore.length
        ? bodyTextReadMore.join('\n')
        : null
    const quotesArr = quotes && Array.isArray(quotes) && quotes.length
        ? [ ...quotes ]
        : []
    const quoteImgArr = quoteImgs && Array.isArray(quoteImgs) && quoteImgs.length
        ? quoteImgs.map(e => ({ src: e?.src, alt: e?.alt || e?.src || '' }))
        : []

    const handleClickReadMore = e => {
        e.preventDefault()
        // console.log('You clicked readmore btn!')
        setReadMore(true)
    }

    if (width < breakpoints.md) {
        return (
            <>
                <StyledSmallDeviceCont>
                    {
                        debugMode && DEBUG
                            ? <StyledDebug>width: {width}<br/></StyledDebug>
                            : null
                    }
                </StyledSmallDeviceCont>
                <StyledSectionH1>{title}</StyledSectionH1>
                <Row>
                    <StyledQuoteSignCell span={3}>
                        {/* {'"'} */}
                        {'\u201d'}
                    </StyledQuoteSignCell>
                    <StyledQuoteCell span={9}>
                        {quotesArr[0]}
                    </StyledQuoteCell>
                    <StyledPortraitCell span={12} bgimg={quoteImgArr[0].src}>
                        &nbsp;
                    </StyledPortraitCell>
                </Row>
                <Row>
                    <Col>
                        <AboutBodyText
                            {
                                ...{
                                    aboutTextTeaserMd,
                                    aboutTextReadMoreMd,
                                    handleClickReadMore,
                                    readMore,
                                    springProps,
                                    candidateEmail,
                                } 
                            }
                        />
                    </Col>
                </Row>
                <Row><Col>&nbsp;</Col></Row>
                
                {/* <Row><Col>&nbsp;&mdash;</Col></Row> */}
            </>
        )
    }

    /* responsive >= 768px width */
    return (
        <SectionContDiv className='ScreenAbout' $debug={debugMode ? 1 : 0}>
            {/* <H1C>About Sue</H1C> */}
            {/* <H1C>componentname={componentname}</H1C> */}
            {/* <pre>{JSON.stringify(details, null, 2)}</pre> */}
            <StyledSectionH1>{title}</StyledSectionH1>

            {/* DEBUG */}
            {
                debugMode && DEBUG
                    ? <StyledDebug>width: {width}<br/></StyledDebug>
                    : null
            }

            {/* TESTING */}
            {/* <Button
                onClick={e => {
                    e.preventDefault()
                    alert(`active = ${active}`)
                }}
            >NEW CONTENT</Button> */}

            {
                debugMode
                    ? (<pre>{
                        JSON.stringify({
                            width,
                            currentBreakpoint,
                            contDivWidthsInPx,
                            debugMode,
                        }, null, 2)}</pre>)
                    : null
            }

            {/* <StyledSectionH1>bgcol = {bgcol}</StyledSectionH1> */}
            
            {/* {DEBUG ? <StyledDebugDiv /> : null} */}
            {/* <pre>width: {width || null}</pre>
            <pre>breakpoints: {JSON.stringify(breakpoints, null, 2)}</pre>
            <pre>sectionMaxWidthsInPx: {JSON.stringify(sectionMaxWidthsInPx, null, 2)}</pre>
            <pre>currentBreakpoint: {currentBreakpoint || null}</pre>
            <pre>negLeftMarginsInPx: {JSON.stringify(negLeftMarginsInPx, null, 2)}</pre>
            <pre>negLeftMargin: {negLeftMarginsInPx?.[currentBreakpoint]}</pre> */}

            <QuoteLeft
                className='QuoteLeft'
                // size="short"
                size="medium"
                text={quotesArr[0]}
                imgsrc={quoteImgArr[0].src}
                imgalt={quoteImgArr[0].alt}
                bgcol={bgcol} /* of the section, ensures quoted text is readable */

                /* NOT WORKING SO DON'T BOTHER ATM */
                // negLeftMargin={
                //     currentBreakpoint
                //         ? negLeftMarginsInPx?.[currentBreakpoint]
                //         : 0
                // }
            />

            {/* <ReactMarkdown>{aboutTextMd}</ReactMarkdown> */}

            <AboutBodyText
                {
                    ...{
                        aboutTextTeaserMd,
                        aboutTextReadMoreMd,
                        handleClickReadMore,
                        readMore,
                        springProps,
                    } 
                }
            />
            
            {/* <ol>
            {
            struct && Array.isArray(struct)
                // ? struct.map(({ key, path }, i) => (
                ? struct.filter(e => e?.path).map(({ key, path }, i) => (
                    <li key={`li-routes-${i+1}`}>
                        path={`${path || '/'}`}
                        <br/>                        
                        <Link to={`${path || '/'}`}>
                            element= jumpto={key || '/'}
                        </Link>
                    </li>
                ))
                : null
            }
            </ol> */}

            {/* <Quote size="medium">{quotesArr[1]}</Quote> */}
            {/* <Quote
                size="medium"
                text={quotesArr[1]}
                imgsrc={quoteImgArr[1].src}
                imgalt={quoteImgArr[1].alt}
                imgleft={true}
                bgcol={bgcol}
                widths={contDivWidthsInPx}
            /> */}

            {/* <QuoteLeft
                // size="medium"
                size="short"
                text={quotesArr[1]}
                // imgsrc={quoteImgArr[1].src}
                // imgalt={quoteImgArr[1].alt}
                bgcol={bgcol} // of the section, ensures quoted text is readable

                negLeftMargin={
                    currentBreakpoint
                        ? negLeftMarginsInPx?.[currentBreakpoint]
                        : 0
                }
            /> */}

            {/* <QuoteAcross
                text={quotesArr[1]}
            /> */}
            
            {/* <EmailObjusc email={'Sue.Wright@Labor4Narrabeen.com'} /> */}
            <br/><p>&nbsp;</p>
            <EmailObjusc email={candidateEmail} />
        </SectionContDiv>
    )
}

const AboutBodyText = ({
    aboutTextTeaserMd,
    aboutTextReadMoreMd,
    handleClickReadMore,
    readMore,
    springProps,
    candidateEmail,
}) => {

    return (
        <>
        <ReactMarkdown>{aboutTextTeaserMd}</ReactMarkdown>
            {
                readMore === false
                    ? (
                        <StyledReadMore className={DEBUG ? 'StyledReadMore' : ''}>
                            <StyledReadMoreText className={DEBUG ? 'StyledReadMoreText' : ''}>
                                <ReactMarkdown>{aboutTextReadMoreMd}</ReactMarkdown>
                            </StyledReadMoreText>
                            <StyledGradientFadeMask
                                className={DEBUG ? 'StyledGradientFadeMask' : ''}
                            >&nbsp;</StyledGradientFadeMask>
                            <StyledReadMoreBtnCont className={DEBUG ? 'StyledReadMoreBtnCont' : ''}>
                                <StyledReadMoreBtn 
                                    className={DEBUG ? 'StyledReadMoreBtn' : ''}
                                    onClick={handleClickReadMore}
                                >Read more</StyledReadMoreBtn>
                            </StyledReadMoreBtnCont>
                        </StyledReadMore>
                    )
                    : (
                        // <animated.div
                        <div
                            /* TODO: animate readmore - NOT WORKING!!! */
                            style={{
                                ...springProps,
                            }}
                        >
                            <ReactMarkdown>{aboutTextReadMoreMd}</ReactMarkdown>
                            <div>
                                <EmailObjusc email={candidateEmail} />
                            </div>
                        </div>
                        // </animated.div>
                    )
            }
        </>
    )
}

const SectionContDiv = styled.div`
    width: ${contDivWidthsInPx.xs}px;
    // margin-left: auto;
    // margin-right: auto;
    margin-bottom: 3rem;
    padding-bottom: 4rem; /* allows enough space for slogan */

    ${media.sm`
        // width: ${contDivWidthsInPx.sm}px;
        border: ${props => props?.$debug ? 1 : 0}px solid pink;
        width: auto;
    `}
    ${media.md`
        // width: ${contDivWidthsInPx.md}px;
    `}
    ${media.lg`
        // width: ${contDivWidthsInPx.lg}px;
    `}
    ${media.xl`
        // width: ${contDivWidthsInPx.xl}px;
    `}    
`

const StyledReadMore = styled.div`
    // flex: 1;
    text-align: center;
    padding: 1rem;
    position: relative;
    max-height: 9rem;
    height: 9rem;
`

const StyledReadMoreBtnCont = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const StyledReadMoreBtn = styled(Button)`
    // border: 3px solid ${laborRed};
    border: 2px solid ${laborBlue};
    border-radius: 0;
`

const StyledReadMoreText = styled.div`
    border: 0px solid ${whiteish};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
`

const StyledGradientFadeMask = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // mask-image: linear-gradient(to bottom, transparent 25%, black 75%);
    mask-image: linear-gradient(to bottom, transparent 10%, black 90%);
    // background: ${laborBlue};
    background: ${darkCharcoal};
`

const StyledDebug = styled.div`
    border: 1px solid white;
    padding: 3px;
    margin: 3px;
    width: 200px;
    text-align: center;
    &:after {
        content: 'XS <= ${breakpoints.xs}px';
        background: crimson;

        /* min-width: 377px */
        ${media.sm`
            // content: 'SM > ${breakpoints.xs}px';
            content: '${breakpoints.xs}px <= SM < ${breakpoints.sm}px';
            background: blue;
        `}

        /* min-width: 576px */
        ${media.md`
            // content: 'MD >= ${breakpoints.sm}px';
            content: '${breakpoints.sm}px <= MD < ${breakpoints.md}px';
            background: green;
        `}

        /* min-width: 768px */
        ${media.lg`
            // content: 'LG >= ${breakpoints.md}px';
            content: '${breakpoints.md}px <= LG < ${breakpoints.lg}px';
            background: magenta;
        `}

        /* min-width: 992px */
        ${media.xl`
            // content: '.XL >= ${breakpoints.lg}px';
            content: '${breakpoints.lg}px <= XL < ${breakpoints.xl}px';
            background: darkgrey;
        `}
    }
`

const StyledSmallDeviceCont = styled.div`
    display: flex;
    // border: 3px solid salmon;
`

const StyledQuoteCell = styled(Col)`
    line-height: 1.67rem;
    font-size: 1.1rem;
`

const StyledQuoteSignCell = styled(Col)`
    font-size: 10rem;
    font-family: Arial !important;
    color: rgba(200, 200, 200, .3);
`

const StyledPortraitCell = styled(Col)`
    background: url(${props => props?.bgimg || ''});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom 1px right;
`
