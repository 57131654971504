import { breakpoints, candidateAddress } from './constants'

export const candidateAddressAsString = () => {
    if (!candidateAddress) return ''
    if (Array.isArray(candidateAddress) && !candidateAddress.length) return ''
    if (Array.isArray(candidateAddress) && candidateAddress.length)
        return candidateAddress.join(', ')
    if (typeof candidateAddress === 'string') return candidateAddress
    return ''
}

export const shuffle = arr => {
    let currIdx = arr.length,
        tmpVal,
        rndIdx

    // While there remain elements to shuffle...
    while (0 !== currIdx) {
        // Pick a remaining element...
        rndIdx = Math.floor(Math.random() * currIdx)
        currIdx -= 1

        // And swap it with the current element
        tmpVal = arr[currIdx]
        arr[currIdx] = arr[rndIdx]
        arr[rndIdx] = tmpVal
    }
    return arr
}

/* cf https://stackoverflow.com/a/9039885/24018747 */
export const detect_iOS = () => ([
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document))

export const detect_macOS = () => (navigator.platform.startsWith('Mac'))

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
        /* you can also use 'auto' behaviour 
           in place of 'smooth' */
    })
}

/* determine current breakpoint */
// export const currentBreakpointFromWidth = w => {
//     // /* ensure breakpoints are in asc order */
//     // const _breakpoints = Object.keys(breakpoints)
//     //     .sort((a, b) => breakpoints[a] < breakpoints[b] ? -1 : 1)
//     //     .reduce((acc, curr) => ({...acc, [curr]: breakpoints[curr]}), {})
//     return Object.keys(breakpoints)
//         .sort((a, b) => breakpoints[a] < breakpoints[b] ? -1 : 1) /* ensure breakpoints are in asc order */
//         // .reduce((acc, curr) => acc ? acc : breakpoints[curr] > w ? curr : acc, null)
//         .reduce((acc, curr) => acc && w > breakpoints[curr] ? curr : curr, null)
// }
export const currentBreakpointFromWidth = w => {
    return Object.keys(breakpoints)
        // .sort((a, b) => breakpoints[a] < breakpoints[b] ? -1 : 1) /* ensure breakpoints are in asc order */
        // .reduce((acc, curr) => acc && w <= breakpoints[curr] ? acc : curr, null)
        .sort((a, b) => breakpoints[a] < breakpoints[b] ? 1 : -1) /* ensure breakpoints are in desc order */
        .reduce((acc, curr) => acc && w > breakpoints[curr] ? acc : curr, null)
}
