// DonateDirectDeposite.js

import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { animated, useSpring } from '@react-spring/web'
import { H1C } from '../common/Builders'

export const DonateDirectDeposit = props => {

    const {
        donateBankXferHdr,
        bankXferTextMd,
        isActive,
    } = props

    const springProps = useSpring({
        opacity: isActive ? 1 : 0,
        // marginLeft: isActive ? 0 : 5000,
        config: { duration: 500, }
    })

    return (
        <>
            <H1C>OR</H1C>
            
            <H1C>{donateBankXferHdr}</H1C>

            <AnimatedStyledDirectDeposit style={springProps}>
            {/* <StyledDirectDeposit> */}
                {/* <H1C>OR</H1C> */}
                <ReactMarkdown
                    components={{
                        // // h2({ node, inline, className, children, ...props }) {
                        // h2({ children }) {
                        //     return (<h2 style={{ textAlign: 'center', }}>{children}</h2>)
                        // }
                    }}
                >
                    {bankXferTextMd}
                </ReactMarkdown>
            {/* </StyledDirectDeposit> */}
            </AnimatedStyledDirectDeposit>
        </>
    )
}

const StyledDirectDeposit = styled.aside`
    // background: salmon;
    padding: 0 4rem;
`

const AnimatedStyledDirectDeposit = styled(animated(StyledDirectDeposit))`
    // background: salmon;
    padding: 0 4rem;
`
