import React from 'react'
import { StyledInterimDiv } from '../common/Builders'

export const Interim = props => {
    return (
        <StyledInterimDiv>
            &nbsp;
            {/* placeholder */}
        </StyledInterimDiv>
    )
}
