// EventsList.js was EventsLatest.js

import React, { useContext, useEffect, useState } from 'react'
import {
    DEBUG,
    events,
    whiteish,
} from '../common/constants'
import EventFramed from './EventFramed'
import { MainContext } from '../contexts/MainContext'
import { Divider } from 'antd'

const checkIfEventIsFuture = (date, now) => {
    const timePassed = (new Date(now)).setHours(now.getHours() - 24)
    // console.log(10, {date, now, timePassed, 'timePassed < date': timePassed < date})
    return date instanceof Date && timePassed < date
}

// const EventsLatest = props => {
export const EventsList = props => {

    const {
        isNarrow,
        past = false,
    } = props

    const [ now, setNow ] = useState(new Date())

    const { debugMode } = useContext(MainContext)

    useEffect(() => {
        setNow(new Date())
    }, [])

    // .map((e, i, _self) => ({ ...e, order: e?.order || Math.max(..._self.map(e => e?.order || 0)) }))
    // .sort((a, b) => b?.order > a?.order ? -1 : 1)
    
    const upcomingEvents = events && Array.isArray(events)
        ? events
            .filter(e => e?.date && !!checkIfEventIsFuture(e.date, now))
            .map((e, i, _self) => ({ /* inject order attrib if missing and put last */
                ...e, order: e?.order || Math.max(..._self.map(e => parseFloat(e?.order) || 0)) + 1
            }))
            .sort((a, b) => b?.order > a?.order ? -1 : 1)
        : null

    const pastEvents = events && Array.isArray(events)
        ? events
            .filter(e => e?.date && !checkIfEventIsFuture(e.date, now))
            .sort((a, b) => b?.order > a?.order ? -1 : 1)
        : null

    // console.log(43, { upcomingEvents })

    const eventsList = events && Array.isArray(events)
        ? past
            ? events
                .filter(e => e?.date && !checkIfEventIsFuture(e.date, now))
                .sort((a, b) => b?.order > a?.order ? -1 : 1)
            : events
                .filter(e => e?.date && !!checkIfEventIsFuture(e.date, now))
                .map((e, i, _self) => ({ /* inject order attrib if missing and put last */
                    ...e, order: e?.order || Math.max(..._self.map(e => parseFloat(e?.order) || 0)) + 1
                }))
                .sort((a, b) => b?.order > a?.order ? -1 : 1)
        : null

    return (
        <>
        {/* {            
            upcomingEvents && Array.isArray(upcomingEvents) && upcomingEvents.length
                ? upcomingEvents.map((e, i) => (
                    <React.Fragment key={`event-${i}`}>
                        <EventFramed
                            data={e}
                            isNarrow={isNarrow}
                        />
                        <Divider style={{ borderColor: whiteish, borderWidth: 3, }} />
                    </React.Fragment>
                ))
                : null
        } */}

        {
            eventsList
                ? eventsList.map((e, i) => (
                    <React.Fragment key={`event-${i}`}>
                        <EventFramed
                            data={e}
                            isNarrow={isNarrow}
                            past={past}
                        />
                        <Divider style={{ borderColor: whiteish, borderWidth: 3, }} />
                    </React.Fragment>
                ))
                : null
        }

        {   DEBUG && debugMode
            ? (
                <>
                    <pre>{JSON.stringify(new Date(), null, 2)}</pre>
                    <div>EventsLatest</div>
                    <pre>{JSON.stringify(upcomingEvents, null, 2)}</pre>
                    <div>Past</div>
                    <pre>{JSON.stringify(pastEvents, null, 2)}</pre>
                </>
            )
            : null
        }            
        </>
    )
}

// export default EventsLatest
