// Events.js

import React, { useContext } from 'react'
import styled from 'styled-components'
import {
    H1C,
    StyledDebugDiv,
} from '../common/Builders'
// import EventsLatest from '../components/EventsList'
import { EventsList } from '../components/EventsList'
import { breakpoints, DEBUG } from '../common/constants'
import { MainContext } from '../contexts/MainContext'

export const Events = props => {

    const {
        width,
        debugMode,
    } = useContext(MainContext)

    const isNarrow = width < breakpoints.md
    const padding = isNarrow ? '.25rem' : 'inherit'

    return (
        <StyledEventsCont
            className={DEBUG ? 'EventsScreen StyledEventsCont' : ''}
            $padding={padding}
        >
            {/* {DEBUG ? <StyledDebugDiv /> : null} */}
            {
                DEBUG && debugMode
                ? (<>
                        <StyledDebugDiv />
                        width: { width }
                </>)
                : null}
            <H1C>Upcoming events</H1C>
            {/* <EventsLatest isNarrow={isNarrow} /> */}
            <EventsList past={false} isNarrow={isNarrow} />
            <H1C>List of past events</H1C>
            {/* <EventsLatest isNarrow={isNarrow} /> */}
            <EventsList past={true} isNarrow={isNarrow} />
        </StyledEventsCont>
    )
}

const StyledEventsCont = styled.div`
    padding: ${props => props.$padding || 'inherit'};

`
