// Socials.js

import React from 'react'
// import {
//     FacebookOutlined,
//     FacebookFilled,
//     TwitterSquareFilled,
// } from '@ant-design/icons'
import { SocialIcon } from 'react-social-icons/component'
import {
    // H1C,
    media,
} from '../common/Builders'
import {
    DEBUG,
    socials,
    // white,
    whiteish
} from '../common/constants'
import styled from 'styled-components'

import 'react-social-icons/twitter'
import 'react-social-icons/facebook'

export const Socials = () => {

    return (
        <SocialsDiv className={DEBUG ? 'SocialsDiv' : ''}>
            {
                socials
                    .sort((a, b) => a?.order < b?.order ? -1 : 1)
                    .map(({ medium, url, order }, i) => (
                        <StyledIconDiv
                            key={`social-icon-${i}`}
                        >
                            <StyledIcon
                                className={DEBUG ? 'StyledIcon' : ''}
                                target="_blank"
                                url={url}
                            />
                        </StyledIconDiv>
                    ))
            }
        </SocialsDiv>
    )
}

const SocialsDiv = styled.div`
    position: fixed;
    right: 0;
    top: 20vh;
    // width: 64px;
    width: 60px;
    // width: 90px;
    // height: 20px;
    // height: auto;
    height: 120px;
    // border: 1px solid ${whiteish}; /* debug */
    background: transparent;
    // background: ${whiteish};
    // background: white;
    z-index: 200;
    display: flex;
    flex-direction: column;
    // justify-content: flex-start;
    align-items: flex-end;

    ${media.xl`
        border: 0px;
    `}    
`

const StyledIconDiv = styled.div`
    // flex: 1;
    text-align: right;
`

const StyledIcon = styled(SocialIcon)`
    // border: 1px solid ${whiteish}; /* debug */
    border-radius: 50%;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
`
