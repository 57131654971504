/* eslint-disable no-unreachable */
import React from 'react'
import ReactMarkdown from 'react-markdown'
import {
    donorDeclarationTextMd,
    links,
} from '../common/constants'

const linkSlug = 'political-donations-nec'

const DonationDeclaration = ({ donorDeclOkText = '' }) => {

    // if (!Array.isArray(donorDeclarationTextMd) || !donorDeclarationTextMd.length) return null

    let arr = Array.isArray(donorDeclarationTextMd)
        ? [ ...donorDeclarationTextMd ]
        : null

    const preAgreeText = donorDeclOkText ? `By clicking "${donorDeclOkText}", ` : ''

    // if (Array.isArray(donorDeclarationTextMd))
    //     donorDeclarationTextMd[0] = [preAgreeText, donorDeclarationTextMd[0]].join(' ')
    if (arr) arr[0] = [preAgreeText, arr[0]].join(' ')

    // const donorDeclarationHtml = (Array.isArray(donorDeclarationTextMd))
    const donorDeclarationHtml = arr
        ? <ReactMarkdown>{arr.join('\n\n')}</ReactMarkdown>
        : typeof donorDeclarationTextMd === 'string'
            ? (
                <ReactMarkdown>
                    {preAgreeText}
                    {donorDeclarationTextMd}
                </ReactMarkdown>
            )
            : null

    const necLink = links && Array.isArray(links) ? links.find(({slug}) => slug === linkSlug) : null
    // const necLinkMd = necLink?.url ? `[${necLink.url}](${necLink?.text || necLink.url})` : null
    const necLinkMd = necLink?.url ? `See also: [${necLink?.text || necLink.url}](${necLink.url})` : null
    const necLinkHtml = necLinkMd
        ? <ReactMarkdown>{necLinkMd}</ReactMarkdown>
        : null
    
    if (!donorDeclarationHtml) return null

    return (
        <article className="donor-declaration">
            {donorDeclarationHtml}
            {necLinkHtml}
        </article>
    )
}

export default DonationDeclaration

